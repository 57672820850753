<template>
    <main id="cLocation" class="main" style="min-height: 100vh;">
        <section class="main_banner">
             <BannerSlider :dataBannerSlider="dataBannerSlider" >
                 <div class="azureMain_portada_content">
                   <ContactSimply :ViewDataForm="{
                         stateInputs:{
                         nombre: true,
                         email: true,
                         telefono: true,
                         asunto: false,
                         motivo: false,
                         mensaje: true,
                        },
                     withBLur: true,
                     asunto: 'Consulta iHosting Co-Location: solicito la llamada de un Ejecutivo Comercial',
                     motivo: 'Consulta comercial Co-Location'
                   }"/>
                   
                 </div>
             </BannerSlider>
        </section>  


        <section class="main_cards">
            <titleBox class="wow animate__animated  animate__fadeInUp" :dataTitleBox="dataTitleMore" :configTitleBox="{'positionTitle':'center' }"/>
            <div class="main_cards_box wow animate__animated  animate__fadeInUp">
                <cardWaveInside v-for="(item,index) in dataSectionMore" :dataInfo="item" :key="index" />
            </div>
        </section>

        <!--Listado-->
        <section class="main_list">
        <listDynamic :dataTitleBox="dataTitleList" :dataList="dataList" class="wow animate__animated  animate__fadeInUp"/>
        </section>

    </main>
</template>

<script>
import BannerSlider from  "@/components/web/sliders/BannerSlider.vue";
import ContactSimply from "@/components/web/forms/contactSimply.vue";
import listDynamic from "@/components/web/listDynamic.vue";
import titleBox from "@/components/web/titleBox.vue"
import cardWaveInside from "@/components/web/cards/cardWaveInside.vue";

export default{
    components:{
        BannerSlider,
        ContactSimply,
        listDynamic,
        titleBox,
        cardWaveInside

    },
    data() {
        return {
            // data para la seccion de inicio, el banner slider ---
            dataBannerSlider:{
                title: 'Co-Location',
                titleBold: '/Housing',
                subtitle: 'Solo preocúpate por tu servidor mientras nosotros nos preocupamos de la energía, climatización y conectividad ya que se encontrará todo monitoreado por un equipo de profesionales para que su servidor esté siempre en línea.',
                directionElement: false,
            },

        // data cards
        dataTitleMore: {
            title: 'Garantizando Estabilidad y Seguridad',
            description: 'Soluciones Tecnológicas y Medidas de Seguridad para un Funcionamiento Ininterrumpido.',
            color: 'var(--dark-color)',
            positionTitle: 'left'
        },
        dataSectionMore:[
            {
                title: 'Soluciones garantizadas',
                text: 'No más preocupaciones por conectividad y ataques DDoS. Ofrecemos soluciones estables con múltiples proveedores',
                img: 'images/services/c_location/SOLUCIONES-GARANTIZADAS-.webp'
            },
            {
                title: 'Acceso Controlado',
                text: 'El acceso físico se encuentra restringido a través de identificación biométrica, múltiples puertas de acceso, cámaras de CCTV.',
                img: 'images/services/c_location/ACCESO-CONTROLADO-.webp'
            },
            {
                title: 'Ambiente Controlado',
                text: 'La temperatura se controla para un funcionamiento óptimo de los equipos, y respaldada en caso de fallas o mantenimiento.',
                img: 'images/services/c_location/AMBIENTE-CONTROLADO-.webp'
            },
            {
                title: 'Energía respaldada',
                text: 'No hay preocupación por cortes de energía, respaldada por UPS de doble conversión y generador.',
                img: 'images/services/c_location/ENERGÍA-RESPALDADA-.webp'
            },


        ],
                    // data listado
                    dataTitleList: {
                title: 'Servicios de Alojamiento y Soporte a Medida',
                description: 'Flexibilidad, Control y Atención Personalizada para tus Necesidades.',
                color: 'var(--dark-color)',
            }, 
            dataList: {
                dataListServiceInfo:[
                {title: 'Flexibilidad de Alojamiento', 'description': 'Su servidor puede ser alojado tanto en Santiago de Chile como en la ciudad de Viña del Mar o incluso en ambas locaciones.', icon: 'fa fa-bolt'},
                {title: 'Control Total ', description: 'Podrás instalar las aplicaciones que desees, arrancar los procesos y servicios que necesites, y realizar las tareas que consideres oportunas en el momento que requieras.',  icon: 'fa fa-bolt'},
                {title: 'Atención Remota Rápida', description: 'En cuanto lo requieras atenderemos rápidamente tus inquietudes de manos remotas.',  icon: 'fa fa-bolt'},
                {title: 'Atención Personalizad ', description: 'Si tienes algún tipo de requerimiento especial cuentanos para así orientarte en todo momento.',  icon: 'fa fa-bolt'},
            ],
            expande: true
        },
    }
            
    },
}
</script>

<style scoped>
.main, .main_banner{
    padding: 0px;
}

.main_cards, .main_list{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px 0px;
    min-height: 75vh;
}

.main_cards_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media(max-width:1300px){
    .main_cards{
    padding: 70px 20px 80px;
}

.main_list{
    padding: 40px 20px 80px;

}
}
@media (max-width: 600px){
.main_list{
    padding: 30px 20px 80px;

}
}
</style>