<template>
  <div class="section">
    <titleBox :dataTitleBox="dataTitleBox" />

    <div class="container">
      <div class="sectionInfo"></div>
      
 

      <div class="tab tab_check"  v-for="(data, index) in dataList.dataListServiceInfo" :key="index" >
        <input :class="data.description ? 'tab_check' : 'tab_noCheck'" type="radio" name="acc" :id="`acc${index}_${keyList}`" />
        <div
          v-if="data.description && dataList.expande"
          class="tab_labelInfo_iconPlus"
          @click="changeCheck(`acc${index}_${keyList}`)"
        >
          <span>+</span>
        </div>

        <label v-if="dataList.expande" class="tab_labelInfo" :for="`acc${index}_${keyList}`">
          <h2  class="tab_labelInfo_index" :class="  index % 2 == 0  ? 'tab_labelInfo_index_color1' : 'tab_labelInfo_index_color2' ">
            <i v-if="data.icon" :class="data.icon"></i>
            <span v-else> {{  index + 1 }}</span>
           
          </h2>
          <h3 class="tab_labelInfo_question">{{ data.title }}</h3>
        </label>

        <label v-else class="tab_labelInfo">
          <h2  class="tab_labelInfo_index" :class="  index % 2 == 0  ? 'tab_labelInfo_index_color1' : 'tab_labelInfo_index_color2' ">
            <i v-if="data.icon" :class="data.icon"></i>
            <span v-else> {{  index + 1 }}</span>
           
          </h2>
          <h3 class="tab_labelInfo_question">{{ data.title }}</h3>
        </label>

        <div v-if="dataList.expande"  class="tab_content">
          <p class="tab_content_paragrahp" v-if="data.description">{{ data.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from "@/components/web/titleBox.vue";

export default {
  props: {
    dataList: {
      default: [],
    },
    dataTitleBox: {
      default: () => ({}),
    },
    keyList: {
      default: 'listDynamic'
    }
  },
  components: {
    titleBox,
  },
  data: () => ({}),

  methods: {
    changeCheck(check) {
      console.log("this.dataList.expande: ", this.dataList.expande);
      if(this.dataList.expande){
        let checkbox = document.querySelector(`#${check}`);
        checkbox.checked = !checkbox.checked;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  color: #333;
}
/* .bodyServices_title_description{
        font-size: 20px;
        text-align: center;
        margin-bottom: 10px;
    } */
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  background: white;
  padding: 0px;
}

.sectionInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-bottom: 25px;
  width: 100%;
}

.container {
  margin: 0 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.tab {
  position: relative;
  background: #fff;
  padding: 0 20px 20px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  overflow: hidden;
  max-width: 550px;
  width: 550px;
  height: max-content;
  z-index: 6;
  /* height: 150px; */
}
.tab:hover .tab_labelInfo_iconPlus {
  color: #333;
  z-index: 0;
}

.tab_labelInfo_iconPlus {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2em;
  color: rgba(0, 0, 0, 0.1);
  transition:  .4s;
  z-index: 1;
  cursor: pointer;
}

.tab_labelInfo_question {
  font-size: 17px !important;
  line-height: 20px;
  font-weight: 500 !important;
  text-transform: uppercase;
  color: var(--dark-color);
}
.tab_check, .tab_noCheck {
  appearance: none;
  /* height: 140px; */
}



/* Estilo adicional para resaltar la tarjeta activa */
.tab_check:checked ~ .tab_labelInfo_iconPlus {
  transform: rotate(135deg);
  color: white !important;
  pointer-events: auto;
  z-index: 1;
}

.tab_content {
 max-height: 0vh !important;
}

.tab_check:checked ~ .tab_content {
  max-height: 100vh !important;
  
}
.tab_check:checked ~ label h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.2);
  font-size: 8em;
  justify-content: flex-end;
  padding: 35px;
}
.tab_check:checked ~ .tab_content .tab_content_paragrahp {
  color: white;
  padding: 10px 0 0 30px;
  font-weight: 500;
  font-size: 16px;
}

.tab_check:checked ~ label h3{
  display: none;
}

.tab_labelInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

h2 {
  width: 35px;
  height: 35px;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.20em;
  border-radius: 5px;
  margin-right: 10px;
}
h3 {
  position: relative;
  font-weight: 500;
  color: #333;
  z-index: 10;
  width: 90%;
  margin: 0 10px;
  font-size: 22px;
}

@media (max-width: 550px) {
  h3 {
    width: 75%;
    font-size: 18px;
  }
}
.tab_labelInfo_index_color1 {
  background: linear-gradient(
    90deg,
    var(--blue-color) 0%,
    var(--primary-color) 100%
  );
}
.tab_labelInfo_index_color2 {
  background: linear-gradient(
    90deg,
    var(--blue-color) 0%,
    var(--primary-color) 100%
  );
}
.tab_content {
  max-height: 0;
  transition: 0.8s ease-in-out;
  overflow: hidden;
}

.tab_content .tab_content_paragrahp {
  position: relative;
  padding: 10px 0;
  color: var(--white-color);
  z-index: 10;
  width: 90%;
  transition: .3s;
}

@media (max-width: 550px) {
  .tab_content .tab_content_paragrahp {
    font-size: 18px;
    font-weight: 400;
  }
}

@media (max-width: 700px) {
  .tab {
    width: 100%;
  }
}

@media (max-width: 555px) {
  .tab_labelInfo_question {
    font-size: 15px !important;
  }

}

</style>
