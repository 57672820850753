<template>
    <main class="hostingMain" id="hostings">
        <section class="portada ">
            <BannerSlider :dataBannerSlider="dataBannerSlider"
            styleSection = " { padding: '100px 90px 130px' }" >
                <div class="BannerSlider_sliderCardPlans">
                    <SliderCardPlans
                        v-if="categories['Emprendedor']"
                        :categorie="categories['Emprendedor']"
                        :infoCategory="{category: dataHosting[4].categoria, subCategory: dataHosting[5].subcategoria }  "
                        :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" 
                        :sslGlobal="true"
                        :cardContact="false"
                        :keySlide="dataBannerSlider.keySlide"
                        :configSwiper="{
                            loop: false,
                            autoplay: false,
                            time: 800,
                            perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 4, viewDynamic: true}
                        }"
                        addDCTO="true"
                    />
                    </div>
            </BannerSlider>
        </section>
        <!-- info vps -->
        <section class="ssdMain_listResources">
            <div class="wow animate__animated  animate__fadeInUp">
                <cardsHoverGradient :dataCards="dataMaxFlex" :dataTitleBox="dataTitleMaxFlex"/>
            </div>
        </section>

        <section class="sectionSiteJet">
            <titleBox :dataTitleBox="dataSiteJetTitle" :configTitleBox="{'positionTitle':dataSiteJetTitle.positionTitle, 'style': 'width: 100%'}" class="sectionSiteJet_title"/>
            <section class="section_site" style="margin-top: 25px">
                <sectionWithImage :dataSection="dataSite1"/>
            </section>
    
            <section class="section_site">
                <sectionWithImage :dataSection="dataSite2"/>
            </section>
        </section>

    <!-- MONITOREO DE PLATAFORMAS -->
    <section class="pt-grey-background section_service" style="display: flex; justify-content: center; padding: 45px 80px;">
        <titleBox :dataTitleBox="dataSiteServiceWebMail" :configTitleBox="{'positionTitle': 'left', 'style': 'width: 100%'}" class="sectionSiteJet_title"/>
        <div class="container" style="display: flex; align-items: center; justify-content: center">

            <div class="row" style="display: flex;flex-direction: column; align-items: center;">
            <!-- <div >
                <img src="images/services/hostings/horde_.webp" class="section_mail_img" alt="image31" />
            </div> -->
            <!-- <div class="col-xl-6 col-md-6">
                <div class="pt-section-title-1">
                <h2 class="pt-section-title" style="font-size: 25px; line-height: 29px; text-align: center;">Refuerza la Identidad Digital de tu Marca con <span class="imp-word">iHosting</span></h2>

                </div>


            </div> -->
            </div>
        </div>
        <div class="container" style="display: flex; align-items: center; justify-content: center">
        <div class="row" style="display: flex;flex-direction: column; align-items: center;">
          <div  >
              <img src="images/services/hostings/roundcube.webp" class="section_mail_img"  alt="image31" />
          </div>
          <!-- <div class="col-xl-6 col-md-6">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title" style="font-size: 25px; line-height: 29px; text-align: center;">¡Todos nuestros Planes Hosting incluyen servicio <span class="imp-word">WebMail</span>!</h2>
        
            </div>


          </div> -->
        </div>
      </div>


    </section>
    <!-- Website Hosting End -->

    <section class="sectionSiteJet">
    
            <section class="section_site">
                <sectionWithImage :dataSection="dataSiteSegurity"/>
            </section>
        </section>


        <section class="section_cardMore wow animate__animated  animate__fadeInUp">
            <titleBox :dataTitleBox="dataTitleMore" :configTitleBox="{'positionTitle':'center' }"/>
            <div class="section_cardMore_container_cards">
                <cardWaveInside v-for="(item,index) in dataSectionMore" :dataInfo="item" :key="index" />
            </div>
        </section>


        <section class="sectionComplementos sectionBeneficios">
            <listDynamic :dataTitleBox="dataTitleBeneficios" :dataList="dataListBeneficios"/>
        </section>

        <!-- cards services hosting Start -->
        <section class="sectionHosting" style="background: var(--grey-color); padding-bottom: 120px;" id="emprendedor">
            <div class="nubesComponent_top">
                <nubes/>
            </div>
            <titleBox :dataTitleBox="DataSectionServices['Emprendedor']"/>
            
            <div class="sectionHosting_slider">
            <SliderCardPlans
                                v-if="categories['Emprendedor']"
                                :categorie="categories['Emprendedor']"
    
                                :infoCategory=" {category: dataHosting[4].categoria, subCategory: dataHosting[5].subcategoria }   "
                                :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" 
                                :sslGlobal="true"
                                :cardContact="false"
                                keySlide="Emprendedor"
                                :configSwiper="{
                                    loop: false,
                                    autoplay: false,
                                    time: 800,
                                    perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 4, viewDynamic: true}
                                }"
                                addDCTO="true"

                />
            </div>

            <div class="nubesComponent">
                <nubes/>
            </div>
        </section>

        <section class="sectionHosting" id="basico"  style="padding-bottom: 30px;">
            <titleBox :dataTitleBox="DataSectionServices['Hosting Básico Anual']"/>
            <div class="sectionHosting_slider">
                    <SliderCardPlans
                    v-if="categories['Hosting Básico Anual']"
                        :categorie="categories['Hosting Básico Anual']"
                        :infoCategory=" {category: dataHosting[0].categoria, subCategory: dataHosting[0].subcategoria }   "
                        :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" 
                        :sslGlobal="true"
                        :cardContact="false"
                        keySlide="Hosting-Básico-Anual"
                        :configSwiper="{
                            loop: false,
                            autoplay: false,
                            time: 800,
                            perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 4, viewDynamic: true}
                        }"
                        addDCTO="true"
                    />
                    </div>
            
        </section>

        <section class="sectionHosting" style="background: var(--grey-color); padding-bottom: 120px;" id="premium" >
            <div class="nubesComponent_top">
                <nubes/>
            </div>
            <titleBox :dataTitleBox="DataSectionServices['Hosting Premium Anual']"/>
            <div class="sectionHosting_slider">
            <SliderCardPlans
                                v-if="categories['Hosting Premium Anual']"
                                :categorie="categories['Hosting Premium Anual']"
    
                                :infoCategory=" {category: dataHosting[2].categoria, subCategory: dataHosting[2].subcategoria }   "
                                :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" 
                                :sslGlobal="true"
                                :cardContact="false"
                                keySlide="Hosting-Premium-Mensual"
                                :configSwiper="{
                                    loop: false,
                                    autoplay: false,
                                    time: 800,
                                    perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 4, viewDynamic: true}
                                }"
                                addDCTO="true"

                />
                </div>
            
            <div class="nubesComponent">
                <nubes/>
            </div>
        </section>

        <section class="sectionHosting" id="wordpress" style="padding-bottom: 30px;">
            <titleBox :dataTitleBox="DataSectionServices['Hosting Wordpress Anual']"/>
            <div class="sectionHosting_slider">
            <SliderCardPlans
                                v-if="categories['Hosting Wordpress Anual']"
                                :categorie="categories['Hosting Wordpress Anual']"
    
                                :infoCategory=" {category: dataHosting[4].categoria, subCategory: dataHosting[4].subcategoria }   "
                                :promoGlobal="{status: true, text: 'Prueba 5 días gratis'}" 
                                :sslGlobal="true"
                                :cardContact="false"
                                keySlide="Hosting-Wordpress-Anual"
                                :configSwiper="{
                                    loop: false,
                                    autoplay: false,
                                    time: 800,
                                    perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 4, viewDynamic: true}
                                }"
                                addDCTO="true"

                            />
                </div>
        </section>

        <section class="azureMain_imageList  spad sectionHosting sectionHosting_eLearning" id="e-learning" style="background: var(--grey-color); padding-bottom: 120px;">
            <div class="nubesComponent_top">
                <nubes/>
            </div>
            <div class="azureMain_imageList_content">
                    <ContactSimply :ViewDataForm="{
                            stateInputs:{
                            nombre: true,
                            email: true,
                            telefono: true,
                            asunto: false,
                            motivo: false,
                            mensaje: true,
                        },
                        withBLur: true,
                        asunto: 'Consulta iHosting: Hostings',
                        motivo: 'Consulta comercial'
                    }"/>
                    
            </div>
            <div class="nubesComponent">
                <nubes/>
            </div>
            <div class="azureMain_imageList_list">
                <listWithImage :dataListBeneficios="dataElearning" :dataTitleBox="dataTitleElearning"/>
            </div>
        </section>
        <!-- cards services hosting End -->
        <section class="sectionHosting" style="padding: 40px 0px;">
            <aboutService :dataSection="dataTitleMicrosoftAzure" />
        </section>
        <section class="sectionComplementos">
            <listDynamic :dataTitleBox="dataTitleList" :dataList="dataList" keyList="complementos"/>
        </section>




    </main>
</template>

<script>
import BannerSlider from  "@/components/web/sliders/BannerSlider.vue";
import SliderCardPlans from "@/components/web/sliders/SliderCardPlans.vue";
import cardsHoverGradient from "@/components/web/cards/cardsHoverGradient.vue";
import listWithImage from "../../../components/web/lists/listWithImage.vue";
import titleBox from "@/components/web/titleBox.vue"
import cardWaveInside from "@/components/web/cards/cardWaveInside.vue";
// import tabeCardsPrice from "@/components/web/tables/tableCardsPrice.vue";
import listDynamic from "../../../components/web/listDynamic.vue";
import nubes from "../../../components/web/nubes.vue";
import ContactSimply from "@/components/web/forms/contactSimply.vue";
import sectionWithImage from "../../../components/sectionWithImage.vue";
import aboutService from "../../../components/web/aboutService.vue";

import { mapGetters, mapActions } from "vuex";

export default{
    components:{
        BannerSlider,
        SliderCardPlans,
        cardsHoverGradient,
        listWithImage,
        titleBox,
        cardWaveInside,
        // tabeCardsPrice,
        listDynamic,
        nubes,
        ContactSimply,
        sectionWithImage,
        aboutService
    },
    data: ()=>({
        widthView: window.innerWidth,
        categories: {},

         // data para la seccion de inicio, el banner slider ---
        dataBannerSlider:{
            title: 'Planes ',
            titleBold: 'Hosting',
            keySlide: 'Hosting_Básico_Anual',
            // btn: {'title': 'Contáctanos', 'type': 'link', 'url': '/nosotros#contacto'},
            subtitle: ' Pensado en personas, empresas o proyectos que no requieren mayores recursos para operar. Tendrán lo mejor del servicio iHosting, en versión limitada y a un precio insuperable.',
            directionElement: true,
       
        },
        
        // data para la seccion Estabilidad y continuidad operacional garantizada ---
        dataTitleMaxFlex: {
                title: '¡Nuestros Planes iHosting Incluyen!',
                description: 'Cuota máxima de uso de servidor en todos nuestros planes, garantizando que tus recursos estén siempre disponibles y no se vean afectados por otros usuarios.',
                color: 'var(--dark-color)',
        },
        dataMaxFlex:[
            {title: 'Transferencia Mensual Ilimitada', img:'images/services/include/TRANSFERENCIA_MENSUAL.webp'},
            {title: 'Antivirus', img: 'images/services/include/ANTIVIRUS.webp'},
            {title: 'Antispam', img: 'images/services/include/ANTISPAM.webp'},
            {title: 'Base de Datos MySQL', img: 'images/services/include/BASE_DE_DATOS_MYSQL.webp'},
            {title: 'Subdominios Ilimitados', img: 'images/services/include/SUBDOMINIOS_ILIMITADOS.webp'},
            {title: 'Servicio Webmail ', img: 'images/services/include/SERVICIOS_WEBEMAIL.webp'},
            {title: 'Respaldo Diario ', img: 'images/services/include/RESPALDO_DIARIO.webp'},

        ],

        // data sitejs 

        // title site js
        dataSiteJetTitle: { 
            title: "SiteJet Builder",
            description: "Descubre la forma más fácil y eficaz de crear tu sitio web con iHosting. Todos los planes ahora incluyen SiteJet Builder, una herramienta innovadora para llevar tu presencia en línea al siguiente nivel, con una interfaz intuitiva para crear sitios web impresionantes sin necesidad de habilidades técnicas avanzadas.",
            positionTitle: 'center',
            color: 'var(--dark-color )',
        },
        dataSite1: {
        title: 'Libertad de diseñar a tu gusto',
        description: 'Disfruta de la libertad de diseñar a tu gusto, eligiendo entre una amplia gama de plantillas profesionales y personalizables que se adaptan a cualquier tipo de negocio o propósito personal. Además, con la funcionalidad de arrastrar y soltar, puedes añadir, mover y editar elementos fácilmente, haciendo que el proceso de diseño sea un juego de niños.',
        color: 'var(--dark-color)',
        positionTitle: 'left',
        icons: [
            { text: 'Intuitiva', icon: 'fa fa-rocket'}, { text: 'Fácil y rápida', icon: 'fa fa-bolt'}, { text: 'Grandes Resultados', icon: 'fa fa-check'}, 
        ],
        img: '/images/services/hostings/sitejet-templates.webp'
        }, 
        // data sitejs 2
        dataSite2: {
        title: 'Gestiona tu sitio web con la máxima eficiencia',
        description: 'En iHosting, entendemos la importancia de contar con herramientas potentes y accesibles para crear tu presencia en línea. Por eso, incluimos SiteJet Builder en todos nuestros planes, para que puedas lanzar y gestionar tu sitio web con la máxima eficiencia y sin complicaciones. ¡Únete a nosotros y empieza a construir el sitio web de tus sueños con facilidad y profesionalismo.',
        color: 'var(--dark-color)',
        positionTitle: 'left',
        img: '/images/services/hostings/sitejet-board.webp',
        reverse: true,
        },         

        dataSiteServiceWebMail: { 
            title: "¡Todos nuestros Planes Hosting incluyen servicio WebMail!",
            description: "Refuerza la Identidad Digital de tu Marca con iHosting.",
            positionTitle: 'left',
            color: 'var(--dark-color )',
        },
        // title site js
        // data sitejs 2
        dataSiteSegurity: {
        // description: 'En iHosting, entendemos la importancia de contar con herramientas potentes y accesibles para crear tu presencia en línea. Por eso, incluimos SiteJet Builder en todos nuestros planes, para que puedas lanzar y gestionar tu sitio web con la máxima eficiencia y sin complicaciones. ¡Únete a nosotros y empieza a construir el sitio web de tus sueños con facilidad y profesionalismo.',
        color: 'var(--dark-color)',
        positionTitle: 'left',
        img: '/images/services/hostings/banner_roundcube.webp',
        reverse: true,
        list: [
            { text: 'Profesionalismo: Un correo electrónico que incluye el nombre de tu empresa (empleado@tuempresa.cl) presenta una imagen más profesional que usar un servicio gratuito y genérico. Esto transmite seriedad y compromiso hacia tu negocio.', icon: 'fa fa-check'},
            { text: 'Branding: Cada correo que envías actúa como una oportunidad de marca, refuerza la identidad de tu empresa y aumenta la visibilidad.', icon: 'fa fa-check'},
            { text: 'Confianza: Los clientes son más propensos a confiar en un correo electrónico que proviene de un dominio empresarial personalizado.', icon: 'fa fa-check'},
            { text: 'Control y Seguridad: Tener tu propio dominio te permite tener un mayor control sobre las políticas de seguridad y las configuraciones de tus cuentas.', icon: 'fa fa-check'},
            { text: 'Personalización y Gestión: Puedes crear direcciones de correo electrónico específicas para diferentes funciones o departamentos, lo que facilita la organización y gestión.', icon: 'fa fa-check'},
        
        ]
        },         


        // data section beneficios --- 
        dataTitleBeneficios: {
        title: '¡Aprovecha todos los beneficios que entrega iHosting!',
        // description: 'Donde tendras asesoría y soporte técnico en tú idioma especializado en resolver tus inquietudes.',
        color: 'var(--dark-color)',
        positionTitle: 'left'
        },
        dataListBeneficios:{
            dataListServiceInfo: [
                {title: 'Múltiples canales de comunicación', 'description': 'Contaras con soporte a través de ticket, chat Online o WhatsApp lo que le permitirá obtener respuestas rápidas y directas a sus preguntas o problemas.', icon: 'fa fa-check'},
                {title: 'Correos electrónicos con tu dominio', 'description': 'Te permite crear direcciones de correo personalizadas para su negocio, aumentando la profesionalidad de su marca.', icon: 'fa fa-check'},
                {title: 'Escalabilidad', 'description': 'Puedes actualizar tu plan de hosting fácilmente para adaptarse al aumento de tráfico y demanda.', icon: 'fa fa-check'},
                {title: 'Compatibilidad con lenguajes', 'description': 'Es compatible con una amplia gama de lenguajes de programación, lo que permite a los emprendedores desarrollar sus sitios utilizando la tecnología preferida.', icon: 'fa fa-check'},
            ],
            expande: true,
            // img: '/images/about-us/hosting-1200.png' 
        },


        // data section more --- 
        dataTitleMore: {
        title: '¿Te parece poco?',
        description: 'Los datos del servidor y las bases de datos mysql se respaldan diariamente por hasta 14 días, y contamos con dos sistemas geográficamente independientes de respaldos para garantizar la tranquilidad de tu información en todo momento.',
        color: 'var(--dark-color)',
        positionTitle: 'left'
        },
        dataSectionMore:[
            {
                title: 'Seguridad',
                text: 'Sello de seguridad a la vista.',
                img: 'images/services/hostings/seguridad.webp'
            },
            {
                title: 'Comunicación encriptada',
                text: 'Encriptación SHA-2 y de 2048 bits.',
                img: 'images/services/hostings/encriptada.webp'
            },
            {
                title: 'SSL Gratuito',
                text: 'SSL Gratuito Let’s Encrypt con renovación gratis e ilimitada.',
                img: 'images/services/hostings/ssl.webp'
            },
            {
                title: 'Migración gratuita',
                text: 'Migración gratuita *sujeta a evaluación.',
                img: 'images/services/hostings/migracion_gratuita.webp'
            },


        ],

        // data section more --- 
        dataTiteTablePrices: {
            title: 'Tabla de precios',
            description: '¿Dudas? Te ayudamos a elegir un plan y migrar. ¡Contactanos!',
            color: 'var(--primary-color)',
        },
        dataTablePrices:{
            orderedKeys: ['Emprendedor', 'Hosting Básico Anual', 'Hosting Premium Anual'], // Define el orden deseado de los sercios en la tabla
            textoDescription: {
            'Emprendedor': 'Pensado en personas, empresas o proyectos que no requieren mayores recursos para operar. Tendrán lo mejor del servicio iHosting, en versión limitada y a un precio insuperable.',
            'Hosting Básico Anual': 'Creamos los Planes básicos para quienes necesitan más y mejores recursos. Más espacio, más recursos,memoria bla bla bla.',
            'Hosting Premium Anual': 'Desarrollamos nuestra Categoría Ultimate para quienes requieren recursos de primera línea. Proyectos de alto rendimiento, Sitios con necesidades ¿? ¿? o Empresas de Tráfico Alta Demanda'

            },
            orderkeysAtributos: ['0', '1', '7', '9', '10'] // Define ls atributos que quiere ver
        },


        // data e learning
        dataTitleElearning: {
        title: 'iHosting E-learning',
        // description: 'Donde tendras asesoría y soporte técnico en tú idioma especializado en resolver tus inquietudes.',
        color: 'var(--dark-color)',
        positionTitle: 'left'
        },
        dataElearning:{
            listText: [
                { text: 'Flexibilidad y crecimiento sin complicaciones o difíciles configuraciones.'},
                { text: 'Asesoramiento permanente para que crezcas sin interrupciones. '},
                { text: 'Infraestructura dedicada y optimizada para sacar máximo provecho a plataformas e-learning.'},
                { text: 'Puedes comenzar con pocos alumnos y crecer a cientos de miles de alumnos en línea.'},
            ],
        },

            // data para la seccion Complementos potentes ---
        dataTitleList: {
        title: 'Contamos con seguridad avanzada: Imunify360',
        description: 'Imunify360 es una solución avanzada de seguridad cibernética diseñada para ofrecer un nivel excepcional de protección para tu sitio web. Al adoptar Imunify360, te beneficiarás de una serie de ventajas clave que fortalecerán la seguridad y el rendimiento de tu plataforma en línea.',
        color: 'var(--dark-color)',
        }, 
        dataList:{
        dataListServiceInfo:[
            {title: 'Detección y Prevención Proactivas', 'description': 'Imunify360 utiliza tecnologías avanzadas para detectar y prevenir amenazas en tiempo real. Su sistema proactivo identifica patrones de comportamiento malicioso y aplica medidas preventivas. antes de que puedan comprometer la integridad de tu sitio.', icon: 'fa fa-check'},
            {title: 'Protección contra Ataques Web', description: 'Este sistema proporciona una sólida defensa contra ataques web, incluyendo inyecciones SQL, ataques de fuerza bruta y otros métodos utilizados por ciberdelincuentes. Tu sitio estará protegido  contra vulnerabilidades comunes que podrían ser explotadas.',  icon: 'fa fa-check'},
            {title: 'Escudo contra Botnets', description: ' Imunify360 actúa como un escudo robusto contra botnets, redes de dispositivos comprometidos que  pueden ser utilizadas para llevar a cabo ataques masivos. Esta capa adicional de seguridad bloquea el acceso no autorizado y protege tu sitio contra eventos adversos.',  icon: 'fa fa-check'},
            {title: 'Identificación de Malware Avanzada ', description: ' El sistema realiza análisis exhaustivos en busca de malware, identificando incluso las amenazas más sofisticadas. Esta capacidad avanzada garantiza la detección temprana y la eliminación rápida de cualquier código malicioso.',  icon: 'fa fa-check'},
            {title: 'Protección contra Ransomware ', description: ' Imunify360 protege tu sitio contra ransomware, evitando que tus archivos sean cifrados y garantizando que tu información valiosa permanezca segura y accesible.',  icon: 'fa fa-check'},
            {title: 'Actualizaciones de Seguridad Automáticas ', description: 'Mantén tu sitio siempre protegido con las actualizaciones automáticas de seguridad. Imunify360 se mantiene al día con las últimas amenazas y despliega actualizaciones continuas para enfrentar. nuevos desafíos de seguridad.',  icon: 'fa fa-check'},
            // {title: 'Ancho de banda de red privada ilimitado ',  icon: 'fa fa-bolt'},
            // {title: 'Virtualización con Vmware calidad empresarial  ', 'description': 'Virtualización con Vmware calidad empresarial con visualización de alto rendimiento.',  icon: 'fa fa-bolt'},
        ],
        expande: true
        },
        DataSectionServices: {
            "Emprendedor": { 
                title: "Planes Emprendedor",
                description: " Pensado en personas, empresas o proyectos que no requieren mayores recursos para operar. Tendrán lo mejor del servicio iHosting, en versión limitada y a un precio insuperable.",
            },
            "Hosting Básico Anual": { 
                title: "Planes Básicos ",
                description: " Creamos los Planes básicos para quienes necesitan más y mejores recursos. Más espacio, más recursos y más memoria.",

            },
            "Hosting Premium Anual": {
                title: "Planes Premium",
                description: "Desarrollamos nuestra Categoría Ultimate para quienes requieren recursos de primera línea. Proyectos de alto rendimiento.",
            },
            "Hosting Wordpress Anual":{
                title: "Planes Wordpress Anual",
                description: "Ahorra contratando planes por 2 o 3 años. ¡Contáctanos!"
            }

        },
              // data section steep to steep 
      dataTitleMicrosoftAzure: {
        titleBox:{
          title: 'Somos Partner de Cloudflare',
          description: 'Cloudflare es una potente red de entrega de contenido (CDN) que aumenta la velocidad y seguridad de tu sitio web. Al utilizar Cloudflare, tu sitio se beneficia de una distribución global de servidores, lo que significa que tus visitantes acceden a tu contenido de forma más rápida y fiable, sin importar dónde se encuentren en el mundo.',
          color: 'var(--dark-color)',
          positionTitle: 'left'
        },

        data:{
          title: 'Seguridad',
          subtitle: 'Cloudflare proporciona una capa adicional de seguridad protegiendo tu sitio web contra ataques DDoS, amenazas de seguridad y tráfico malicioso. Su tecnología avanzada de mitigación de amenazas y su robusto firewall de aplicaciones web aseguran que tu sitio permanezca seguro y operativo las 24 horas, los 7 días de la semana.',
          img: 'img/logos/cloudflare.svg',
          btn: {
            text: 'Cotiza con nosotros',
            link: '/nosotros#contacto'
          }

        }

    },
    }),

    computed: {
        ...mapGetters(["getServices_", "getData"]),
        data_() {
            return this.getData?.web;
        },
        dataHosting() {
            return this.getData?.web.hosting;
        },
        widthCardsServices(){
    if(window.innerWidth > 1300 || window.innerWidth <= 450){
        return {
          width: '380px',
          min_width: '340px',
          max_width: '385px'
        }
      }
      else{
        return {
          width: '290px',
          min_width: '285px',
          max_width: '300px'
        }
      }
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        ...mapActions(["getServices"]),
        initComponent() {
            setTimeout(() => {
                if (this.getData) {
                this.data_.hosting.forEach((hosting, index) => {
                    this.getServices(hosting.subcategoria).then((res) => {
                    let data = [];
                    res.forEach((item) => {
                        let i = this.data_.hosting[index]["servicios"].includes(item.id);
                        if (i) {
                        data.push(item);
                        }
                    });
                    let dataFiltered = this.filterForSpace(data);
                    // Funcion que ordena los servicios de su categoria de menor a mayor por su precio
                    let categoriasOrdenada = this.categoriesSort(dataFiltered);
                    this.$set(this.categories, hosting.nombre, categoriasOrdenada);
                    });
                });

                //   this.swiper_component_fast_static();
                //   if (this.$route.query.id) {
                //     this.tab = "#" + this.$route.query.id;
                //     this.retryTwo();
                //   } else {
                //     this.retryOne();
                //   }
                //   this.accordion();
                } else {
                this.initComponent();
                }
            }, 100);
            },
        categoriesSort(service){
            return service.sort( (a,b)=> a.precio - b.precio);
        },
        filterForSpace(services){
            if(services.length > 4){
                let data =  services.filter((e)=> e.atributos[0].pivot.valor != "64 GB" && e.atributos[0].pivot.valor != "128 GB" );
                // console.log("data filterspace: ", data)
                return data;
            }else{
                // console.log("services wordpress? ", services)
                return services
            }
        }
    },
}
</script>

<style scoped>

.hostingMain{
    overflow: hidden;
}

.BannerSlider_sliderCardPlans{
  min-height: 835px;
}
.portada{
  padding: 0px 0px 0px;
}

 .azureMain_imageList, .section_tablaPrice, .section_cardMore, .sectionSiteJet{
    padding: 50px 0px 20px;
}

.azureMain_imageList, .section_tablaPrice, .section_cardMore{
    min-height: 72vh;

}

.ssdMain_listResources{
    padding: 0px 0px 20px;
}
.sectionCards{
    min-height: 58vh;
}
.section_cardMore{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section_cardMore_container_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:  0 auto;
    width: auto;
}
.section_tablaPrice{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100vh;
}
.sectionHosting{
    padding: 80px 0px 80px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 65vh;
}

.sectionHosting_eLearning{
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.sectionHosting_slider{
    min-height: 735px;
    width: 100%;
    /* padding: 0px 0px 100px; */
}
.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -75px;
}

.nubesComponent_top{
  position: absolute;
    top: 38px;
    left: 0;
    z-index: 5;

}

.sectionHosting:nth-child(1), .sectionHosting:nth-child(3){
    background: var(--grey-color);
}

.sectionComplementos{
    padding: 70px 0px 40px;
    min-height: 90vh;
    /* z-index: 8; */
}
.sectionBeneficios{
    min-height: 58vh;
    padding: 100px 0px 100px;
}


/* section image text  sitejs*/
/*  */

.sectionSiteJet{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}

.section_site{
  min-height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}
.azureMain_beneficios{
  min-height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}



/* end */

.section_mail_img{
    max-width: 300px;
    min-width: 150px;
    max-height: 170px;
    padding: 0px !important
}
/* .container_loading{
  min-height: 735px;
  width: 100%;
  height: 100%;
  min-height: 725px;
  padding: 100PX 20PX;
  display: flex;
  align-items: flex-start;
  justify-content: center;
} */
@media (max-width:1580px){
    .azureMain_imageList{
        padding: 105px 10px 50px;
    }
}
@media(max-width:1300px){
    .section_service{
        padding: 45px 30px;   
    }
    .sectionComplementos{
        padding: 30px 0px 80px;
    }
    .sectionHosting_eLearning{
        flex-direction: column-reverse;
    }
}
@media(max-width: 750px){
    .azureMain_imageList{
    flex-direction: column-reverse;
} 
}

@media (max-width: 650px) {
.sectionSiteJet{
    padding: 20px 15px;
}
.section_site{
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;
}
.sectionSiteJet_title{
    display: none;   
}

.sectionBeneficios{
    padding: 10px 0px 50px;
}
.sectionComplementos{
padding: 65px 0px 40px;
}

.section_service{
flex-direction: column;
gap: 50px;   
padding: 40px;
}

.section_mail_img{
    max-width: 230px;
    max-height: 143px;
}

}
@media (max-width: 600px){
    /* .sectionHosting_slider{
    max-width: 100%;
    } */
    .portada{
  padding-top: 10px 0px 0px;
}
.azureMain_imageList{
  padding: 80px 10px 60px;
}
.sectionHosting{
    padding: 50px 0px 20px;
}
}
</style>