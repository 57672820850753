<template>
  <div v-if="item">
    <div class="pt-section-title-1">
      <h5 class="pt-section-title title-service" style="margin-bottom: 0">{{ item.nombre }}</h5>
      <p
        style="color: #00245078; font-size: 20px; text-transform: uppercase; position: relative; top: -7px"
        id="vue-content"
        class="des-header"
      >
        <span class="imp-word">{{ item.descripcion }}</span>
      </p>
    </div>

    <!-- HEADER CONFIGURACIÓN -->
    <header-nav-component
      :title="'Configuración de Servicio'"
      :back_="true"
      :loading="loading"
      :step_config="step_config"
      @back="back"
      style="margin-top: -28px"
    />

    <div class="col-md-12 px-0 change-size-v" style="margin-top: 7px; margin-left: 0">
      <!-- COMPONENTE DE CONFIGURACIÓN -->
      <div class="wrap-blog pt-fancybox2-active">
        <div class="col-md-12 padding-responsive px-0 mb-3">
          <configuration-component
            v-if="step == 3"
            :campos="subcategory.campos_completables"
            :id="subcategory.id"
            :type="1"
            :service="item"
            :validate_="validate_"
            :flag="true"
            :step_config="step_config"
            @add="addDomain"
            @configFinish="changeConfigStep"
          />
        </div>
      </div>
      <div v-if="filterCategory.length > 0">
        <!-- SERVICIOS ADICIONALES -->
        <div v-if="subcategory && step_config == 2" class="adicionales-content cd-filter-block2 slide-jq">
          <div v-for="(adicional, idx) in filterCategory" :key="idx" class="container-collapse">
            <div
              class="panel-heading mb-2"
              data-toggle="collapse"
              :href="'#item' + idx"
              role="button"
              aria-expanded="true"
              :aria-controls="'item' + idx"
            >
              <h4
                class="panel-title collapse-title"
                style="font-weight: 400 !important; font-size: 23px !important; cursor: pointer;"
              >
                {{ adicional.categoria.nombre }}
              </h4>
              <!-- BUTTON COLLAPSE - + -->
              <i @click="()=> collapse[idx].show = !collapse[idx].show" 
                :class="!collapse[idx].show ? 'fa fa-plus fa-custom-collapse' : 'fa fa-minus fa-custom-collapse' " 
                style="cursor: pointer;padding: 10px;" aria-hidden="true"></i>

              <em style="position: relative; top: -4px"></em>
            </div>

            <!-- COLLAPSE SERVICIOS ADICIONALES -->
            <ul class="collapse show fade-in" :id="'item' + idx" style="padding-left: 0">
              <div class="collapse-target" style="display: flex; flex-flow: row wrap">
                <div
                  style="display: flex; flex-flow: row wrap; margin-top: 5px; width: 100%"
                  class="pricing special card-services-container"
                >
                  <div
                    v-for="(service, idx) in adicional.servicios_activos"
                    :key="idx"
                    class="col-md-6 pb-1 card-optional-service"
                  >
                    <!-- COMPONENTE SERVICIO -->
                    <card-service
                      v-if="subcategory"
                      :item="service"
                      :category="adicional"
                      :subcategory="adicional.categoria"
                      :type="2"
                      :change="change"
                      @showConfiguration="hideAll"
                    />
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <footer-component
      @back="back()"
      @goToCheckout="goToCheckout"
      @goToCart="goToCart"
      :service="item"
      :contrato="contrato"
      :step="step"
      v-if="step == 3"
      class="only-desktop"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderNavComponent from "../HeaderNavComponent";
import ConfigurationComponent from "../ConfigurationComponent";
import footerComponent from "../FooterComponent";

export default {
  components: {
    HeaderNavComponent,
    ConfigurationComponent,
    footerComponent,
    "card-service": () => import(/* webpackChunkName: "card-service" */ "../CardOptionalService"),
  },
  props: ["item", "subcategory", "step", "validate_", "contrato"],
  data: () => ({
    loading: true,
    change: null,
    step_config: 1,
    collapse: []
  }),
  watch: {
    step() {
      this.step_config = 1;
    },
  },
  created() {
    this.$root.$on("returnStepConfg", () => this.returnStepConfg());
  },

  computed: {
    ...mapGetters(["getData"]),
    data_() {
      return this.getData?.carrito;
    },
    filterCategory() {
      let categories = [];

      this.subcategory.adicionales.forEach((value) => {
        if (!this.data_.servicios_ocultos.includes(parseInt(value.id)) ) {
          // console.log("no hay servicios ocultos ");
        // console.log("servicios NOO ocultos: ", this.data_.servicios_ocultos);
          if (value.servicios_activos.length > 0) {
                        // console.log("categoria: ", value);
            this.collapse.push({id: value.id, show: true});
            categories.push(value);
          }
        }else{
          // console.log("hay servicios ocultos: ", value.id);
        }
      });

      return categories;
    },
  },
  methods: {
    returnStepConfg() {
      this.step_config = 1;
      window.scrollTo(0, 0);
    },
    changeConfigStep() {
      this.step_config = 2;
      window.scrollTo(0, 0);
      setTimeout(() => {
        $(".adicionales-content").removeClass("slide-jq");
      }, 800);
    },
    back() {
      this.$emit("back", this.item);
    },
    addDomain(params) {
      this.$emit("add", { service: this.item, form: params });
      this.domanValidate = true;
      // this.loading = fa
    },
    goToCart() {
      this.$emit("goToCart");
    },
    goToCheckout() {
      this.$emit("goToCheckout");
    },
    hideAll(id) {
      this.change = id;
    },
  },
};
</script>

<style>
.ul-v-list {
  text-align: left;
  display: flex;
  flex-flow: column;
  margin-bottom: 0px;
}
.title-bar-left2 {
  text-align: left;
  margin-top: 31px;
  padding: 10px 30px;
  font-size: 17px;
}

.title-bar-right2 {
  text-align: right;
  margin-top: 31px;
  padding: 10px 30px;
  font-size: 17px;
}

.cd-filter-block2 input,
.cd-filter-block2 select {
  padding: 15px;
}

.cd-filter-block2 ul {
  transition: ease 0.5s;
}

.padding-responsive {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.card-service {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.container-collapse {
  border-radius: 3px;
  padding: 5px 0px 0px 0px;
  margin-bottom: 15px;
}

.collapse-title {
  margin-top: 10px;
}

.panel-heading {
  text-align: center;
}

.header-nav-adicionales {
  margin-top: 28px;
  margin-bottom: 9px;
}

.card-service-optional {
  margin-top: 20px;
}

.card-service-optional:nth-child(1) {
  margin-top: 0px;
}

.title-service {
  font-size: 48px;
}
@media (max-width: 991px) {
  .span-header-v h3 {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .header-nav-adicionales {
    margin-bottom: 10px;
  }
  .wrap-blog .wrapper {
    padding: 0 !important;
  }

  .card-service {
    padding: 0;
  }

  .padding-responsive {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .title-service {
    height: 66px;
    font-size: 43px;
  }
  .des-header {
    max-height: 30px;
    top: -27px !important;
    position: relative !important;
    font-size: 15px !important;
  }

  .span-header-v h3 {
    font-size: 16px;
  }
}

@media (max-width: 380px) {
  .title-service {
    font-size: 32px !important;
  }
}

@media (max-width: 360px) {
  .span-header-v h3 {
    font-size: 14px;
  }
}
</style>
