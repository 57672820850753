<template>
  <div id="nosotros" class="overflow-custom-v">

    <!-- Get-In Touch Start class="pt-blue-background" -->
    <section id="contacto" class="pt-grey-background">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12">
            <div class="sticky-img">
              <img src="images/ilustraciones/001.png" alt="t-img" class="img-fluid" />
            </div>
          </div>
          <div class="col-xl-6 col-md-12 mt-3 mt-xl-0">
            <div class="pt-contact-form">
              <div class="row">
                <div class="col-12 p-0">
                  <div class="pt-section-title-1">
                    <h2 class="pt-section-title">Pago Rápido</h2>
                    <p class="pt-section-description">
                      Paga de forma expedita tu servicio solamente ingresando el número de la nota de cobro junto con un
                      email o RUT asociado a la cuenta.

                    </p>

                  </div>
                </div>
              </div>
              <div class="row">
                <form @submit.prevent="validNoRobot" class="quote-from">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group" style="margin-bottom: 4rem">
                        <input v-model="form.nota_cobro" @keypress="onlyNumber" type="text" name="nota_cobro" required
                          data-vv-as="nota_cobro" placeholder="Número nota de cobro"
                          class="form-control input-white-custom mb-20"
                          :class="{ 'vue-error': errors.first('nota_cobro') }" key="3_" maxlength="13" />
                        <input v-model="form.clienteEmailRut" type="text" name="clienteEmailRut" required 
                          data-vv-as="clienteEmailRut" placeholder="Email o RUT"
                          class="form-control input-white-custom mb-20"
                          :class="{ 'vue-error': errors.first('clienteEmailRut') }"
                          key="4_"
                          maxlength="75"
                        />
                        <FormError :error="errors.first('nota_cobro')"> </FormError>
                        <FormError :error="errors.first('clienteEmailRut')"> </FormError>

                      </div>
                    </div>
                    <div class="col-md-12 mb-4">
                      <div class="input-box input_box_turnstile">

                        <!-- Turnstile cloud -->
                        <div id="pagoRapidoCloud"></div>
                      </div>

                      <!-- <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="token"> </vue-recaptcha> -->
                    </div>

                    <div class="col-md-12">
                      <button type="submit" alt="Buscar nota de cobro" aria-label="Buscar nota de cobro"
                        class="btn btn-default-grad-purple-fill btn-lg btn-block"
                        style="width: 100%; min-width: 180px; padding: 5px 20px !important"
                        :class="[verify ? 'verify-class' : 'normal-class']">
                        <span v-if="!verify" class="spinner-span">Buscar nota de cobro
                          <i class="fas fa-arrow-alt-circle-right spinner-i" style="top: 9px"></i></span>
                        <div v-else class="spinner-border text-secondary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Get-In Touch End -->
    <section id="co-location" class="pt-grey-background pt-0 detalle-notacobro" v-if="serviceDetail.nota_cobro">
      <div class="container">
        <div class="row justify-content-xl-center">
          <div class="col-xl-8 col-md-12 xl-offset-2">
            <div class="pt-fancybox-box-info" style="text-align: center;">
              <h4 class="pt-fancy-title">Nota de cobro número: {{ serviceDetail.nota_cobro.id }}</h4>
              <div class="row mt-2">
                <div class="col-md-1"></div>
                <div class="col-md-7" style="text-align: left; max-width: 98%; height: auto; overflow-wrap: break-word;">
                  <h5 style="font-size: 16px; white-space: nowrap;"><b style="white-space: wrap;">Cliente: {{
                    serviceDetail.nombre_cliente }}</b></h5>
                  <h5 style="font-size: 16px; white-space: unset; overflow-wrap: break-word; max-width: 100%;"><b>Correo:
                      {{ serviceDetail.correo }}</b></h5>
                  <h5 style="font-size: 16px; white-space: nowrap;"><b>Servicio: {{ serviceDetail.servicio.nombre ? serviceDetail.servicio.nombre : '(Nota de cobro sin servicio)'  }}</b>
                  </h5>
                  <h5 v-if="serviceDetail.dominio" style="font-size: 16px; white-space: nowrap;"><b>Dominio: {{
                    serviceDetail.dominio }}</b></h5>
                  <h5 style="font-size: 16px; white-space: nowrap;"><b>Monto a pagar: ${{
                    formatNumber(serviceDetail.nota_cobro.neto) }}</b></h5>
                </div>
                <div class="col-md-4">
                  <div class="col-md-12 px-0">
                    <hr class="d-block d-sm-none">

                    <div class="row">
                      <div class="col-md-12">
                        <div class="row card-checkbox px-1" style="position: relative; top: -7px">
                          <ul class="small-block-grid-3 pl-0" style="width: 100%; position: realtive; top: 3px">
                            <li class="col-md-12 px-0">
                              <label>
                                <input type="radio" v-validate="'required'" data-vv-as="Método de pago" name="payment"
                                  id="payment_1" key="1001" class="card-input-element v2-element" ref="radio_1" checked />

                                <div class="card-input">
                                  <div class="card-input-body">
                                    <div class="icon webpay"></div>
                                    WebPay
                                  </div>
                                </div>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
              @click="QuickPayOrder" alt="Pagar nota de cobro" aria-label="Pagar nota de cobro"
              style="width: 100%; height: 52px">
              <span>Pagar nota de cobro</span>
              <i class="fas fa-arrow-alt-circle-right ml-2" style="top: 2px; position: relative"></i>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionVencidas detalle-notacobro" v-if="notasVencidas">
      <div class="pt-section-title-1 text-center sectionVencidas_head">
        <h2 class="pt-section-title">Notas de cobro vencidas</h2>
        <p class="pt-section-description">
          Estimado {{ serviceDetailList.pendingNote[0].nombre_cliente }}, lamentablemente no es posible pagar esta nota de
          cobro sin antes pagar las notas de cobro vencidas:
        </p>
      </div>
      <div class="sectionVencidas_container">
        <div v-if="serviceDetailList.pendingNote" class="sectionVencidas_container sectionVencidas_containerList">
          <div v-for="(service, index) in serviceDetailList.pendingNote" :key="index"
            class="pt-fancybox-box-info sectionVencidas_container_boxList" style="text-align: center;">
            <div class="sectionVencidas_container_boxList_container">
              <span class="sectionVencidas_container_box_enfasis"
                :class="service.nota_cobro.estado == 2 ? 'sectionVencidas_container_boxList_enfasis' : 'sectionVencidas_container_box_enfasisActive'">
                {{ service.nota_cobro.id }}</span>
              <span class="sectionVencidas_container_boxList_enfasis"
                :class="service.nota_cobro.estado == 2 ? 'sectionVencidas_container_box_enfasis' : 'sectionVencidas_container_box_enfasisActive'">
                {{ service.nota_cobro.estado == 2 ? new Date(service.nota_cobro.epoch_vencimiento *
                  1000).toLocaleDateString() : service.nota_cobro.epoch_vencimiento }}</span>
              <h4 class="sectionVencidas_container_boxList_title"> {{ service.identificador != null ? `
                ${service.identificador}` : ` ${service.servicio.nombre} ` }}</h4>
              <span class="sectionVencidas_container_boxList_price">${{ formatNumber(service.nota_cobro.neto) }}</span>

            </div>
            <div class="checkbox_container sectionVencidas_container_boxList_checkbox_container">

              <span @click="checkboxMutation(index, false)">No pagar</span>
              <div class="checkbox-example" :class="checkboxStates[index].isChecked ? 'checkbox-active' : ''"
                @click="checkboxMutation(index)">
                <input type="checkbox" :id="`checkboxOneInput${service.nota_cobro.id}`" v-model="service.isChecked"
                  @click="checkboxMutation(index)" :disabled="index == 0" />
                <label :for="`checkboxOneInput${service.nota_cobro.id}`"></label>
              </div>
              <span @click="checkboxMutation(index, true)">Pagar</span>
            </div>
          </div>
        </div>
        <div class="sectionVencidas_buttons">
          <button class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
            @click="QuickPayOrder" alt="Pagar nota de cobro" aria-label="Pagar nota de cobro"
            style="width: 100%; height: 52px">
            <span>Pagar</span>
            <i class="fas fa-arrow-alt-circle-right ml-2" style="top: 2px; position: relative"></i>
          </button>
        </div>
      </div>
    </section>

    <div style="height: 0px; position: absolute">
      <form id="form_webpay" :action="action">
        <input type="hidden" name="token_ws" v-model="token" />
        <button class="disabled-button" id="submit_webpay" type="submit"></button>
      </form>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TurnstileCloud from "@/hooks/segurity/turnstile";
import {
  formatNumber,
  swiper_component,
  changeColorSvg,
  masonry,
  contentTooltip,
  resolucion2,
  onlyNumber,
} from "@/helpers";
import FormError from "@/components/cart/FormError";

// import VueRecaptcha from "vue-recaptcha";

export default {
  metaInfo: {
    title: "ihosting | Pago rapido",
    htmlAttrs: {
      lang: "es",
      amp: true,
    },
    meta: [
      {
        name: "description",
        content: "Somos una empresa que cuenta con más de 21 años en el mercado enfocada en prestar soluciones de infraestructura e ingeniería TI robustas, brindando seguridad, una experiencia de soporte personalizada y siempre disponible para atender a sus requerimientos en el menor tiempo posible."
      },
      {
        name: "keywords",
        content: "ihosting, viña, chile, datacenter",
      },
    ]
  },
  components: {
    FormError,
    // VueRecaptcha,
  },
  data: () => ({
    // number: 0,
    verify: false,
    send: false,
    token: null,
    action: null,
    serviceDetail: "",
    serviceDetailList: [],
    notasVencidas: false,
    metodo_pago: "webpay",
    form: {
      nota_cobro: "",
      clienteEmailRut: "",
      robot: false,
    },
    checkboxStates: [],
    monto: 0,
  }),
  mounted() {

    setTimeout(() => {
      if(process.env.VUE_APP_ACTIVE_TURNSTILE == 'true'){
        TurnstileCloud.activeTurnstileCloud("pagoRapidoCloud");
      }
    }, 1000);


    // this.$router.push('home');
    this.form.nota_cobro = this.catchGetParameter('id');
    // console.log("getUser: ", this.getUser.rut);
    this.changeColorSvg();
    setTimeout(() => {
      this.masonry();
      this.swiper_component();
    }, 350);
    this.clearPagoRapido();

  },
  beforeDestroy() {
    // si el usuario se redirecciona a otra pagina, se borra todo elemento relacionado al turnstile
    if(typeof TurnstileCloud !== 'undefined'){
      TurnstileCloud.removeTurnstileCloud();
    }
  },
  methods: {
    ...mapActions(["getService", "sendResumeTo"]),
    ...mapMutations(["setResume", "clearCart", "setToken", "clearPagoRapido"]),
    formatNumber,
    swiper_component,
    changeColorSvg,
    masonry,
    contentTooltip,
    resolucion2,
    onlyNumber,

    checkboxMutation(indexAct, action = null) {

      if (indexAct == 0) return false;

      action != null
        ? this.checkboxStates[indexAct].isChecked = action
        : this.checkboxStates[indexAct].isChecked = !this.checkboxStates[indexAct].isChecked;

      if (this.checkboxStates[indexAct].isChecked) {
        // this.monto += this.checkboxStates[indexAct].nota_cobro.neto;
        this.checkboxStates.forEach((e, index) => {
          if (index < indexAct && index > 0) {
            this.checkboxStates[index].isChecked = true;
          }
        })
      } else {
        if (this.checkboxStates[indexAct + 1] && this.checkboxStates[indexAct + 1].isChecked) this.$toast.success("Para evitar periodos impagos, se deben pagar las notas de cobro por orden de vencimiento.", { duration: 4000, position: 'top-right' });
        this.checkboxStates.forEach((e, index) => {
          if (index > indexAct && index > 0) {
            this.checkboxStates[index].isChecked = false;
          }
        })
      }
    },

    submit() {
      if (this.form.nota_cobro) {
        if(process.env.VUE_APP_ACTIVE_TURNSTILE == 'true') this.form.tokenTurnstile = TurnstileCloud.getToken() // se agrega token para que lo validen
        this.clearPagoRapido();
        this.checkboxStates = [];
        this.notasVencidas = false;

        this.serviceDetail = false;
        this.serviceDetailList = [];
        this.$Progress.start();
              // console.log("this.form: ", this.form);
              this.getService(this.form).then((res) => {
                // console.log("res:??? ", res);
                if (this.getServiceList.notasVencidas == false && this.getService_.status && this.getService_.status != 'error') {
                  // console.log("No tiene notas vencidas.");
                  this.serviceDetail = this.getService_;
                  // console.log("detalleServicios: ", [this.serviceDetail.detalle_servicio]);
                  this.serviceDetail.detalle_servicio.es_pago_rapido = this.serviceDetail.es_pago_rapido;
                  this.serviceDetail.detalle_servicio.precio = this.serviceDetail.monto_cobrado;
                  let detalle_servicio = [this.serviceDetail.detalle_servicio];

                  this.setResume(detalle_servicio);
                  this.$Progress.finish();
                  this.send = true;
                  setTimeout(() => {
                    this.scrollDown();
                  }, 300);
                }
                else if (this.getServiceList.notasVencidas == true) {
                  console.log("Existen notas vencidas.");
                  this.serviceDetailList = this.getServiceList;
                  this.notasVencidas = true;
                  let detalleServicios = [];
                  this.serviceDetailList.pendingNote.forEach((element, index) => {
                    detalleServicios.push(element.detalle_servicio);
                    index == 0 ? element.isChecked = true : element.isChecked = false;
                    index == 0 ? this.checkboxStates.push({ isChecked: true }) : this.checkboxStates.push({ isChecked: false });


                  });
                  // console.log("detalleServicios: ", detalleServicios);
                  this.setResume(detalleServicios);
                  this.$Progress.finish();
                  this.send = true;
                  // console.log("getService true fin: ",this.serviceDetail );
                  setTimeout(() => {
                    this.scrollDown();
                  }, 300);
                }
                else if (this.getService_.status == 'error') {
                  console.log("[001]error...");
                  this.$toast.error(this.getService_.message);
                  this.$Progress.fail();
                }
                else {
                  console.log("[002]error...");
                  this.$toast.error('Sin resultados.');
                  this.$Progress.fail();
                }
                this.verify = false;
                TurnstileCloud.resetTurnstile();
              }).catch(error=>{
                console.log("catch submit: ", error);
                TurnstileCloud.resetTurnstile();

              });

      } else {
        this.$toast.error("Debes ingresar los datos correctos.");
        TurnstileCloud.resetTurnstile();
      }
    },
    QuickPayOrder() {
      this.$Progress.tempTransition({ speed: "2s", opacity: "2.6s", termination: 1300 });
      this.$Progress.start();
      this.verify = true;
      setTimeout(() => {
        let notascobro = [];
        let detalleServicios = [];
        if (this.notasVencidas) {
          this.serviceDetailList.pendingNote.forEach((service, index) => {
            service.isChecked = this.checkboxStates[index].isChecked;
            if (service.isChecked == true) {
              service.nota_cobro.detalle_servicio = service.detalle_servicio;
              notascobro.push(service.nota_cobro);
              detalleServicios.push(service.detalle_servicio);
            }

          });
          this.setResume(detalleServicios);
          console.log("detalleServicio actualizado: ");

        } else {
          notascobro = [this.serviceDetail.nota_cobro];
          notascobro[0].detalle_servicio = this.serviceDetail.detalle_servicio;
        }

        this.sendResumeTo({'notascobro': notascobro}).then((res) => {
          // console.log("res", res)
          if (res.status == "success") {
            this.action = res.formAction;
            this.token = res.tokenWs;

            this.clearCart();

            setTimeout(() => {
              this.$toast.success("Redireccionando...");
              this.$Progress.finish();
              var form = document.getElementById("form_webpay");
              form.submit();
            }, 500);
          } else {
            this.$toast.error("Error al procesar el pago");
            this.$Progress.fail();
            this.verify = false;
          }
        });
      }, 0);
    },
    // resetRecaptcha() {
    //   this.form.robot = false;
    //   this.$refs.recaptcha.reset()
    // },
    catchGetParameter(parameter) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(parameter) ? urlParams.get(parameter) : '';
    },
    scrollDown() {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(".detalle-notacobro").offset().top - 90
        },
        800
      );
    },
    clearResume() {
      this.serviceDetail = "";
      thisserviceDetailList = []
    },
    validNoRobot() {
      
      // setTimeout(() => {
        // estado que verifica si ya se recibió el token de cloudflare
        if (TurnstileCloud.getToken == null) {
          this.verify = false;
          this.$toast.error("Terminando de verificar que no eres un robot, intenta nuevamente.");
          this.$Progress.fail();
  
        } else {
          this.form.robot = true;
          this.verify = true;
          this.submit();
        }
      // }, 7500);

    }

  },
  computed: {
    ...mapGetters(["getService_", "getResume", "getServiceList"]),



    isPreviousCardUnchecked() {
      return (index) => {
        if (index === 0) {
          return true; // La primera card no está desactivada
        }
        if (!this.serviceDetailList.pendingNote[index - 1].isChecked) {
          // console.log("102: ", this.serviceDetailList.pendingNote[index - 1].isChecked);
          return true;
        }

      }

    },


  }
};
</script>
<style scoped>
.sectionVencidas {
  width: 100%;
  min-height: 400px;
  padding: 4%;
}

.sectionVencidas_head {
  margin-bottom: 30px !important;
}

.sectionVencidas_container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.sectionVencidas_containerList {
  width: 100%;
  overflow-x: auto;
  flex-direction: column;
  align-items: center;
}

.sectionVencidas_container_box {
  position: relative;
  max-width: 350px;
  width: auto;
  min-height: 220px;
  padding: 32px 25px 10px;
  border-radius: 10px;
  margin: 15px 45px;
  box-shadow: 0px 2px 10px 1px rgba(0, 136, 255, 0.08);
  border: 5px transparent solid;
  transition: .3 ease-in;

}

.sectionVencidas_container_boxList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 0px;
  min-width: 660px;
  max-width: 750px;
  flex-direction: column;
  min-height: 180px;
  max-height: 190px;
  border: 1px solid rgba(0, 0, 0, 0.038);
  border-radius: 8px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.036);
  margin-bottom: 20px;
}

.sectionVencidas_container_boxList_container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 55%;
  min-height: 84px;
}

.sectionVencidas_container_boxList_container .sectionVencidas_container_boxList_enfasis,
.sectionVencidas_container_boxList_container .sectionVencidas_container_boxList_title {
  border-right: 1px solid #0000002e;
  padding: 0 10px;
}

.sectionVencidas_container_boxList_price {
  padding: 0 10px;
  width: 18%;
  border-right: none;
}

.sectionVencidas_container_boxList_title {
  width: 50%;
  font-size: 16px;
}

.sectionVencidas_container_box_enfasis {
  color: rgb(255, 7, 7);

  height: 36px;
  width: 16%;
  border-right: 1px solid #0000002e;
  padding: 0 10px;
}

.sectionVencidas_container_box_enfasisActive {
  color: var(--blue);
  /* background: var(--blue); */


}

.sectionVencidas_container_boxList_enfasis {
  width: 18%;
}

.iconwebpay {
  background-position: 0px 50px;
  /* background-image: url("images/logos_payments.png"); */
  background-size: 140px 100px;
  width: 55px;
  height: 50px;
  margin: 5px auto
}

.checkbox_container {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sectionVencidas_container_boxList_checkbox_container {
  width: 100%;
  /* max-width: 350px; */
  margin: 0 auto;
  height: 38%;
  min-height: 58px;
  background: #dbefff;
}

.sectionVencidas_container_boxList_checkbox_container span {
  font-size: 13px;
  cursor: pointer;
  transition: .3s ease;
}

.sectionVencidas_container_boxList_checkbox_container span:hover {
  opacity: 0.7;
}

input[type=checkbox] {
  visibility: hidden;
}

.checkbox-example {
  width: 50px;
  height: 22px;
  background: #007bff36;
  margin: 20px 10px;
  position: relative;
  border-radius: 15px;
  margin: 0;
}

.checkbox-example label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: all .5s ease;
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 3px;
  background: #f6fafd;
}

/* .checkbox-example input[type=checkbox]:checked + label {
        left: 29px;
      } */
.checkbox-active input[type=checkbox]+label {
  left: 29px;
}

.checkbox-active label {
  background: var(--blue);
}

.sectionVencidas_buttons {
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
  display: flex;
  justify-content: center;

}

.sectionVencidas_buttons button {
  width: 90%;
  max-width: 600px;
}

.input_box_turnstile {
  width: 100% !important;
  padding: 0 12px;
}

@media(max-width:840px) {
  .sectionVencidas_container {
    flex-direction: column;
    align-items: center;
  }

  .sectionVencidas_container_box {
    margin: 15px;
  }
}
</style>
