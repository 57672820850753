<template>
  <div>
    <div
      class="pt-pricebox-1 text-center mt-0 shadow-card"
      :class="[
        addClass ? 'active' : '',
        category.id == getData.carrito.categoria_hosting.id ? 'pc-fbox-home' : '',
      ]"
    >
      <div class="pt-pricebox-info" style="margin-bottom: 20px !important;">
        <div v-if="category.id == getData.carrito.categoria_hosting.id" class="contbadage">
          <div class="badge plans" :class="[addClass ? 'bg-white' : 'bg-blue']">Prueba 5 días gratis</div>
        </div>
        <span class="pt-price-title">
          <span v-if="item.nombre.toUpperCase() == 'MICROSOFT 365 EMPRESA ESTÁNDAR'" style="    font-size: 17px;">{{ item.nombre }} </span><span v-else>{{ item.nombre }}</span></span >
        <br />
        <span class="pt-price-duration fromer"
          ><em>{{ item.descripcion }}</em></span
        >

        <h2 class="price">
          <span v-if=" category.id == 21 && item.moneda.nombre_corto == 'UF'" class="item-row-price" :data-node="true"
            >{{item.moneda.nombre_corto}} {{ changeSeparator((item.precio / 12 * form.cantidad).toFixed(2)) }}
          </span>
          <span v-else-if=" category.id == 21 && item.moneda.nombre_corto == 'USD'" class="item-row-price" :data-node="true"
            >{{item.moneda.nombre_corto}} {{ changeSeparator((item.precio / 12 * form.cantidad).toFixed(2)) }}
          </span>

          <span v-else-if=" category.id != 21 && item.moneda.nombre_corto == 'UF'" class="item-row-price" :data-node="true"
            >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
          </span>
          <span v-else-if=" category.id != 21 && item.moneda.nombre_corto == 'USD'" class="item-row-price" :data-node="true"
            >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
          </span>

          <span v-else class="row-price" :data-node="true"
            ><span class="item-row-price">$</span>
            <span>{{ category.id == 21 ? formatNumber(item.precio / 12 * form.cantidad) : formatNumber(item.precio * form.cantidad) }}</span>
            <!-- <span>{{ formatNumber(item.precio * form.cantidad) }}</span> -->
          </span>
        </h2>

        <!-- <p class="pt-price-duration">{{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}</p> -->
        <p  v-if=" category.id == 21 && item.moneda.nombre_corto == 'UF'" class="pt-price-duration">
          Mes / Contrato anual {{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
        </p>
        <p  v-else-if=" category.id == 21 && item.moneda.nombre_corto == 'USD'" class="pt-price-duration">
          Mes / Contrato anual {{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
        </p>
        <p  v-else-if="category.id == 21 && item.moneda.nombre_corto !='USD' && item.moneda.nombre_corto != 'UF' " class="pt-price-duration">
          Mes / Contrato anual {{ formatNumber(item.precio * form.cantidad) }}
        </p>
        <p v-if="category.id != 21" class="pt-price-duration">{{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}</p>

      </div>
      <div v-if="subcategory.campos_completables.length > 0">
         <ul class="pt-list-info pt-list-info-padding">
        <li v-for="(atribute, idx) in item.atributos" :key="idx" class="active" :style="atribute.nombre == 'Activación' ? 'display:none;' : '' ">
          <div v-if="atribute.nombre != 'Activación'" style="flex-flow: row nowrap">
            <span style="margin-right: 25px"> 
              <div style="display: flex; flex-flow: row nowrap"></div>
              <span>{{ atribute.nombre.toUpperCase() }} </span>
            </span>

            <span style="float: right"
              ><span
                v-if="
                  atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                  atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                  atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                "
                class="span-infinito-cart"
                >∞</span
              >
              <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
            </span>
          </div>
        </li>
      </ul>
      </div>

      <div v-else>
        <ul class="pt-list-info pt-list-info-padding">
                              <li v-for="(atribute, idx) in item.atributos" :key="idx" class="active" :style="atribute.nombre == 'Activación' ? 'display:none;' : '' ">
                                <div v-if="atribute.nombre != 'Activación'" style="flex-flow: row nowrap">
                                  <div
                                    v-if="
                                      atribute.nombre.toUpperCase() != 'APLICACIONES OFFICE' &&
                                      atribute.nombre.toUpperCase() != 'CUENTAS'
                                    "
                                  >
                                    <span style="margin-right: 25px">
                                      <div style="display: flex; flex-flow: row nowrap"></div>
                                      <span>{{ atribute.nombre.toUpperCase() }} </span>
                                    </span>

                                    <span style="float: right"
                                      ><span
                                        v-if="
                                          atribute.pivot.valor.toUpperCase() == 'ILIMITADOS' ||
                                          atribute.pivot.valor.toUpperCase() == 'ILIMITADA' ||
                                          atribute.pivot.valor.toUpperCase() == 'ILIMITADAS'
                                        "
                                        class="span-infinito-cart"
                                        >∞</span
                                      >
                                      <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <hr class="my-3" />

                            <div>
                              <!-- title if appsServices exist-->
                              <h3
                                style="font-size: 16px"
                                :class="[ addClass ? 'label-b-white' : 'label-b-blue' ]"
                              >
                              {{ item.codigo == 'M365EB-A-SNT' ? "VERSIONES WEB OFFICE INCLUIDAS" : "APLICACIONES OFFICE INCLUIDAS" }}
                             <!-- {{ item.appsServices.web}} -->
                              </h3>
                              <div>
                                <!-- si instalables es si y web no -->
                                <img v-if=" item.codigo == 'M365EE-A-NSB' || item.codigo == 'M365EE-M' || item.codigo == 'M365EP-A-NSA' || item.codigo == 'M365EP-M' || item.codigo == 'M365EB-A-NSB' || item.codigo == 'AM365-A' || item.codigo == 'AM365-A-NSO' "
                                  src="images/microsoft/imagen_3.svg"
                                  alt="microsoft office 365 paquete ihosting completo"
                                />
                                <!-- si web es si e instalable no -->
                                <img v-else-if=" item.codigo == 'M365EB-A-SNT' || item.codigo == 'M365EB-A-SNE' || item.codigo == 'M365EB-A-SNO' || item.codigo == 'M365EB-A-SNA' || item.codigo == 'M365EB-A-SNB' "
                                  src="images/microsoft/imagen_5.svg"
                                  alt="microsoft office 365 paquete ihosting basico"
                                />
                                <p v-else style="margin: 5px auto;font-size: 16px; color: #194960; font-weight: 500;">Ninguna</p> 
                              </div>
                            </div>

                            <hr class="my-3" />

                            <div>
                              <h3
                                style="font-size: 16px"
                                :class="[addClass ? 'label-b-white' : 'label-b-blue']"
                              >
                                SERVICIOS INCLUIDOS
                              </h3>

                              <div>
                                <img v-if="item.codigo == 'M365EB-A-NSA' || item.codigo == 'M365EB-A-SNA' || item.codigo ==  'M365EP-A-NSA' "
                                src="images/microsoft/imagen_1.svg"
                                alt="microsoft paquete servicio completo"
                                />
                                <img v-else-if="item.codigo == 'M365EB-A-SNB' || item.codigo == 'M365EB-M' ||  item.codigo ==  'M365EE-A-NSB' || item.codigo ==  'M365EE-M' "
                                src="images/microsoft/imagen_0.svg"
                                alt="microsoft paquete servicio basico"
                              />
                              <img v-else-if="item.codigo == 'M365EB-A-NSE' || item.codigo == 'M365EB-A-SNE' || item.codigo == 'EOM365-A'  || item.codigo == 'EOM365-A-NNE' || item.codigo == 'EOM365-A-NNE'"
                                src="images/microsoft/imagen_4.svg"
                                alt="microsoft paquete servicio exchange"
                              />
                              <img v-else-if="item.codigo == 'M365EB-A-NSO' || item.codigo == 'M365EB-A-SNO' || item.codigo == 'M365AP-A-NSO' || item.codigo == 'AM365-A' || item.codigo == 'M365ODB01A' || item.codigo == 'AM365-A-NSO' "
                                src="images/microsoft/imagen_2.svg"
                                alt="microsoft paquete servicio onedrive"
                              />
                              <img v-else-if="item.codigo == 'M365EB-A-SNT' || item.codigo == 'M365EB-M' "
                                src="images/microsoft/teams.svg"
                                alt="microsoft paquete servicio teams"
                              />
                              </div>
                              <!-- <div v-else>
                                <img
                                v-if="item.nombre == 'Aplicaciones Microsoft 365'"
                                src="images/microsoft/imagen_2.svg"
                                alt="microsoft office 365"
                              />
                              <img
                                v-else-if="item.nombre == 'Microsoft 365 Empresa Básico'"
                                src="images/microsoft/imagen_0.svg"
                                alt="microsoft office 365"
                              />
                              <img
                                v-else-if="item.nombre == 'Microsoft 365 Empresa Premium'"
                                src="images/microsoft/imagen_1.svg"
                                alt="microsoft office 365"
                              />
                              <img
                                v-else-if="item.nombre == 'Microsoft 365 Empresa Estándar'"
                                src="images/microsoft/imagen_0.svg"
                                alt="microsoft office 365 estandar"
                              />
                              <img
                                v-if="item.nombre == 'OneDrive para Empresa (plan 1)'"
                                src="images/microsoft/imagen_2.svg"
                                alt="microsoft office 365"
                              />
                              <img
                                v-if="item.nombre == 'Exchange Online (plan 1)'"
                                src="images/microsoft/imagen_4.svg"
                                alt="microsoft office 365"
                              />  
                              </div> -->

                            </div>
      </div>
     

      <div class="pt-btn-container">
        <div class="pt-button-block">
          <div v-if="subcategory.campos_completables.length > 0">
            <a
              @click="next_step(item)"
              style="z-index: 20; cursor: pointer"
              class="pt-button"
              :class="{ 'pt-button-change-color': addClass }"
            >
              <span class="text">Configurar</span>
            </a>
          </div>

          <div v-else class="row">
            <div class="col-12 px-0 mb-2" style="height: 83px; z-index: 10" :data-node="true">
              <div style="text-align: center; text-align: -webkit-center">
                <label class="vue-input mb-2" :data-node="true" style="margin-bottom: 0rem"
                  ><b :data-node="true" :class="[addClass ? 'label-b-white' : 'label-b-blue']"
                    >Cuentas</b
                  ></label
                >
                <div style="width: 85px; position: relative; top: -7px; margin: auto;"   :data-node="true">
                  <input
                    v-model="form.cantidad"
                    onkeypress="if((event.charCode < 48 || event.charCode > 57) || this.value.length > 1) return false"
                    @keydown="checkNumber($event, form.cantidad)"
                    min="1"
                    max="99"
                    type="number"
                    name="cantidad"
                    id="cantidad_"
                    class="input_num input-num-serv "
                    :data-node="true"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 px-0">
              <a
                @click="addToCart(item)"
                style="z-index: 20; cursor: pointer"
                class="pt-button"
                :class="{ 'pt-button-change-color': addClass }"
              >
                <span class="text"><i class="fas fa-shopping-cart"></i> Contratar</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-bg-effect polygon-new">
        <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { formatNumber, changeSeparator, checkNumber } from "@/helpers";

export default {
  props: ["item", "type", "category", "subcategory", "index"],
  data: () => ({
    SelectOptions: [1, 2, 3],
    unidad: null,
    form: {
      cantidad: 1,
    },
  }),
  created() {
    let name = this.item.nombre.split(1);
    if (name.length > 1) {
      this.unidad = name[1].substr(0, 2);
    }
  },

  computed: {
    ...mapGetters(["getData"]),
    dynamicName() {
      let name = this.item.nombre.split(1);
      if (name.length > 1) {
        return name[0] + " " + this.form.cantidad + name[1];
      } else {
        return this.item.nombre;
      }
    },
    addClass() {
      var index = this.index;
      if (index + 1 == 1) {
        return false;
      } else if (index + 1 == 2 || index + 1 == 3) {
        return true;
      } else if (index + 1 == 4) {
        return false;
      } else if (index + 1 == 5 || index + 1 == 6) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(["addServiceToDetailAdicional", "addServiceToCart"]),
    formatNumber,
    changeSeparator,
    checkNumber,
    next_step(item) {
      this.$emit("next", item);
    },
    addToCart(item) {
      this.$Progress.start();
      if (this.form.cantidad == 0) {
        this.$Progress.fail();
        this.$toast.error("Cantidad ingresada incorrecta.");
        return false;
      }
      item["producto"] = true;
      item["estado"] = 0;
      item["configuracion"] = {
        cantidad: this.form.cantidad,
      };
      item["servicios_adicionales"] = [];
      item["subcategory_"] = this.subcategory;
      item["category_"] = this.category;
      this.addServiceToCart(JSON.parse(JSON.stringify(item)));
      this.$toast.success("Servicio agregado.");
      this.$Progress.finish();
    },
  },
};
</script>

<style scoped>
@media (max-width: 576px) {
  .wrap-blog .wrapper {
    padding: 0 !important;
  }
}
</style>
