<template>
    <main id="microsoft" class="main" style="min-height: 100vh;">

        <!-- {{ this.data_.servicios }} -->
        <section class="main_banner">
             <BannerSlider :dataBannerSlider="dataBannerSlider" >
                 <div class="azureMain_portada_content">
                   <ContactSimply :ViewDataForm="{
                         stateInputs:{
                         nombre: true,
                         email: true,
                         telefono: true,
                         asunto: false,
                         motivo: false,
                         mensaje: true,
                         
                        },
                     withBLur: true,
                     asunto: 'Consulta iHosting Microsoft 365: solicito la llamada de un Ejecutivo Comercial',
                     motivo: 'Consulta comercial Microsoft 365'
                     
                   }"
                   titleForm="Solicita asistencia de un Experto en Microsoft 365"/>
                   
                 </div>
             </BannerSlider>
        </section>  

        <section class="main_cards wow animate__animated  animate__fadeInUp">
            <titleBox :dataTitleBox="dataTitleMore" :configTitleBox="{'positionTitle':'center' }"/>
            <div class="main_cards_box">
                <cardWaveInside v-for="(item,index) in dataSectionMore" :dataInfo="item" :key="index" />
            </div>
        </section>

        <section class="main_cards_slider">
            <titleBox :dataTitleBox="dataTitleCardsMicrosoft" :configTitleBox="{'positionTitle':'center' }"/>
            <cardsMicrosoft/>
        </section>

        <section class="main_list">
            <listDynamic :dataTitleBox="dataList.dataListTitle" :dataList="dataList" keyList="cuestion"/>
        </section>


    </main>
</template>

<script>
import BannerSlider from  "@/components/web/sliders/BannerSlider.vue";
import ContactSimply from "@/components/web/forms/contactSimply.vue";
import titleBox from "@/components/web/titleBox.vue"
import cardWaveInside from "@/components/web/cards/cardWaveInside.vue";
import cardsMicrosoft from "@/components/web/cards/cardsMicrosoft.vue";

import listDynamic from "@/components/web/listDynamic.vue";

export default{
    components:{
        BannerSlider,
        ContactSimply,
        titleBox,
        cardWaveInside,
        cardsMicrosoft,
        listDynamic

    },
    data: ()=>({

            // data para la seccion de inicio, el banner slider ---
            dataBannerSlider:{
                title: 'Microsoft ',
                titleBold: '365',
                subtitle: 'Verás como se multiplica tu productividad con Microsoft 365 para empresas, antes Office 365. En tu mano contarás con todo lo necesario para trabajar desde cualquier lugar de la mejor forma posible: herramientas de trabajo disponibles online y offline, un espacio ideal para el trabajo en equipo y efectivos canales de comunicación.',
                directionElement: false,
            },

            // data cards potencia
            dataTitleMore: {
                title: 'Potencia tu productividad',
                description: 'Colaboración, comunicación y almacenamiento seguro para tus proyectos.',
                color: 'var(--dark-color)',
                positionTitle: 'left'
            },
            dataSectionMore:[
                {
                    title: 'Herramientas de Microsoft',
                    text: 'Word, Excel, PowerPoint, etc. en la nube.',
                    img: 'images/services/microsoft/HERRAMIENTAS-DE-MICROSOFT-.webp'
                },
                {
                    title: 'Maximiza la colaboración',
                    text: 'Microsoft Teams para optimizar el trabajo colaborativo.',
                    img: 'images/services/microsoft/MAXIMIZA-LA-COLABORACIÓN-.webp'
                },
                {
                    title: 'Comunicación Efectiva',
                    text: 'Correo electrónico profesional: Outlook.',
                    img: 'images/services/microsoft/COMUNICACIÓN-EFECTIVA-.webp'
                },
                {
                    title: 'Almacenamiento Seguro',
                    text: 'OneDrive (1TB) para almacenamiento seguro y uso compartido de archivos.',
                    img: 'images/services/microsoft/ALMACENAMIENTO-SEGURO-.webp'
                },
                {
                    title: 'Aplicaciones Actualizadas',
                    text: 'Actualizaciones permanentes de las aplicaciones en todos tus dispositivos.',
                    img: 'images/services/microsoft/APLICACIONES-ACTUALIZADAS-.webp'
                },


            ],

            // data cards microsoft
            dataTitleCardsMicrosoft: {
                title: 'Planes Microsoft Office 365',
                description: 'En los planes Microsoft Office 365 podrás trabajar a toda velocidad y con la estabilidad que buscas, revisa nuestros planes a continuación. No dudes contactarnos si necesitas apoyo en la implementación.',
                color: 'var(--dark-color)',
                positionTitle: 'center'
            },

            // data list 
            dataList:{
                dataListTitle: {
                    title: 'Preguntas Frecuentes',
                    description: 'Microsoft 365 está formado por un conjunto de herramientas que te permiten trabajar desde cualquier lugar gracias a sus aplicaciones de office, al espacio de trabajo colaborativo y a canales de comunicación profesionales.',
                    color: 'var(--dark-color)',
                    positionTitle: 'center'
                },
                dataListServiceInfo: [
                {title: '¿Cómo funciona Microsoft 365?', 'description': 'La suscripción a Microsoft 365 para empresas pone en tu mano aplicaciones como Word, Excel, PowerPoint u Outlook, entre otras, en su versión online, móvil y/o de escritorio para que puedas trabajar desde cualquier lugar y dispositivo. Siempre contarás con la última versión de las aplicaciones al pagar una suscripción mensual y estas actualizaciones no supondrán nuevos gastos.', icon: 'fa fa-check'},
                {title: '¿En qué se diferencia Microsoft 365 de Microsoft Office?', 'description': '  Con Microsoft 365 dispondrás de las aplicaciones de Office que ya conoces siempre actualizadas y de otras herramientas, como OneDrive para el almacenamiento de documentos en la nube. En el plan Microsoft 365 Business Standard contarás con la versión más reciente de las aplicaciones de Office que podrás instalar en varios dispositivos. Con el plan Microsoft 365 Business Basic podrás utilizar la versión online de las aplicaciones y acceder a todas las herramientas siempre que estés conectado a Internet.', icon: 'fa fa-check'},
                {title: '¿Necesitas estar conectado a Internet para utilizar Microsoft 365?', 'description': ' Para instalar y activar todas las funcionalidades de Microsoft 365 es imprescindible tener acceso a Internet. También, algunos de sus servicios necesitan conexión como el correo electrónico. Con el plan Microsoft 365 Business Standard dispondrás de las versiones de escritorio de las aplicaciones y podrás trabajar sin acceso a Internet. Cuando te conectes, todo tu trabajo se sincronizará y Microsoft 365 se actualizará de forma automática.', icon: 'fa fa-check'},
                {title: '¿Microsoft 365 requiere instalación?', 'description': 'La opción Basic de Microsoft 365 no necesita ningún proceso de instalación. En la Standard, tendrás disponibles las versiones de escritorio instalables de las aplicaciones en tu Centro de Administración del portal de Microsoft 365.', icon: 'fa fa-check'},
                {title: '¿Cuándo puedo cancelar mi suscripción a Microsoft 365?', 'description': 'Puedes cancelar tu suscripción a Microsoft 365 en cualquier momento.', icon: 'fa fa-check'},
                {title: '¿Cómo trabajar en equipo sobre el mismo documento desde cualquier lugar?', 'description': 'Tanto con el plan Basic como con el Standard de Microsoft 365 para empresas, los documentos almacenados en OneDrive pueden editarse simultáneamente desde cualquier sitio con conexión a Internet. Todos aquellos con permiso de edición pueden modificar el documento y ver sus cambios en tiempo real.', icon: 'fa fa-check'},
            
            ],
            expande: true,
            // img: '/images/about-us/hosting-1200.png' 
        },
    }),

}
</script>

<style scoped>
.main, .main_banner{
    padding: 0px;
}


.main_cards_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.main_cards, .main_list, .main_cards_slider, .main_list{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0px 40px;
    min-height: 65vh;
    max-width: 100%;
}

.main_list{
    min-height: 95vh;
}

/* .main_cards_slider{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
</style>