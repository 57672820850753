var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"azureMain",staticStyle:{"min-height":"100vh"},attrs:{"id":"AzureIhosting"}},[_c('SliderTextImages',{attrs:{"dataSlider":_vm.dataSliderTextImages}},[_c('div',{staticClass:"azureMain_portada_content"},[_c('ContactSimply',{attrs:{"ViewDataForm":{
                  stateInputs:{
                  nombre: true,
                  email: true,
                  telefono: true,
                  asunto: false,
                  motivo: false,
                  mensaje: true,
                },
                withBLur: true,
              asunto: 'Consulta iHosting Microsoft Azure: solicito la llamada de un Ejecutivo Comercial',
              motivo: 'Consulta comercial Microsoft Azure'
            },"titleForm":"Solicita la llamada de un Ejecutivo Especializado"}})],1)]),_c('section',{staticClass:"sectionServices"},[_c('div',{staticClass:"sectionServices_container"},[_c('div',{staticClass:"sectionServices_container_text"},[_c('titleBox',{attrs:{"dataTitleBox":_vm.dataCardsScale.dataTitle,"configTitleBox":{'positionTitle':'center' }}})],1),_c('div',{staticClass:"sectionServices_container_cards"},_vm._l((_vm.dataCardsScale.listText),function(item,index){return _c('cardWaveInside',{key:index,staticClass:"wow animate__animated  animate__fadeInUp",attrs:{"dataInfo":item}})}),1)])]),_c('section',{staticClass:"azure_bannerAnimated"},[_c('bannerAnimated',{attrs:{"dataBannerAnimated":_vm.dataBannerAnimated_demofree}})],1),_c('div',{staticClass:"azureMain_beneficios"},[_c('div',{staticClass:"azureMain_beneficios_content "},[_c('div',{staticClass:"azureMain_beneficios_content_text "},[_c('div',{staticClass:"azureMain_beneficios_content_text_sectionTitle wow animate__animated  animate__fadeInUp "},[_c('titleBox',{attrs:{"dataTitleBox":_vm.dataSolution,"configTitleBox":{'positionTitle':_vm.dataSolution.positionTitle, 'style': 'width: 100%'}}})],1),_vm._m(0)]),_vm._m(1)])]),_c('section',{staticClass:"azureMain_steep"},[_c('div',{staticClass:"nubesComponent_top"},[_c('nubes')],1),_c('div',{staticClass:"azureMain_steep_content wow animate__animated  animate__fadeInUp"},[_c('div',{staticClass:"azureMain_steep_content_steep"},[_c('CardsFlex',{attrs:{"dataInfo":_vm.dataCardsSteep,"dataTitleBox":_vm.dataTitleSteep}})],1)]),_c('div',{staticClass:"nubesComponent"},[_c('nubes')],1)]),_c('section',{staticClass:"azureMain_imageList spad"},[_c('div',{staticClass:"azureMain_imageList_content"},[_c('ContactSimply',{attrs:{"ViewDataForm":{
                  stateInputs:{
                  nombre: true,
                  email: true,
                  telefono: true,
                  asunto: false,
                  motivo: false,
                  mensaje: true,
                  withBLur: true
              },
              asunto: 'Consulta iHosting Microsoft Azure',
              motivo: 'Consulta comercial Microsoft Azure'
            },"titleForm":"Solicita asistencia comercial en Microsoft Azure"}})],1),_c('div',{staticClass:"azureMain_imageList_list"},[_c('listWithImage',{attrs:{"dataListBeneficios":_vm.dataListBeneficios,"dataTitleBox":_vm.dataTitleBeneficios}})],1)]),_c('section',{staticClass:"azure_bannerAnimated"},[_c('bannerAnimated',{attrs:{"dataBannerAnimated":_vm.dataBannerAnimated}})],1),_c('section',{staticClass:"azureMain_list"},[_c('listDynamic',{staticClass:"wow animate__animated  animate__fadeInUp",attrs:{"dataTitleBox":_vm.dataTitleList,"dataList":_vm.dataList}})],1),_c('section',{staticClass:"azure_about"},[_c('aboutService',{attrs:{"dataSection":_vm.dataTitleMicrosoftAzure}})],1),_c('section',{staticClass:"cardsHoverAnimated   "},[_c('cardHoverAnimated',{attrs:{"dataCards":_vm.dataAdditional,"dataTitleBox":_vm.dataTitleAdditional}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"azureMain_beneficios_content_text_row wow animate__animated  animate__fadeInUp"},[_c('div',{staticClass:" wow azureMain_beneficios_content_text_row_bx"},[_c('div',{staticClass:"bg-light rounded text-center p-4"},[_c('i',{staticClass:"fa fa-line-chart fa-2x text-primary mb-2"}),_c('p',{staticClass:"mb-0"},[_vm._v("Objetivos del Cliente")])])]),_c('div',{staticClass:" wow azureMain_beneficios_content_text_row_bx"},[_c('div',{staticClass:"bg-light rounded text-center p-4"},[_c('i',{staticClass:"fa fa-shield fa-2x text-primary mb-2"}),_c('p',{staticClass:"mb-0"},[_vm._v("Disponibilidad y seguridad")])])]),_c('div',{staticClass:" wow azureMain_beneficios_content_text_row_bx"},[_c('div',{staticClass:"bg-light rounded text-center p-4"},[_c('i',{staticClass:"fa fa-refresh fa-2x text-primary mb-2"}),_c('p',{staticClass:"mb-0"},[_vm._v("Dimensión de Carga")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-5 azureMain_beneficios_content_image"},[_c('img',{staticClass:"img-fluid wow zoomIn azureMain_beneficios_content_image_img",attrs:{"data-wow-delay":"0.5s","src":"/images/services/azure/images/ARQUITECTURA-DE-SOLUCION.webp"}})])}]

export { render, staticRenderFns }