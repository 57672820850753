<template>
    <div class="boxCard" 
        :class="{ boxCardactive: index % 2 == 1 }" :style="styleBoxCardComputed">
        <div v-if="imageCard.state" class="boxCard_contentImage">
            <img class="boxCard_contentImage_img" :src="imageCard.src" alt="image8" style="width: 65px" />
        </div>
        <div v-if="sslGlobal ||  promoGlobal.status || service.promo && service.promo.status" class="boxCard_detail" style="position: relative;">
            <div v-if="sslGlobal" class="boxCard_ssl" :class="{'boxCard_ssl_minCard': this.styleBoxCard.width == '290px' }">
                <span class="boxCard_promo_text boxCard_ssl_text">
                   <i class="ion-ios-locked"></i> SSL
                </span>
            </div>

            <div v-if="promoGlobal.status || service.promo && service.promo.status" class="boxCard_promo" :class="{'boxCard_promo_minCard': this.styleBoxCard.width == '290px' }">
                <span class="boxCard_promo_text" 
                    :class="{ boxCard_promo_text_active: index % 2 == 0 }">
                    {{ promoGlobal.status ? promoGlobal.text : service.promo.text }}
                </span>
            </div>
        </div>
        <header class="boxCard_header" :class="{ boxCard_header_active: index % 2 == 1 }">
            <div class="boxCard_header_up">
                <span class="boxCard_header_up_title" 
                    :class="{ boxCard_header_up_title_active: index % 2 == 1 }">
                    {{ service.atributos[0].pivot.valor  }}
                </span>
                <em class="boxCard_header_up_subtitle"> 
                    <!-- {{ service.descripcion  }}  -->
                </em>
            </div>
            <div class="boxCard_header_down">
                <div class="boxCard_header_down_title" :class="{ boxCard_header_up_title_active: index % 2 == 1 }">

                    <span v-if="cuponService_selected">
                     ${{  formatNumber( calcularPrecioConDescuento(service.precio_clp, cuponService_selected ) )  }}
                        <!-- servicio.precio: {{ service.precio  }} - form.cantidad: {{ form.cantidad }} -->
                    </span>
                    
                    <span v-else>
                        ${{ formatNumber(service.precio_clp) }}
                        <!-- servicio.precio: {{ service.precio  }} - form.cantidad: {{ form.cantidad }} -->
                    </span>

                    <div v-if="addDCTO && cuponService_selected" style="position: relative;">
                        <p style="font-size: 14px; width: 100%; min-width: 250px; text-decoration: line-through; margin: 4px 0px; line-height: normal;">
                           <span v-if="cuponService_selected.periodicidad_state == 1 && cuponService_selected.cupon_periodicidad_selected ">
                             ${{ formatNumber(service.precio_clp * get_valor_por_nombre_periodicidad(cuponService_selected.cupon_periodicidad_selected.nombre)) }}
                           </span>
                           <span v-else>
                            ${{ formatNumber(service.precio_clp) }}
                           </span>
                        </p>
                        <!-- Porcentaje de descuento -->
                        <b v-if="cuponService_selected.id_tipocupon == 2" class="dcto" :class="{ boxCardactive_dcto: index % 2 == 1 }">

                            {{  cuponService_selected.periodicidad_state == 1 
                                ? cuponService_selected.cupon_periodicidad_selected.valor 
                                : cuponService_selected.valor
                            }}%
                        </b>
                    </div>
                </div>
                <p class="boxCard_header_down_subtitle">  

                    <span v-if="service.moneda.nombre_corto == 'UF' && service.precio_clp != service.precio"
                        class="item-row-price"
                        :data-node="true"
                        >{{ changeSeparator((service.precio * form.cantidad).toFixed(2)) }}
                    </span>

                    <span v-else-if="service.moneda.nombre_corto == 'USD' && service.precio_clp != service.precio"
                        class="item-row-price"
                        :data-node="true"
                        >{{ changeSeparator((service.precio * form.cantidad).toFixed(2)) }}
                    </span>

                    <span v-else-if="service.moneda.nombre_corto == 'CLP'" class="row-price" :data-node="true">
                        <span v-if="service.precio != service.precio_clp">
                            $ {{ formatNumber(service.precio * form.cantidad) }}
                        </span>
                    </span>
                    {{ service.moneda.nombre_corto }} / {{ cuponService_selected && cuponService_selected.periodicidad_state == 1 && cuponService_selected.cupon_periodicidad_selected ? `Primer pago`  : service.periodicidad.nombre }}
                </p>
                
            </div>

            <div v-if="service.cupon_service" class="boxCard_header_textExtraCupon">
                <div>
                    <span v-if="cuponService_selected && service.cupon_service.periodicidad_state == 1">
                     Ahorra ${{ formatNumber( getCalculoAhorro(service.precio_clp * get_valor_por_nombre_periodicidad(cuponService_selected.cupon_periodicidad_selected.nombre) , cuponService_selected.cupon_periodicidad_selected.valor, service.cupon_service.id_tipocupon ) ) }} CLP
                    </span>
                    <span v-else>
                     Ahorra ${{formatNumber( getCalculoAhorro(service.precio, service.cupon_service.valor, service.cupon_service.id_tipocupon ) ) }} CLP
                    </span>
                </div>
            </div>
            <div class="boxCard_header_buttons">
                <div v-if="service.cupon_service" class="btn_promo">

                    <btnPromo 
                        @get_cupon_selected="get_cupon_selected_function"
                        :btnConfig="{
                            cupon_service: service.cupon_service,
                            colorPrimary: index % 2 == 1,
                            text:  `${service.cupon_service.id_tipocupon == 2 ? `Ahorra ${service.cupon_service.valor}%` : ` ahorra $${service.cupon_service.valor}` }`,
                            description: ` ${service.cupon_service.new_client == 1 ? 'Exclusivo para clientes nuevos' :  'Promoción primer pago' } `,
                            url: `/cart?service=${service.id}&category=${infoCategory.category}&subcategory=${infoCategory.subCategory}&cupon=${service.cupon_service.codigo_cupon}`
                        }"
                    />
                </div>
                

            </div>
        </header>
        <div class="boxCard_body" :class="{'boxCard_body_viewMore': service.viewMore  || styleBoxCard.viewMore }  ">
            <ul class="boxCard_body_list">
                <li v-for="(atribute, idx) in service.atributos.slice(0,11)" :key="idx" class="boxCard_body_list_element">
                    <span class="boxCard_body_list_element_title">{{ atribute.nombre.toUpperCase() }} </span>
                    <span class="boxCard_body_list_element_value" :class="{'boxCard_body_list_element_value_black': idx == 0}">
                        <span v-if="atribute.pivot.valor.toUpperCase() == '∞' || atribute.pivot.valor.toUpperCase()== 'ILIMITADOS' || atribute.pivot.valor.toUpperCase() == 'ILIMITADAS' || atribute.pivot.valor.toUpperCase() == 'ILIMITADA'" class="boxCard_body_list_element_value_infinito">∞</span>
                        <span v-else>{{ atribute.pivot.valor.toUpperCase() }}</span>
                    </span>
                </li>
            </ul>
        </div>
        <div  class="boxCard_footer">
            <a v-if="!styleBoxCard.viewMore"  @click="cardViewMore(service)" class="boxCard_footer_button" :class="{ boxCard_footer_button_active: index % 2 == 1 }">
                <span v-if="service.viewMore" class="boxCard_footer_button_text">Ver menos</span>
                <span v-if="!service.viewMore" class="boxCard_footer_button_text">Ver más</span>
                <i class="ion-chevron-down boxCard_footer_button_text_icon" :class="service.viewMore ? 'boxCard_footer_button_text_iconDeg': ''"></i>

            </a>

            <a v-if="!cuponService_selected" class="boxCard_header_buttons_button"  
                :class="{ boxCard_header_buttons_button_active: index % 2 == 1 }" 
                :href="`/cart?service=${service.id}&category=${infoCategory.category}&subcategory=${infoCategory.subCategory}`">
                <span class="text">Activar</span>
            </a>
            <a v-else class="boxCard_header_buttons_button"  
                :class="{ boxCard_header_buttons_button_active: index % 2 == 1 }" 
                :href="`/cart?service=${service.id}&category=${infoCategory.category}&subcategory=${infoCategory.subCategory}&cupon=${service.cupon_service.codigo_cupon}${cuponService_selected.cupon_periodicidad_selected && cuponService_selected.cupon_periodicidad_selected.id_periodicidad ? '&cupon_periodicidad=' + cuponService_selected.cupon_periodicidad_selected.id_periodicidad : '' }`">
                <span class="text">Activar</span>
            </a>

            <div v-if="cuponService_selected" class="boxCard_footer_aviso" >
                <span v-if="cuponService_selected.cupon_periodicidad_selected">
                    El {{ new Date().getFullYear() + get_valor_por_nombre_periodicidad(cuponService_selected.cupon_periodicidad_selected.nombre)  }} renuevas por ${{ formatNumber(service.precio_clp) }} / {{ service.periodicidad.nombre  }}
                </span>
                <span v-else>
                    El {{ new Date().getFullYear() + get_valor_por_nombre_periodicidad(service.periodicidad.nombre)  }} renuevas por ${{ formatNumber(service.precio_clp) }} / {{ service.periodicidad.nombre  }}
                </span>
            </div>
        </div>

        <div v-if="index % 2 == 1" class="pt-bg-effect polygon-new" style="opacity: 0.3; max-width: 100%;">
            <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
        </div>
    </div>
</template>
<script>
import btnPromo from "../buttons/btnPromo.vue";
import {
formatNumber,
changeSeparator,
getCalculo_price_with_cupon,
get_valor_por_nombre_periodicidad,
getCalculoAhorro
} from "@/helpers";
export default{
components: {
    btnPromo
},
props:{
    styleBoxCard:{
        default:{
            background: 'white',
            width: '340px',
            min_width: '320px',
            max_width: '342px',
            viewMore: true,
        },
        required: false
    },
    serviceProp:{
        default: {}
    },
    index:{
        default: 0
    },
    infoCategory:{
      default: ()=>{
        return {
          category: '',
          subCategory: ''
        }
      }
    },
    imageCard:{
        type: Object,
        default: ()=> {
            return {
                src: '',
                state: false
            }; 
        }
    },
    promoGlobal: {
        type: Object,
        default: ()=>{
            return {
                status: false,
                text: ''
            }
        }
    },
    sslGlobal:{
      default: false,
      type: Boolean
    },
    addDCTO: {
        default: false
    }

},
data:()=>({
    form: {
        cantidad: 1,
    },
    cuponService_selected: null  ,
    cupon_selected: null,
}),
computed:{
    styleBoxCardComputed(){
        return {
            'width': this.styleBoxCard.width,
            'max-width': this.styleBoxCard.max_width,
            'min-width':  this.styleBoxCard.min_width, 
            'background': this.styleBoxCard.background,

        }
    },
    service(){
        // Realiza una copia profunda del array this.services
        const service = JSON.parse(JSON.stringify(this.serviceProp));
        // Establece la propiedad viewMore en falso para cada objeto en la copia
        service.viewMore = false;
        return service;
    },
},
methods: {
    formatNumber,
    changeSeparator,
    getCalculo_price_with_cupon,
    get_valor_por_nombre_periodicidad,
    getCalculoAhorro,
    cardViewMore(service){
        // mutar el objeto de vuejs2 de manera reactiva
        this.$set(service, 'viewMore', !service.viewMore);
        // debido a que vuejs2 no se actualiza correctamente lo forzamos
        this.$forceUpdate(); 
    },
     aumentarEn20PorCiento(precio) {
        return precio * 1.20;
    },
    get_cupon_selected_function(cupon){
        // console.log("get_cupon_selected: ", cupon);
        this.cuponService_selected = cupon;
    },
     calcularPrecioConDescuento(montoServicio, cuponService_selected ) {
        // console.log("calcularPrecioConDescuento: cuponService_selected: ", cuponService_selected)
        let getCalculo_price_with_cupon =  this.getCalculo_price_with_cupon(cuponService_selected, montoServicio);
        // console.log("fin-------------: calcularPrecioConDescuento ", getCalculo_price_with_cupon);
        return getCalculo_price_with_cupon;

}
},
}
</script>
<style scoped>
.boxCard{
    position: relative;
    background:#fdffff;
    position: relative;
    padding: 5px 30px 15px 30px;
    cursor: pointer;
    border: 2px solid #f5f9ff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px 0px rgb(0 36 80 / 10%);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #194960;
    border-radius: 1.25rem;
    overflow: hidden;
}
.boxCardactive{
    background: var(--primary-color);
    border: 2px solid var(--primary-color);
    color: white;
}

/* etiqueta de promocion */

.boxCard_detail{
    position: relative;
    min-height: 40px;
    margin: 5px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.boxCard_promo{

}

.boxCard_promo_minCard{

}

.boxCard_ssl{

}

.boxCard_ssl_minCard{

}
.boxCard_promo_text{
    background: #fff !important;
    color: var(--primary-color) !important;
    display: inline-block;
    padding: 8px 10px;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.boxCard_promo_text_active{
    background:  var(--primary-color) !important;
    color:var(--white-color) !important;

}
.boxCard_ssl_text{
    background: #2EDBBE !important;
    color: var(--white-color) !important;
}
.boxCard_promo_text i {
    font-size: 13px;
    margin-right: 3px;
}

.boxCard_contentImage{
    left: 23px;
    top: 23px;
    opacity: 0.1;
    position: absolute;
}
.boxCard_contentImage_img{
    width: 65px;
    vertical-align: middle;
    border-style: none;
    overflow-clip-margin: content-box;
    overflow: clip;
}

/* header box ---------------------*/
.boxCard_header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    text-align: center;
    border-bottom: 2px solid #eff4fa5c;
    margin-bottom: 30px;
    color: #194960;
}
.boxCard_header_active{
    border-bottom: 2px solid #eff4fa6f;
    color: #ffff;
    
}

    .boxCard_header_up, .boxCard_header_down{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        .boxCard_header_up_title{
            color: var(--primary-color);
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 18px;
            line-height: 26px;
            font-weight: 700;
            font-size: 28px;
            display: none;
        }
        .boxCard_header_up_title_active{
            color: white !important;
        }
        .boxCard_header_up_subtitle{
            font-size: 17px;
        }
        .boxCard_header_down_title{
            font-family:'Jost', sans-serif;
            font-weight: 600;
            font-size: 45px;
            font-style: normal;
            line-height: 60px;
            color: #194960;
            margin: 15px 0 0;
        }
        .boxCard_header_down_subtitle{
            font-size: 14px;
            margin-bottom: 0px;
        }
    .dcto{
        font-size: 12px;
        background: transparent;
        border-radius: 8px;
        padding: 2px;
        position: absolute;
        right: -24px;
        top: -55px;
        height: 35px;
        width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-color);
        color: white;
    }

    .boxCardactive_dcto{
        background: white;
        color: var(--primary-color);
    }
    .boxCard_header_textExtraCupon{
        margin: 5px 0px;
        text-align: center;
        padding: 0px;
        font-size: 12px;
        font-weight: 500;
    }
        .boxCard_header_buttons{
            margin-bottom: 15px;

        }
        .boxCard_header_buttons_button{
            position: relative;
            width: 100%;
            background: var(--primary-color);
            color: var(--white-color)!important;
            font-family: var(--title-fonts);
            font-size: 16px;
            padding: 5px 35px;
            line-height: 2;
            vertical-align: middle;
            border: none;
            display: inline-block;
            overflow: hidden;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            margin-top: 4px;
            z-index: 1;
        }
        .boxCard_header_buttons_button_active{
            background:var(--white-color)!important; 
            color: var(--primary-color) !important;
        }
/* body box */
.boxCard_body{
    padding: 1px;
    overflow: hidden;
    height: 170px;
}
.boxCard_body_viewMore{
    height: auto;
}
.boxCard_body_list{
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
}
.boxCard_body_list_element{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    font-size: 13px;
    list-style: none;
    min-height: 34px;
}
.boxCard_body_list_element_title{
    margin-right: 25px
}
.boxCard_body_list_element_value{
    float: right
}

.boxCard_body_list_element_value_black{
    font-weight: 700;
    font-size: 15px;
}
.boxCard_body_list_element_value_infinito{
    font-size: 18px;
    font-weight: 700;
}

/* footer box */
.boxCard_footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.boxCard_footer_button{
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    z-index: 10;
}
.boxCard_footer_button_active{
    color: #fff;
}
.boxCard_footer_button_text{
    /* display: flex;
    align-items: center; */

}
.boxCard_footer_button_text_icon{
    font-size: 10px;
    margin: 4px 0 0 5px;
    transition: .3s ease-in;

}
.boxCard_footer_button_text_iconDeg{
    transform: rotate(180deg);
    margin: 0px 0 0 5px;
}


.boxCard_footer_aviso{
    font-size: 12px;
    font-weight: 500;
    padding: 10px 0px 0px;
}

/* promo */
.btn_promo{
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width:1500px){
.boxCard{
    padding: 5px 35px 30px 35px;
}
}
@media (max-width:1580px){
.boxCard_header_up_title {
    font-size: 12px;
}
.boxCard_header_down_title{
    font-size: 38px;
}

.boxCard_header_up_subtitle{
    font-size: 15px;
}

.boxCard_body_list_element{
font-size: 11px;
}
.dcto{
    right: -5px;
}
}

@media (max-width:900px){
.boxCard{
    width: auto;
    min-width: 250px;
}
}
</style>