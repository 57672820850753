<template>
<div class="minBannerAnimated">
      <div class="minBannerAnimated_content wow animate__animated animate__fadeInUp ">
        <h2 class="minBannerAnimated_content_title">
          {{dataBannerAnimated.title}}
        </h2>
        <p class="minBannerAnimated_content_description">
          {{dataBannerAnimated.paragraph}}
        </p>
      </div>
      <div class="footer_waves">
        <div
          class="footer_wave wave1"
          style="background: url('images/backgrounds/wave.png');"
        ></div>
        <div
          class="footer_wave wave2"
          style="background: url('images/backgrounds/wave.png');"
        ></div>
        <div
          class="footer_wave wave3"
          style="background: url('images/backgrounds/wave.png');"
        ></div>
        <div
          class="footer_wave wave4"
          style="background: url('images/backgrounds/wave.png');"
        ></div>
      </div>
    </div>
</template>

<script>

export default{
  props:{
    dataBannerAnimated:{
      default: ()=>({
        title: 'title',
        paragraph: 'paragraph'
      })
    }
  }
}

</script>

<style scoped>

.minBannerAnimated {
  position: relative;
  background-image: linear-gradient(
    90deg,
    var(--blue-color) 0%,
    var(--primary-color) 100%
  );
  background-size: cover;
  opacity: 0.9;
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0px;
}

.minBannerAnimated_content {
  height: 100%;
  width: auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
}
.minBannerAnimated_content_title,
.minBannerAnimated_content_description {
  color: white;
}

.minBannerAnimated_content_title {
  margin-bottom: 25px;
}
.minBannerAnimated_content_description{
  font-size: 18px;
}


.footer_waves {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  z-index: 0;
  opacity: 0.4;
  padding: 0;
  margin: 0;
}

.footer_wave{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-size: 1000px 100px;
}
.wave1{
  animation: wave 15s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s !important;
  bottom: 0px;
}
.wave2 {
  animation: wave2 20s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s !important;
  bottom: 10px;
}
.wave3 {
  animation: wave 35s linear infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s !important;
  bottom: 15px;
}
.wave4 {
  animation: wave2 10s linear infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -5s !important;
  bottom: 20px;
}
@keyframes wave{
  0%{
    background-position-x: 0px; 
  }
  40%{
    background-position-x: 1000px; 
  }
  70%{
    background-position-x: 500px; 
  }
  90%{
    background-position-x: 300px; 
  }
  100%{
    background-position-x: 0px; 
  }
}
@keyframes wave2{
    0%{
    background-position-x: 0px; 
  }
  40%{
    background-position-x: -1000px; 
  }
  70%{
    background-position-x: -500px; 
  }
  90%{
    background-position-x: -300px; 
  }
  100%{
    background-position-x: 0px; 
  }
}
@media (max-width: 1300px){
  .minBannerAnimated_content_title{
    font-size: 33px;
  }
}
@media (max-width: 700px){
  .minBannerAnimated_content_title{
    font-size: 30px;
  }
  .minBannerAnimated_content_description{
    font-size: 16px;
    font-weight: 500;
  }
}


@media (max-width: 600px){
    .minBannerAnimated{
        padding: 60px 30px;
    }
    .minBannerAnimated_content_title {
        font-size: 25px;
    }
    .minBannerAnimated_content_description{
        font-size: 15px;
    }
}
</style>