<template>
    <div class="appHeaderNav">
      <ul class="headerNav" :class="{'headerNav_active': headerActive || $route.name != 'Home' && $route.name != 'Nosotros', 'headerNav_active_modeResponsive': toggle}" >
            <li v-for="(link, index) in links" class="headerNav_bx"  :key="index">
               <!-- <label :for="`btn-${index + 1}`" class="show">{{ link.name }} +</label> -->
               <div  @click="clickLink">
                 <router-link v-if="link.hash && link.link"  class="headerNav_bx_link" :class="{'active-link': link.active || $route.hash == link.hash }" :to="link.link">
                  <span  @click="changeStateLink(index)">
                    {{ link.name }}
                  </span>
                </router-link>
                
                <span v-else-if="!link.hash && !link.link" class="headerNav_bx_link" :class="{'active-link': link.active || $route.hash == link.hash }"> {{ link.name }}</span>
                
                <a v-else class="headerNav_bx_link"  :href="link.link" target="_blank">{{ link.name }}</a>
               </div>
              <i v-if="link.subMenu" class="fa fa-arrow-down headerNav_bx_link_arrow" :class="{'headerNav_bx_link_arrow_white': $route.name == 'Home' || $route.name == 'Nosotros'}" :style="linkSelect ==  link.name ? 'transform: rotate(0deg);' : ''" aria-hidden="true" @click="activeSubMenu(link.name)"></i>
               <!-- <input type="checkbox" :id="`btn-${index + 1}`"> -->
               <ul v-if="link.subMenu" @click="clickLink" class="headerNav_bx_subMenu" :class="{'headerNav_bx_subMenu_bx_active': linkSelect ==  link.name }">
                  <li v-for="(subMenu, indexSub) in link.subMenu" :key="indexSub" class="headerNav_bx_subMenu_bx" >
                    <router-link class="headerNav_bx_subMenu_bx_link" :to="subMenu.link" >
                      <span @click="changeStateLink(index)">
                        {{subMenu.name}}
                        
                      </span>
                    </router-link>
                </li>
               </ul>
            </li>
       </ul>

       <div :class="{'headerToggles_headerActive': headerActive || $route.name != 'Home' && $route.name != 'Nosotros'} ">
              <div class="hamburger main_container_header_subMenu_list_btn_icon" @click="toggle = !toggle">
                  <input class="checkbox" type="checkbox" id="btn" :checked="toggle" />
                  <svg fill="none" viewBox="0 0 50 50" height="30" width="30">
                      <path
                      class="lineTop line"
                      stroke-linecap="round"
                      stroke-width="3"
                      stroke="white"
                      d="M6 11L44 11"
                      ></path>
                      <path
                      stroke-linecap="round"
                      stroke-width="3"
                      stroke="white"
                      d="M6 24H43"
                      class="lineMid line"
                      ></path>
                      <path
                      stroke-linecap="round"
                      stroke-width="3"
                      stroke="white"
                      d="M6 37H43"
                      class="lineBottom line"
                      ></path>
                  </svg>
              </div>
            </div>
          
    </div>




</template>

<script>
export default{
    props:{
        headerActive: {
            default: false
        },
        links: {
            default: []
        }
    }, 
    data: ()=>({
      toggle: false,
      linkSelect: ''
    }),

    methods: {
      clickLink(){
        this.toggle = false;
        this.activeSubMenu();
        this.$forceUpdate()
      },

      activeSubMenu(linkName = null){
        if( this.linkSelect == linkName || linkName == null){
          this.linkSelect = '';
        }else{
          this.linkSelect = linkName;
        }
      },
        changeStateLink(index){
          this.$emit('changeStateLink', index);
          this.scrollToTop();
        },
        scrollToTop() {
          window.scrollTo(0, 0); // Desplazar la ventana hasta la parte superior
        }
    },
}

</script>

<style scoped>



.headerNav_bx{
    float: left;
    max-height: 50px;
    display: inline-block;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.headerNav_bx_link, .headerNav_bx_subMenu_bx_link{
  line-height: 70px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  padding: 2px 5px 2px 15px;
  color: white;
}

.headerNav_bx_link_arrow{
  font-size: 9px;
  margin: 0 1px;
  transition: .3s;
}

.headerNav_bx_link_arrow_white{
  color: white;
}

.headerNav_bx_subMenu{
    position: absolute;
    top: 80px;
    opacity: 0;
    visibility: hidden;
    transition: top .3s;
    min-width: 200px;
    width: 250px;
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.headerNav_bx_subMenu_bx_active{
  position: relative;
    display: block;
    opacity: 1;
    visibility: visible !important;
    margin: 0px;
    padding: 0;
    top: 0px !important;
    margin-left: 15px;
    width: 100%;
}


.headerNav_bx_subMenu, .headerNav_bx_subMenu_bx_subMenu{
    background-color: rgba(251,251,253,0.8) !important;
    backdrop-filter: saturate(180%) blur(20px) !important;
    border-radius: 8px;
}

.headerNav_bx_subMenu_bx_link{
    font-size: 14px !important;
    line-height: 10px;
    color: var(--dark-color);
}

.headerNav_bx_subMenu_bx_link:hover{
  color: var(--primary-color);
}

.headerNav_bx_subMenu_bx{
    width: 100%;
    padding: 8px 1px;
}

.headerNav_bx:hover > .headerNav_bx_subMenu{
  top: 60px;
  opacity: 1;
  visibility: visible;
}
.headerNav_bx_subMenu_bx{
  position: relative;
  margin: 0px;
  float: none;
  display: list-item;
}

.show,.main_container_header_subMenu_list_btn_icon,input{
  display: none;
}
.fa-plus{
  font-size: 15px;
  margin-left: 40px;
}

/* active start */
.headerNav_active .headerNav_bx_link{
    color: var(--dark-color) !important;
}

.content{
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}
header{
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0;
}
p{
  font-size: 30px;
  font-weight: 500;
}

.active-link{
   color: var(--white) !important;
}

.headerNav_active .active-link, .headerNav_active .headerNav_bx_link_arrow_white{
 color: var(--dark-color) !important;

}



/* toggle start */
.main_container_header_subMenu_list{
  position: absolute;
  top: 4rem;
  right: 1.2rem;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  margin-top: 0.25rem;
  padding: 1.4rem;
  border-radius: 8px;
  background: #000;
  border: 1px solid #80808057;
  display: none;

}


.main_container_header_subMenu_list_btn{
  margin: 10px 0px;
  padding: 0.25rem 0;
  cursor: pointer;

}

.main_container_header_subMenu_list_btn:hover{
  color: #ffffff;
}


.main_container_header_subMenu_list_btn_icon{
  margin: 0 5px;
}



/* hamburguer START */
.hamburger {
  /* display: none; */
  display: none;

  height: 40px;
  width: 40px;
  transform: 0.2s;
  position: relative;
}
.hamburger .checkbox {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: block;
}
.line {
  transition: 0.5s;
  stroke-width: 6px;
  stroke: white;
}
.lineTop {
  stroke-dasharray: 40 40;
  stroke-dashoffset: 25;
}
.lineBottom {
  stroke-dasharray: 40 40;
  stroke-dashoffset: 60;
}
.lineMid {
  stroke-dasharray: 40 40;
}
/* .hamburger .checkbox:checked + svg .line {
} */
.hamburger .checkbox:checked + svg .lineTop {
  stroke-dashoffset: 0;
  transform-origin: left;
  transform: rotateZ(45deg) translate(-7px, -5px);
}
.hamburger .checkbox:checked + svg .lineMid {
  stroke-dashoffset: 40;
}
.hamburger .checkbox:checked + svg .lineBottom {
  stroke-dashoffset: 0;
  transform-origin: left;
  transform: rotateZ(-45deg) translate(-5px, 5px);
}


/* HAMBURGUER END */


/* active */

.headerToggles_headerActive .line{
  stroke: var(--primary-color)  !important;
}

@media(max-width:1300px){
  .line{
    stroke: var(--primary-color) !important;
  }
}

.headerNav_active .headerNav_bx_subMenu{
  background-color: rgba(251, 251, 253, 0.963) !important;
    backdrop-filter: saturate(180%) blur(20px) !important;
    border-radius: 8px;
}

/* active end */

@media (max-width:1260px){


  ul{
    margin-right: 0px;
    float: left;
  }
  /*  .logo{
    padding-left: 30px;
    width: 100%;
  } */
  .show + a, ul{
    display: none;
    position: absolute;
    right: 10px;
    background: white;
    color: black;
    min-width: 275px;
    top: 50px;
    display: flex;
    flex-direction: column;
    float: none;
    margin: 0px;
    padding: 10px;
  }
   ul li, ul ul li{
    display: none;
    width: 100%;
  }
   ul li a:hover{
    box-shadow: none;
  }
  .show{
    display: block;
    color: white;
    font-size: 18px;
    padding: 0 20px;
    line-height: 70px;
    cursor: pointer;
  }
  .show:hover{
    color: cyan;
  }
  .main_container_header_subMenu_list_btn_icon{
    display: block;
    color: white;
    position: absolute;
    top: 0;
    right: 40px;
    line-height: 70px;
    cursor: pointer;
    font-size: 25px;
  }
   ul ul{
    top: 70px;
    border-top: 0px;
    float: none;
    position: static;
    display: none;
    opacity: 1;
    visibility: visible;
  }
   ul ul a{
    padding-left: 40px;
  }
   ul ul ul a{
    padding-left: 80px;
  }
   ul ul ul li{
    position: static;
  }
  [id^=btn]:checked + ul{
    display: block;
  }
   ul ul li{
    border-bottom: 0px;
  }
  span.cancel:before{
    content: '\f00d';
  }




  .appHeaderNav{
    max-width: 100%;
    height: 100%;

  }

  .headerNav{
    top: 80px;
    opacity: 0;
  }

  .headerNav_bx{
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-height: 400px;
    
}

.headerNav_active_modeResponsive{
   opacity: 1;
   top: 50px;
}
.headerNav_active_modeResponsive .headerNav_bx{
  display: flex;
}
.headerNav_bx_link{
  line-height: 48px;
  color: var(--dark-color) !important;

}



.headerNav_bx_link_arrow{
  width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 40%;
    top: 10px;
    transform: rotate(-90deg);
    transition: .3s;
}

.headerNav_bx_link_arrow_white{
  color: var(--primary-color);
}
  .main_container_header_subMenu_list_btn_icon{
    line-height: 0;
  }
.hamburger{
  display: block;
    top: 0;
    right: 0;
}

.headerNav_bx_subMenu {
  width: auto;

}


.headerNav_bx_subMenu_bx_link{
  font-size: 13px;
}
}
</style>