<template>
    <div class="logos">
      <div class="logos-slide">
          <img src="/images/partners/partner4_1.webp" />
          <img src="/images/partners/partner11_1.webp" />
          <img src="/images/partners/partner12_1.webp" />
          <img src="/images/partners/partner19_1.webp" />
        <img src="/images/partners/partner10_1.webp" />
        <img src="/images/partners/partner14_1.webp" />
        <img src="/images/partners/partner7.png" />
        <img src="/images/partners/partner23_1.png" />
      </div>

      <div class="logos-slide">
          <img src="/images/partners/partner4_1.webp" />
          <img src="/images/partners/partner11_1.webp" />
          <img src="/images/partners/partner12_1.webp" />
          <img src="/images/partners/partner19_1.webp" />
        <img src="/images/partners/partner10_1.webp" />
        <img src="/images/partners/partner14_1.webp" />
        <img src="/images/partners/partner7.png" />
        <img src="/images/partners/partner23_1.png" />
      </div>
    </div>
</template>

<script>

export default{
    props:{
        images:{
            require: false,
            default: ()=>([])
        }
    },
    mounted(){
        var copy = document.querySelector(".logos-slide").cloneNode(true);
        document.querySelector(".logos").appendChild(copy);
    }

}

</script>


<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 20px 0px 0px 20px;
  background: rgba(223, 223, 223, 0.096);
  white-space: nowrap;
  position: relative;
  margin: 0 auto;
  max-width: 1400px;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  padding: 20px 10px;
  height: 110px;
  width: 165px;
  margin: 10px 50px;
}


@media(max-width: 1500px){
  .logos-slide img {
    padding: 20px 10px;
    height: 90px;
    width: 145px;
    margin: 10px 50px;
}
}

@media(max-width: 600px){
  .logos:before, .logos:after {
    width: 50px;
    height: 100%;
  }

  .logos {
    padding-top: 30px ;
  }


  .logos-slide img {
    height: 90px;
    width: 120px;
    margin: 10px 20px;
    padding: 20px 10px;
}

}

</style>