<template>

    <!-- slider -->
    <div class="hero">
    <div v-if="dataSlider.withBackgroundsFixed" class="backgroundOverlay" style="background-image: url(images/backgrounds/backgroundDecoratino.jpg)"></div>

      <!-- list Items -->
      <div class="hero_slider">

          <div v-for="(service,indexSlider) in dataSlider.services" :key="indexSlider" class="item" :class="{ 'active': indexSlider == itemActive }">
              <img v-if="dataSlider.withBackgrounds" :src="service.mainImage" alt="background_page_azure"/>

              <div class="content">
                <img class="content_header_image" :src="service.header_img" alt="image_Microsft_Azure">
                  <!-- <p>{{ service.header_title }} </p> -->
              <h2>{{ service.title }} <span v-if="service.title_bold" class="title_bold">{{ service.title_bold }}</span></h2>
                  <p> {{ service.description }}</p>
              </div>
          </div>
      </div>

      <div class="hero_right">
        <slot>
          <!--  componente a agregar -->
        </slot>
      </div>

      <div v-if="dataSlider.withPointsAnimation" class="ripple-background">
              <div class="circle xxlarge shade1"></div>
              <div class="circle xlarge shade2"></div>
              <div class="circle large shade3"></div>
              <div class="circle mediun shade4"></div>
              <div class="circle small shade5"></div>
      </div>
      
      <!-- button arrows -->
          <div v-if="dataSlider.withArrows" class="arrows">
              <span @click="prev" class="arrows_btn"> 
                <i class="ri-arrow-left-s-line"></i> 

              </span>
  
              <span @click="next" class="arrows_btn"> 
                <i class="ri-arrow-right-s-line"></i>

              </span>
          </div>
      <!-- thumbnail -->
      <div v-if="dataSlider.withThumbnail" class="thumbnail">
          <div v-for="(thumbnail,indexThumbnail) in dataSlider.services" :key="indexThumbnail" @click="itemActive = indexThumbnail" class="item" :class="{'active': indexThumbnail == itemActive}">
              <img :src="thumbnail.mainImage" alt="imagen de propieda creati hogar"  provider="cloudinary" format="webp"/>

              <div class="content">
                  {{ thumbnail.name }}
              </div>
          </div>
      </div>

      <div class="nubesComponent">
        <nubes/>
    </div>
  </div>
</template>

<script>
import nubes from "@/components/web/nubes.vue";

export default{
    components: {
        nubes
    },
    props:{
        dataSlider: {
            required: true,
            default: ()=>({})
        }
    },
    data: ()=>({
        itemActive: 0,

    }),
    mounted() {
        setInterval(() => {
            this.next()
        }, 18000);
    },
    methods: {
     next(){
        this.itemActive == this.dataSlider.services.length - 1 ? this.itemActive = 0 : this.itemActive += 1;

     }, 
     prev(){
        this.itemActive <= 0 ? this.itemActive = this.dataSlider.services.length - 1  : this.itemActive -= 1;

     }
    },
}

// onMounted(() => {
//     useHead({
//       script:[
//         {
//           src: "/jsDOM/BannerHero.js",
//           async: true
//         }
//       ]
//     })
// })

</script>

<style scoped>

svg{
  width: 25px;
}

/* css slider */
.hero{
  min-height: 100vh;
  position: relative;
  background: var(--grey-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  overflow: hidden;

}

.hero_slider{
    position: relative;
    display: block;
    height: 100%;
    width: 50%;
    min-height: 485px;
    display: flex;
    align-items: center;
    max-width: 800px;
}
.hero .hero_slider .item{
  position: absolute;
  inset: 0 0 0 0;
  top: 5%;
  overflow: hidden;
  opacity: 0;
  transition: .5s;
}
.hero .hero_slider .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
}
/* .hero .hero_slider .item::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
      to top, #000 18%, transparent
  );

} */
.hero .hero_slider .item .content{
  position: absolute;
  left: 10%;
  top: 5%;
  width: 100%;
  max-width: 600px;
  z-index: 1;
  /* color: white; */
}

.hero .hero_slider .item .content p{
  line-height: 25px;
}
.hero .hero_slider .item .content p:nth-child(1){
  text-transform: uppercase;
  letter-spacing: 4px;
}
.hero .hero_slider .item .content h2{
    font-size: 55px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 65px;
    margin: 0 0 10px;
}
.title_bold{
    color: var(--primary-color);
}
.content_header_image{
    height: 60px !important;
    width: auto !important;
    min-width: 200px;
    margin-bottom: 10px;
}
.hero .hero_slider .item.active{
  opacity: 1;
  z-index: 1;
}
.hero_right{
    z-index: 2;
    width: 50%;
    max-width: 800px;
}
@keyframes showContent {
  to{
      transform: translateY(0);
      filter: blur(0);
      opacity: 1;
  }
}
.hero .hero_slider .item.active p:nth-child(1),
.hero .hero_slider .item.active img:nth-child(1),

.hero .hero_slider .item.active h2,
.hero .hero_slider .item.active p:nth-child(3),
.hero .hero_slider .item.active .btnMore{
  transform: translateY(30px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent .5s .7s ease-in-out 1 forwards;
}
.hero .hero_slider .item.active h2{
  animation-delay: 1s;
}
.hero .hero_slider .item.active p:nth-child(3){
  animation-duration: 1.3s;
}
.hero .hero_slider .item.active .btnMore{
  animation-duration: 1.5s;
}


.btnMore{
  width: 140px;
  background: none;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  font-size: 14px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  /* height: 50px; */
  position: relative;
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
  background-color: #2e2e2e;
  animation-delay: 1.5s;
  transition: .3s;
}

.btnMore:hover{
  background: white;
  color: black;
}
.arrows{
  position: absolute;
  top: 30%;
  right: 50px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  min-width: 90px;
  max-width: 100px;
}
.arrows span{
  background-color: #2e2e2e;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: x-large;
  color: #eee;
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrows span:hover{
  background-color: #eee;
  color: black;
}
.thumbnail{
  position: absolute;
  bottom: 50px;
  z-index: 10;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 250px;
  padding: 0 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: center;
  /* overflow-x: hidden; */
}
.thumbnail::-webkit-scrollbar{
  width: 0;
}
.thumbnail .item{
  width: 150px;
  height: 220px;
  filter: brightness(.5);
  transition: .5s;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

}
.thumbnail .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  position: relative;
}
.thumbnail .item.active{
  filter: brightness(1.5);

}

/* .thumbnail .item.active::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient( to top, #000000cc 20%, transparent );
  z-index: 0;
} */
.thumbnail .item .content{
  position: absolute;
  inset: auto 10px 10px 10px;
  /* color: #eee; */
  z-index: 1;
}

.backgroundOverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.2;
  z-index: 0;
}


/* circle */

.ripple-background{

position: absolute;
bottom: 0px;
right: 0px;
}
.circle{
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(90deg, var(--blue-color) 0%, var(--primary-color) 100%);
  animation: ripple 12s infinite;
  box-shadow: 0px 0px 1px 0px var(--blue-color); 
}

.small{
  width: 200px;
  height: 200px;
  right: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  right: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  right: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  right: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  right: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}

@media (max-width: 700px){

  .ripple-background{
    position: absolute;
    left: -355px;
    bottom: -200px;
  }
}
/* circle animation end--- */

@media(max-width: 1450px){
    .hero .hero_slider .item {
        top: 15%;
    }
    .hero .hero_slider .item .content{
        max-width: 550px;
    }
    .hero .hero_slider .item .content h2{
        font-size: 42px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 48px;
        margin: 0 0 10px;
  }
}
@media (max-width: 1200px){
    .hero{
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: auto;
        width: 100%;
        padding: 20px 10px;
    }

    .hero_right{
    width: 100%;
}
    .hero .hero_slider{
        width: 100%;
        min-height: 370px;
    }
  .hero .hero_slider .item .content{
      top: 4%;
      margin: 0 auto;
      left: 0; right: 0;
  }
  .hero .hero_slider .item{
    top: 0;
  }

  .hero .hero_slider .item .content p{
  font-size: 14px;
}

.hero .hero_slider .item .content h2{
      font-size: 40px;
      line-height: 38px;
  }

  .thumbnail{
      align-items: flex-end;
  }
  .thumbnail .item{
      height: 145px;
  }
}

/* nubes */
.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -72px;
}
@media screen and (max-width: 1075px) {

  .tab-navigation{
      width: 100%;
  }
  .hero .hero_slider .item .content{
      /* top: 20%; */
  }
  .thumbnail{
      justify-content: start;
      padding: 0 40px;
      scrollbar-width: thin;
  }
  .hero .hero_slider .item .content h2{
      font-size: 38px;
  }
  .arrows{
      top: 50%;
      left: 10%;
  }
}

@media (max-width: 650px){
  .hero .hero_slider .item .content p:nth-child(1){
  text-transform: uppercase;
  letter-spacing: 2px;
  }
  .hero .hero_slider .item .content h2{
      font-size: 30px;
  }

  .btnMore{
      font-size: 12px;
  }

  .arrows{
      top: 60%;
  }
  .thumbnail{
      padding: 20px;
  }
  .thumbnail .item{
      width: 160px;
  }
}

@media (max-width: 600px){
  .nubesComponent{
  position: absolute;
  left: 0;
  bottom: -102px;
}
.hero .hero_slider{
        min-height: 330px;
    }

    .hero .hero_slider .item .content{
      padding: 10px 20px;
    }
}

@media screen and (max-height: 540px) {

  
  .thumbnail{
      align-items: flex-end !important;
  }

  .thumbnail .item{
      height: 115px !important;
  }


}

</style>
