<template>
    <div id="defender">
        <!-- Banner -->
        <SliderTextImages :dataSlider="dataSliderTextImages">
            <div class="sliderText_imageBx">
                <img src="/images/services/microsoft_defender/bannerImage.webp">
            </div>
        </SliderTextImages>
        <!-- Why Service -->
        <section class="section">
            <div class="section_container">
                <div class="section_container_left">
                    <img class="section_container_left_img" src="/images/services/microsoft_defender/logo.webp" alt="">
                </div>
                <div class="section_container_right">
                    <titleBox :dataTitleBox="titleBox_for_whySection" :configTitleBox="{'positionTitle': titleBox_for_whySection.positionTitle }"/>
                    <button type="submit" class="btnShodow" >
                        <a href="#contact" class="btnShodow_link">
                           Solicita la llamada de un ejecutivo
                        </a>
                    </button>
                </div>
            </div>
        </section>
        
        <!-- section contacto 1-->
        <section id="contact" class="section">
            <div  class="section_container">
                <div class="section_container_left section_container_left_withPadding">
                    <titleBox :dataTitleBox="titleBox_for_contactFirst" :configTitleBox="{'positionTitle': titleBox_for_contactFirst.positionTitle }"/>
                </div>
                <div  class="section_container_right">
                    <div class="section_container_right_ContactBx">
                        <ContactSimply :ViewDataForm="{
                                stateInputs:{
                                nombre: true,
                                email: true,
                                telefono: true,
                                asunto: false,
                                motivo: false,
                                mensaje: true,
                                
                            },
                            withBLur: false,
                            asunto: 'Consulta Microsoft Defender',
                            motivo: 'Consulta comercial Microsoft Defender'
                        }"
                        titleForm="Contacto"
                        />
                    
                    </div>
                </div>
            </div>
        </section>
        
        <!-- section Beneficios -->
        <section class="section section_grayColor">
            <div class="nubesComponent_top">
                <nubes/>
            </div>
            <div class="section_container section_container_column">
                <div class="section_container_left section_container_left_widthFull">
                    <titleBox :dataTitleBox="titleBox_for_benefits" :configTitleBox="{'positionTitle': titleBox_for_benefits.positionTitle }"/>
                </div>
                <div class="section_container_right section_container_right_widthFull">
                    <div v-for="(benefit, indexBenefit) in dataBenefits" :key="indexBenefit" class="cardInfo">
                        <div class="cardInfo_head">
                            <img class="cardInfo_head_img" :src="benefit.image" alt="">
                            <h3 class="cardInfo_head_title">{{ benefit.title }}</h3>
                        </div>
                        <div class="cardInfo_body">
                            <p class="cardInfo_body_text">{{ benefit.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- section contacto 2-->
        <section class="section">
            <div class="section_container section_container_reverse">
                <div class="section_container_left section_container_left_withPadding">
                    <titleBox :dataTitleBox="titleBox_for_contactSecond" :configTitleBox="{'positionTitle': titleBox_for_contactSecond.positionTitle }"/>
                </div>
                <div class="section_container_right">
                    <div class="section_container_right_ContactBx">
                        <ContactSimply :ViewDataForm="{
                                stateInputs:{
                                nombre: true,
                                email: true,
                                telefono: true,
                                asunto: false,
                                motivo: false,
                                mensaje: true,
                                
                            },
                            withBLur: false,
                            asunto: 'Consulta Microsoft Defender',
                            motivo: 'Consulta comercial Microsoft Defender'
                        }"
                        titleForm="Contacto"
                        />
                    
                    </div>
                </div>
            </div>
        </section>

        <!-- section segurity -->
        <section class="section section_grayColor section_paddingBottom">
            <div class="nubesComponent_top">
                <nubes/>
            </div>
            <div class="section_container section_container_reverse">
                <div class="section_container_left ">
                    <img class="section_container_left_img" src="/images/services/microsoft_defender/image1.webp" alt="">
                </div>
                <div class="section_container_right section_container_right_flex">
                    <titleBox :dataTitleBox="titleBox_for_segurty" :configTitleBox="{'positionTitle': titleBox_for_segurty.positionTitle }"/>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
import SliderTextImages from '@/components/web/sliders/SliderTextImages.vue';
import titleBox from '@/components/web/titleBox.vue';
import ContactSimply from "@/components/web/forms/contactSimply.vue";
import nubes from '@/components/web/nubes.vue';
export default{
    components: {
        SliderTextImages,
        titleBox,
        ContactSimply,
        nubes
    },
    data: ()=>({
        // Banner
        dataSliderTextImages:{
        withBackgrounds: false,
        withArrows: false,
        withThumbnail: false,
        withBackgroundsFixed: true,
        withPointsAnimation: false,
        services: [
            {
            "title": "Potencia la seguridad informática de tu empresa con ",
            "title_bold": "Microsoft Defender",
            "mainImage": "/QUINTA%20COLONIAL%20CCS/_MG_0164-2_fb9orh.jpg",
            "header_title" : "Microsoft Azure",
            "header_img": "/images/services/microsoft_defender/logo.webp ",
            },

        ]
        },
        // Why Service
        titleBox_for_whySection:{
          title: '¿Por qué elegir',
          title_bold: 'Microsoft Defender?',
          description: 'En iHosting, entendemos la importancia de proteger tus activos digitales y mantener tu empresa a salvo de amenazas cibernéticas. Por eso, somos representantes oficiales de los servicios de Microsoft Defender, una solución de seguridad líder en el mercado que proporciona una defensa integral contra malware, ransomware y otras formas de ataques cibernéticos.',
          color: 'var(--dark-color)',
          positionTitle: 'left'
        },

        // section contact 1
        titleBox_for_contactFirst:{
          title: '¿Listo para fortalecer la seguridad de tu empresa? ',
          title_bold: '¡Déjanos ayudarte!',
          description: 'Completa el formulario a continuación para solicitar una llamada personalizada de uno de nuestros ejecutivos de ventas. Descubre cómo Microsoft Defender puede adaptarse a las necesidades específicas de tu empresa y brindarte la protección que mereces.',
          color: 'var(--dark-color)',
          positionTitle: 'left'
        },

        //section Beneficios
        titleBox_for_benefits:{
          title: 'Beneficios clave de ',
          title_bold: 'Microsoft Defender',
          color: 'var(--dark-color)',
          positionTitle: 'center'
        },

        dataBenefits:[
            {
                image: '/images/services/microsoft_defender/icon3.webp',
                title: 'Protección Avanzada',
                text: 'Con tecnología de vanguardia, Windows Defender ofrece una protección avanzada contra amenazas conocidas y desconocidas, manteniendo tus sistemas seguros en todo momento.',
            },
            {
                image: '/images/services/microsoft_defender/icon2.webp',
                title: 'Detección y Respuesta',
                text: 'Detecta y responde rápidamente a posibles intrusiones y ataques, minimizando el impacto en tu empresa y protegiendo la integridad de tus datos.',
            },
            {
                image: '/images/services/microsoft_defender/icon1.webp',
                title: 'Integración con Microsoft Ecosystem',
                text: 'Como parte del ecosistema de Microsoft, Microsoft Defender se integra perfectamente con otras herramientas y servicios de la marca, proporcionando una seguridad cohesiva y eficaz en todas las plataformas.',
            }
        ],

        // section contact 1
        titleBox_for_contactSecond:{
        title: 'Solicita Asistencia Especializada en ',
        title_bold: 'Microsoft Defender',
        description: '¿Necesitas ayuda para implementar y optimizar Microsoft Defender en tu entorno empresarial? Nuestros expertos certificados están listos para brindarte la asistencia el soporte que necesitas. Completa el formulario a continuación y uno de nuestros especialistas se pondrá en contacto contigo lo antes posible.',
        color: 'var(--dark-color)',
        positionTitle: 'left'
        },

        // section segurity
        titleBox_for_segurty:{
          title: 'Potencia la seguridad de tu Empresa con ',
          title_bold: 'iHosting y Microsoft Defender',
          description: 'En iHosting, nos comprometemos a ofrecerte soluciones de seguridad confiables y efectivas. Con Microsoft Defender, puedes estar seguro de que tu empresa está protegida en todo momento. ¡Contáctanos hoy mismo y lleva la seguridad de tu empresa al siguiente nivel!',
          color: 'var(--dark-color)',
          positionTitle: 'left'
        },
    })
}
</script>

<style scoped>

.sliderText_imageBx{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.section{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 0px 0px;
    position: relative;
}

.section_grayColor{
   background:  var(--grey-color);
   padding-top: 150px;
}

.section_paddingBottom{
    padding-bottom: 100px;
}
.section_container{
    display: flex;
    max-width: 1500px;
    width: 100%;
}
.section_container_column{
    flex-direction: column;

}
.section_container_reverse{
    flex-direction: row-reverse;
}
.section_container_left{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

}
.section_container_left_widthFull{
    width: 100%;
}

.section_container_left_withPadding{
    padding: 0 50px;
}
.section_container_right{
    width: 50%;
    padding: 0 60px;
    z-index: 2;
}

.section_container_right_flex{
    display: flex;
}
.section_container_right_widthFull{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0px;
}
.section_container_left_img{
    max-width: 500px;
}


/* contact */
.section_container_right_ContactBx{
    position: relative;
    width: 100%;
}
.section_container_right_ContactBx::before{
    content: '';
    position: absolute;
    min-width: 200px;
    min-height: 200px;
    height: 82%;
    width: 85%;
    background: linear-gradient(90deg, var(--blue-color) 0%, var(--primary-color) 100%);
    border-radius: 50%;
    margin: 0 auto;
    top: 9%;
    bottom: 5%;
    left: 5%;
    right: 5%;

}


/* btn start */
.btnShodow{
    background: var(--primary-color);
    color:  var(--white-color);
    font-family: var(--title-fonts);
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    padding: 5px 35px;
    line-height: 2;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
    transform: scale(1);

}
.btnShodow:hover{
    transform: scale(1.05) !important;
    color: var(--white-color) !important;

}

.btnShodow_link{
  color:  var(--white-color);

}
/* btn end */

/* card start */
.cardInfo{
    min-width: 280px;
    max-width: 380px;
    width: auto;
    min-height: 334px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 8px;
    background: var(--white-color);
    margin: 0 5px;
}
.cardInfo_head{
    margin-bottom: 10px;
}

.cardInfo_head_img{
    width: 65px;
    max-height: 80px;
    margin-bottom: 8px;
}
.cardInfo_head_title{
    font-size: 17px;
    font-weight: 700;
    text-align: start;
    color: var(--primary-color);
}

.cardInfo_body_text{
    font-size: 14px;
    font-weight: 500;
}

/* card end */

/* nubes */
.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -72px;
}
.nubesComponent_top{
  position: absolute;
    top: 65px;
    left: 0;
    z-index: 1;

}
/* nubes end */
@media (max-width: 1300px){
    .sliderText_imageBx img{
    max-width: 500px;

}

}
@media (max-width:1200px){
    .section_container_right, .section_container_left_withPadding{
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center
    }
    .section_container_right{
        flex-direction: column;
        align-items: center
    }
 
    .section_container_right_widthFull{
        flex-direction: column;
    
    }
    .sliderText_imageBx{
        padding: 20px;
    }
    .sliderText_imageBx img{
        height: 90%;
        max-height: 400px;
        max-width: 300px;
    }
}

@media(max-width:950px){
    .section{
        padding: 40px 0px 60px;
    }
    .section_grayColor{
        padding-top: 100px;
    }
    .section_container_right, .section_container_left{
        width: 100%;
        padding: 0 5px;
    }

    .section_container {
        flex-direction: column;
    }

    .section_container_left_img{
        max-width: 300px;
    }
    .cardInfo{
        margin-bottom: 15px;
    }
}

@media (max-width:600px){
    .sliderText_imageBx img{
        min-height: 460px;
    } 
}
</style>