<template>
    <div>
        <nav v-if="estadoHorario == false" class="navChat8i" :class="showNavMessages ? 'open' : ''">
            <div class="navChat8i_navcontent">
                <div @click="changeViewButton" class="toggle-btn">
                    <img src="images/help.png" alt="message8i">
                    <i class='fa fa-plus'></i>
                </div>
                <span class="nav-content_iconMenu" style="--i:2;">
                    <a target="_blank" href="https://wa.me/56322768037?text=Hola!">
                        <i class="fa fa-whatsapp"></i>
                    </a>
                </span>

            </div>
        </nav>

        <nav v-else class="navChat8i" >
            <div class="navChat8i_navcontent">
                <div @click="viewContact" class="toggle-btn">
                    <img src="images/help.png" alt="message8i">
                </div>

            </div>
        </nav>
        
    </div>
</template>
<script>
export default {
    data: () => ({
        showNavMessages: false,
        timeCurrent: null,
        timeLimite: process.env.VUE_APP_TIME_LIMIT || '18:15:00',
        timeMinChile: process.env.VUE_APP_TIME_MIN_CHILE || '09:00:00',
        estadoHorario: false
    }),
    created() {
      this.obtenerHoraChile();
    },
    watch:{
        estadoHorario(){
            if(this.estadoHorario){
                setTimeout(() => { //setTimeout en caso de que tarde en cargar el elemento chatwoot
                    // console.log("this.estadoHorario es true");
                    let chatBtn = document.querySelector('.woot-widget-bubble');
                    // console.log('chatBtn: ', chatBtn)
                    chatBtn.classList.toggle('widgetBubble_none')
                }, 2000);
            }
        }
    },
    methods: {
        changeViewButton() {
            this.showNavMessages = !this.showNavMessages;
            let chatBtn = document.querySelector('.woot-widget-bubble');
            chatBtn.classList.toggle('widgetBubble_top');
        },
        obtenerHoraChile() {
            // si es fin de semana mandar al formulario de contacto ---
            if(this.esFinDeSemana(new Date())){
                return this.estadoHorario = true;
            }
            // verificar por hora laboral ---
            this.timeCurrent = this.timeChile();
            
            if(this.timeCurrent >= this.timeMinChile && this.timeCurrent <= this.timeLimite){
                // console.log("estadoHorario: false");
                this.estadoHorario = false;

            }else if(this.timeCurrent < this.timeMinChile || this.timeCurrent > this.timeLimite){
                // console.log("estadoHorario: true");
                this.estadoHorario = true;

            }            
        },
        
        viewContact(){
            window.location.replace('/nosotros#contacto');
        },

        timeChile(){
            const zonaHorariaChile = "America/Santiago";
            const horaActualChile = new Date();
            // Obtener la hora actual formateada en 24 horas
            const timeChile = horaActualChile.toLocaleTimeString("es-CL", {
                timeZone: zonaHorariaChile,
                hour12: false, // Establece el formato de 24 horas
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            });
            return timeChile;
        },
         esFinDeSemana(fecha) {
            const diaSemana = fecha.getDay();
            // const diaSemana = 0;

            return diaSemana === 0 || diaSemana === 6;
        }
        // chatwoot() {
        //     console.log("se ejecuta chatwoot: ");
        //     // window.$chatwoot.popoutChatWindow();
        //     // window.$chatwoot.toggleBubbleVisibility("hide")
        //     // window.$chatwoot.toggle("open"); // To open widget
        //     // window.$chatwoot.toggle("close"); // To close widget

        //     window.chatwootSettings = {
        //         //... other Settings
        //         darkMode: "auto",
        //     };
        // }
    },
}
</script>
<style>
.navChat8i {
    position: fixed;
    bottom: 18px;
    right: 10px;
    width: 80px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: -webkit-grab;
    cursor: grab;
    z-index: 9000000000;

}

.navChat8i .navChat8i_navcontent {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.navChat8i_navcontent .toggle-btn{
    height: 64px;
    width: 64px;
}

.navChat8i_navcontent .toggle-btn,
.navChat8i_navcontent .nav-content_iconMenu a {

    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    text-align: center;
}

.navChat8i_navcontent .nav-content_iconMenu a {
    font-size: 50px;
    height: 60px;
    width: 60px;
}

.navChat8i_navcontent .nav-content_iconMenu a i{
    color: #fff;

}

.navChat8i_navcontent .toggle-btn {
    position: relative;
    /* font-size: 35px; */
    color: #0e2431;
    z-index: 100;
    cursor: pointer;
    transition: all 0.6s ease;


}
.navChat8i_navcontent .toggle-btn i{
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    
    /* transition: opacity .2 transform 1s; */
    transition: transform 300ms ease, opacity 100ms ease;
}
.navChat8i_navcontent .toggle-btn img{
    width: 28px;
    height: 28px;
    opacity: 1;
    transition: .3s;
}

.navChat8i.open .toggle-btn img{
    opacity: 0;

}
.navChat8i.open .toggle-btn i{
    opacity: 1;
    transform: rotate(-225deg);
    color: rgba(255, 0, 0, 0.514);
}

/* .nav.open .toggle-btn {
    position: relative;
    content: 'x';
} */

/* .nav.open .nav {
    z-index: 1000;

} */

.navChat8i_navcontent .nav-content_iconMenu {
    position: absolute;
    transition: transform 300ms ease, opacity 100ms ease, visibility 0ms linear 0ms, bottom 0ms linear 0ms;
    opacity: 0;

}

.nav-content_iconMenu a:hover {
    box-shadow: 0 8px 32px rgba(0, 0, 0, .4) !important;
}

.navChat8i.open .navChat8i_navcontent .nav-content_iconMenu {
    transform: translateY(-80px);
    opacity: 1;
}


.navChat8i_navcontent .nav-content_iconMenu a {
    text-decoration: none;
    background-color: #67D449;
    display: flex;
    justify-content: center;
}

.navChat8i_navcontent .nav-content_iconMenu a img{
    width: 28px;
    height: 28px;
    margin: 0 auto;
}

.navChat8i_navcontent .nav-content_iconMenu a i {
    font-size: 28px;
    /* color: #0e2431; */
    opacity: 0.8;
    transition: 0.2s;
}

.nav.open .nav-content_iconMenu a i {
    font-size: 24px;
    color: #0e2431;
    opacity: 0.8;
    transition: 0.2s;
}

.navChat8i_navcontent .nav-content_iconMenu a:hover i {
    opacity: 1;
}</style>