<template>
    <div>
      <section class="config cd-main-content pb-80 blog sec-bg2 motpath boxshadowcustom section_" :class="{ 'event-none-v': processCart }">
        <!-- Header resumen -->
        <div class="resumenHeader pt-breadcrumb pt-breadcrumb-cart custom-background">
          <div class="bg-logo-banner" style="display: block !important;">
            <img src="images/isotipo.png" alt="ihosting_isotipo" />
          </div>
          <div class="container resumenHeader_image" style="position: absolute;">
            <img src="images/about-us/Group-510-1.svg" class="img-fluid" alt="seozie-img" />
          </div>
          <div class="pt-section-title-1 mb-0 resumenHeader_title" style="max-height: 53px">
            <h5 v-if="response == 1" class="resumenHeader_title_text">¡Gracias Por <br/><span>Confiar</span> En ihosting!</h5>
            <h5 v-else class="resumenHeader_title_text"> El pago no ha sido <br/><span>completado</span></h5>
          </div>
        </div>
              
        <section class="ResumenPay">
          <!-- list pay -->
          <listpay :response="response" :listpay="listpay" :notasCobroNeto="notasCobroNeto" :esPagoRapido="esPagoRapido"/>
  
          <!-- resumen de pago -->
          <DataPay 
                :response="response" 
                :listpay="listpay" 
                :dataPay="dataPay" 
                :notasCobroNeto="notasCobroNeto" 
                :esPagoRapido="esPagoRapido"
                @finishOrder="finishOrder" 
                @clearPago="clearPago" 
                @generateReport="generateReport" 
              />
        </section>
  
  
        <div style="height: 0px; position: absolute">
          <form id="form_webpay" :action="action">
            <input type="hidden" name="token_ws" v-model="token" />
            <button class="disabled-button" id="submit_webpay" type="submit"></button>
          </form>
        </div>
      </section>
  
      <div>
        <!-- PDF RESUMEN  720-->
        <vue-html2pdf
          :show-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="720"
          filename="comprobante_nc_pdf"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-content-width="1120px"
          ref="html2Pdf"
          pdf-orientation="landscape"
          @hasGenerated="hasGenerated()"
        >
          <div slot="pdf-content" class="pdf-content-res px-2">
            <section class="ResumenPay">
              <!-- list pay -->
              <listpay :response="response" :listpay="listpay" :notasCobroNeto="notasCobroNeto" :esPagoRapido="esPagoRapido"/>
  
              <!-- resumen de pago -->
              <DataPay 
                :pdfView="true" 
                :response="response" 
                :listpay="listpay" 
                :dataPay="dataPay" 
                :notasCobroNeto="notasCobroNeto" 
                :esPagoRapido="esPagoRapido" 
                :error_="error_"
                @finishOrder="finishOrder" 
                @clearPago="clearPago" 
                @generateReport="generateReport" 
              />
  
            </section>
          </div>
        </vue-html2pdf>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapMutations } from "vuex";
  import listpay from './ListsPay.vue'
  import DataPay from './DataPay.vue'
  
  import VueHtml2pdf from "vue-html2pdf";
  export default {
    components: {  VueHtml2pdf, listpay, DataPay },
    data: () => ({
      response: null,
      action: null,
      token: null,
      verify: false,
      finish: true,
      error_: false,
      processCart: false,
      esPagoRapido: false,
      esPagoPorCupon: null,
  
      listpay: [],
      dataPay: null,
    }),
    created() {
      this.checkResponse();  
    },
  
    computed: {  
      // suma del valor neto de todas las notas de cobro 
      notasCobroNeto(){
        let neto = 0;
            // iterar solo si hay elementos
        if(this.listpay.length > 0){
          this.listpay.forEach(e=>{
            neto = neto + parseInt(e.neto);
          });
        }
        return neto;
      },
  
    },
    methods: {
      ...mapMutations(["clearResume", "updateStatus", "setToken"]),
      ...mapActions(["sendResumeTo"]),
    
      // funcion que toma los datos del pago desde la respuesta URL
      checkResponse() {
        // sin no es una transferencian
        if(this.$route.query.resp != 4){

          // En caso de recibir el dato de pago (params) como respuesta, estos se guardan
          if (this.$route.query.params && this.$route.query.params != null) {
            let params = JSON.parse(this.$route.query.params);
            this.listpay = params.notas;
            this.esPagoRapido = params.payFast;
            this.dataPay = params.dataPay;
          }
          // Pago por cupon
          if(this.$route.query.params && this.$route.query.PAYCUPON){
            this.esPagoPorCupon = JSON.parse(this.$route.query.PAYCUPON);
          }
        }else{
          this.dataPay = this.$route.query.monto_total;
        }
  
        // En caso de que no obtengamos el dato (resp) redirecionamos al home
        if (this.$route.query.resp ) {
          this.response = this.$route.query.resp;
          this.updateStatus(this.$route.query.resp);
        } else if(!this.$route.query.resp) {
          this.$router.push("/");
        }
        
      },
  
      // borra los datos del servicio que se desea pagar
      clearPago(){
        this.clearResume()
      },
    
      // funcion que permite reintentar el pago con las notas que obtuvimos como respuesta en la URL
      finishOrder() {
        this.$Progress.start();
        this.processCart = true;
        this.verify = true;
        if (this.listpay.length == 0) {
          this.error_ = true;
          this.processCart = false;
          this.verify = false;
          this.$toast.error("Error al intentar procesar el pago.");
          this.$Progress.fail();
          return false;
        } else {
          this.error = false;
        }
        setTimeout(() => {
          let nc = [];
          this.listpay.forEach((value) => {
            nc.push(value);
          });
          this.sendResumeTo({'notascobro': nc, 'esPagoPorCupon': this.esPagoPorCupon}).then((res) => {
            if (res.status == "success") {
              this.action = res.formAction;
              this.token = res.tokenWs;
              this.setToken(this.token);
  
              setTimeout(() => {
                this.$toast.success("Redireccionando...");
                this.$Progress.finish();
                var form = document.getElementById("form_webpay");
                form.submit();
                this.esPagoPorCupon = null;
              }, 500);
            }else{
              this.error_ = true;
              this.processCart = false;
              this.verify = false;
              this.$toast.error("Error al intentar procesar el pago.");
              this.$Progress.fail();
            }
          });
        }, 0);
      },
  
      // Funcion que genera un pdf del componente resumen
      generateReport() {
        setTimeout(() => {
          this.$refs.html2Pdf.generatePdf();
        }, 1000);
      },
  
  
    },
  };
  </script>
  
  <style scoped>
  
   /* RESUMEN HEADER */
  .resumenHeader{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px !important;
    min-height: 300px;
  }
  
  .resumenHeader_image{
    position: absolute;
    max-width: 320px;
    max-height: 320px;
    right: 60px;
    bottom: -5px;
  }
  
  
  .resumenHeader_title_text{
    font-size: 50px !important;
      line-height: 52px;
      z-index: 9;
      position: relative;
      margin-bottom: 10px;
      font-weight: 600;
      text-align: center;
      color: var(--dark-color);
      line-height: 70px;
  }
  
  .resumenHeader_title_text span{
    color: var(--primary-color);
  }
  
  
  /* RESUMEN */
  .ResumenPay{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 40px 25px;
    max-width: 1200px;
    margin: 0 auto;
    /* max-height: 100vh; */
  }
  
  
  
  
  
  
  
  /* OTROS */
  
  .m-chckbox--label {
    margin-bottom: 0 !important;
  }
  
  .m-chckbox--container {
    line-height: 14px !important;
    margin: 5px 0 8px 0 !important;
  }
  
  .error-nc-v-resume {
    position: relative !important;
    font-size: 13px !important;
    color: red !important;
    line-height: 0.3 !important;
    top: 16px !important;
  }
  
  
  
  @media (max-width: 1100px){
  
  
     /* RESUMEN HEADER */
    .resumenHeader_image{
      display: none;
    }
  }
  
  @media (max-width: 560px){
    .resumenHeader_title{
      max-height: 300px !important;
    }
    .resumenHeader_title_text{
      font-size: 35px !important;
    }
  }
  </style>