<template>
  <div
    class="col-md-12 px-0 mt-4 only-responsive"
    :class="{ 'reverse-responsive': resolucion }"
    style="padding: 0px !important; flex-flow: row wrap; justify-content: space-between"
  >
    <div v-if="step_config == 1" class="col-md-3 col-v col-v-1">
      <button
        @click="$emit('back')"
        alt="regresar" aria-label="regresar"
        type="button"
        class="btn btn-default-blue-fill btn-back-footer-cart"
        style="
          background-color: #f5f9ff !important;
          color: var(--dark-color) !important;
          height: 52px;
          padding: 8px 45px !important;
        "
      >
        Atrás
      </button>
    </div>

    <div v-if="step_config == 2 && step == 3" class="col-md-4 col-v col-v-3" style="margin-left: auto">
      <button
        @click="$emit('goToCheckout')"
        alt="continuar" aria-label="continuar"
        type="button"
        class="btn btn-default-grad-purple-fill-black"
        style="float: right; width: 100%; height: 52px; padding: 8px 45px !important"
      >
        <span class="spinner-span"
          >Continuar <i class="fas fa-arrow-alt-circle-right ml-2" style="position: relative; top: 9px"></i
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import { resolucion } from "@/helpers";
export default {
  props: ["service", "step"],
  data: () => ({
    step_config: 1,
  }),
  created() {
    this.$root.$on("configurationComplet", () => (this.step_config = 2));
    this.$root.$on("returnStepConfg", () => (this.step_config = 1));
  },
  computed: { resolucion },
};
</script>

<style scoped>
.col-v {
  margin-top: 0px;
}

.col-v-1 {
  padding-left: 0 !important;
}

.col-v-3 {
  padding-right: 0 !important;
}

.reverse-responsive {
  flex-direction: column-reverse !important;
}
@media (max-width: 991px) {
  .col-v {
    margin-top: 30px;
  }

  .col-v-1 {
    padding-left: 15px !important;
  }

  .col-v-3 {
    padding-right: 15px !important;
  }
}
</style>
