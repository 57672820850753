<template>
    <header class="header" :class="{'header_active': widthPantalla <= 1165}" id="header">
      <div class="header_container" id="navbarDown">
            <!-- header logo -->
            <router-link class="header_container_head_logo_bx" to="/#home">
              <img  @click="changeStateLink()" class="header_container_head_logo_bx_img" :class="{'displayNone': $route.name != 'Home' && $route.name != 'Nosotros'}"  src="images/logo_blanco.svg" alt="ihosting" />
              <img  @click="changeStateLink()" class="header_container_head_logo_bx_img header_container_head_logo_bx_img_imageBlack" :class="{'displayBlock': $route.name != 'Home' && $route.name != 'Nosotros'}"  src="images/logo_negro.png" alt="ihosting" />
            </router-link>
            <!-- header nav -->
            <div class="header_container_nav">
              <AppHeaderNav :headerActive="header_active" :links="links" :hash="hash" @changeStateLink="changeStateLink"/>
            </div>
            <!-- header toggle cart/auth -->
            <div class="header_container_head_subMenu">
              <AppHeaderToggle :headerActive="header_active"/>
            </div>
          </div>
    </header>
</template>

<script>
import AppHeaderNav from './AppHeaderNav.vue';
import AppHeaderToggle from './AppHeaderToggle.vue';
export default{
    components:{
      AppHeaderNav,
      AppHeaderToggle
    },
    data: ()=>({
      widthPantalla: 0,
      header_active: false,
      hash: '',
      links: [
        {
          name: 'Hostings', 
          link: '/Hostings#hostings', 
          hash: '#hosting',
          active: false,
          subMenu: [ 
            {
              name: 'Hosting Emprendedor',
              link: '/Hostings#emprendedor'
            },
            {
              name: 'Hosting Empresa Básico',
              link: '/Hostings#basico'
            },
            {
              name: 'Hosting Empresa Premium',
              link: '/Hostings#premium'
            },
            {
              name: 'Hosting Wordpress',
              link: '/Hostings#wordpress'
            },
            {
              name: 'Hosting E-learning',
              link: '/Hostings#e-learning'
            },
          ]
        },
        {
          name: 'Servicios',
          active: false,
          subMenu: [
            {
              name: 'VPS SSD ',
              link: '/vpsSSD',
            },
            {
              name: 'Cloud VPS',
              link: '/vpsSSDCloud'
            },
            {
              name: 'Co-Location',
              link: '/cLocation#cLocation'
            },
            {
              name: 'Microsoft Azure',
              link: '/AzureIhosting'
            },
            {
              name: 'Microsoft 365',
              link: '/microsoft'
            },
            {
              name: 'Microsoft Defender',
              link: '/microsoftDefender#defender'
            },
          ]
        },
        {
          name: 'Ingenieria TI',
          link: '/ingenieria#ingenieria',
          hash: '#ingenieria',
          active: false,
          subMenu: [
            {
              name: 'Administración de infraestructura',
              link: '/ingenieria#administracion-infraestructura',
            },
            {
              name: 'Monitoreo de plataformas',
              link: 'ingenieria#monitoreo-plataformas'
            },
            {
              name: 'Diseño de arquitectura TI',
              link: '/ingenieria#diseño-arquitectura'
            },
            {
              name: 'Test de stress',
              link: '/ingenieria#test-stress'
            },

          ]
        },
        {
          name: 'Conectividad',
          link: '/conectividad#conectividad',
          hash: '#conectividad',
          active: false,
          subMenu: [
            {
              name: 'VPN Empresarial',
              link: '/conectividad#vpn',
            },
            {
              name: 'Punto-Punto',
              link: '/conectividad#punto-punto'
            },
            {
              name: 'Bring Your Own IP Space (BYOIP)',
              link: '/conectividad#byoip'
            },
            {
              name: 'Peering',
              link: '/conectividad#peering'
            },

          ]
        },
        {
          name: 'Nosotros', 
          link: '/nosotros#nosotros',
          hash: '#nosotros',
          active: false,
        },
        {
          name: 'Blog', 
          link: 'https://blog.ihosting.cl/',
          active: false,
        },
        {
          name: 'Contáctanos', 
          link: '/nosotros#contacto',
          hash: '#contacto',
          active: false,
        },
      ]
    }),
    mounted() {

        this.widthPantalla = window.innerWidth;
        if(this.widthPantalla <= 1165){
          this.header_active = true;
        }
        window.addEventListener('scroll', ()=> {
            const header = document.getElementById('header');
            const navbar = document.querySelector('#navbarDown'); 

            // Verifica si los elementos existen antes de intentar acceder a sus propiedades
            if (header && navbar) {
                let height = header.clientHeight;
                header.classList.toggle('header_active', window.scrollY + 59 > height);
                if(window.scrollY + 59 > height || this.widthPantalla <= 1165){
                  this.header_active = true;
                }
                else{
                  this.header_active = false;
                }
            }
        });   
    },
    methods: {
      changeStateLink(index = null){
        // console.log("changeStateLink: ", index);
            this.links.forEach(e=> e.active = false);
            if(index != null) this.links[index].active = !this.links[index].active;
            this.$forceUpdate();
        }
    },
}
</script>

<style scoped>

.header{
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 30px 10px;
  z-index: 999;
  transition-duration: .3s;
  border-bottom: 1px solid #80808000;
}

.header_container{
  position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0px;
    max-height: 99%;
}


.header_container_head_logo_bx{
  width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.header_container_head_logo_bx_img{
  max-width: 150px;
  height: 50px;
}

.header_container_head_logo_bx_img_imageBlack{
  display: none;
}


.header_container_head_subMenu{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 11%;
  max-width: 140px;
  min-width: 138px
}

  .header_container_nav {
  width: 70%;
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.header_container_head_social{
  width: 30%;
  display: flex;
  justify-content: flex-end;
}


/* ===== Tab content ===== */
.tab-content {
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
}

.tab {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: auto;
	max-width: 1100px;
	padding: 15px 50px;
	opacity: 0;

	transform: translateX(25px);
	content-visibility: hidden;
}

.tab.active {
	transform: translateX(0);
	content-visibility: visible;
	opacity: 1;
	transition: opacity 1s ease, transform 1s ease;
}

.tab .row {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 50px 0;
	gap: 30px;
}

.tab .img-card {
	position: relative;
	width: 450px;
	max-width: 450px;
	height: 300px;
	max-height: 300px;
	border-radius: 20px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 5px 25px rgb(2, 2, 2, 0.1);
}

.tab .img-card img {
	width: 100%;
	object-fit: cover;
}

.right-column {
	max-width: 800px;
}

.info .city,
.info .description p {
	color: #fff;
	margin-bottom: 10px;
}

.info .city {
	font-size: 2em;
}

.country {
	color: #434354;
	font-size: 5em;
	font-weight: 700;
	text-align: center;
	text-shadow: 0 5px 25px rgb(2, 2, 2, 0.1);
}



/* HEADER ACTIVE START */

.header_active{
  background-color: rgba(251,251,253,0.8);
  backdrop-filter: saturate(180%) blur(20px);
  border-bottom: 1px solid #81818114;
  box-shadow: -1px -10px 20px 2px #00000052;

  /* padding: 20px 30px 20px; */
}

.header_active .header_container_head_logo_bx_img{
display: none;
}

.header_active .header_container_head_logo_bx_img_imageBlack{
display: block;
}


/* HEADER ACTIVE END */


.displayNone{
  display: none;
}
.displayBlock{
  display: block;
}
/* ===== Media queries (max-width: 1050px;) ===== */
/* @media screen and (max-width: 1050px) {


} */


@media (max-width:1260px){
	section {
		margin: 0 0;
	}

	.tab-nav-bar {
		margin: 65px 20px 40px 25px;
	}

	.tab {
		padding: 15px 25px;
	}

	.tab .row {
		flex-direction: column;
	}

	.tab .img-card {
		width: auto;
		max-width: 600px;
	}

	.country {
		font-size: 10vw;
		padding-bottom: 50px;
	}


  .header{
    background-color: rgba(251,251,253,0.8);
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid #81818114;
    box-shadow: -1px -10px 20px 2px #00000052;
    padding: 15px 10px 15px 20px;   
  }
  .left-btn,
.right-btn {
  width: 38px;
}
  /* .header_container_head_search, .header_container_head_social{
    display: none;
  } */

  .header_container_head_logo_bx{
    width: 50%;
    justify-content: space-between;
  }

  .header_container_head_logo_bx_img{
  max-width: 130px;
  height: 40px;
display: none;

}



 .header_container_head_logo_bx_img_imageBlack{
display: block;
}

  .hamburger {
    display: block;
  }

  .tab-menu{
    width: 79%;
  }

  .tab-btn{
    margin: 0 1px;
    padding: 2px 12px;
  }


  .header_container_nav{
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
  }
  .header_container_head_subMenu{
    width: 50%;
    padding-right: 60px;
    max-width: 340px;

  }
}

@media screen and (max-width: 650px) {
  .header{
    padding: 15px 5px 15px 10px;
  }

  .header_container_head_logo_bx{
    width: 30%;
  }
  .header_container_head_subMenu{
    width: 65%;
  }


}
</style>