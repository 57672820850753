import axios from 'axios';
// Indica si el scriptCloud se ha agregado a la plataforma
let activeScript = false;
let finishResponse = false;
let scriptID = 'scriptTurnstile';
let tokenTurnstile = null;
let turnstileInstance = null;

const verifyCloud = (id_widgetCloud)=>{
    // esta funcion se llama cuando el script en el head del html ya está agregado ya que la funcion se encuntra en ese sript
    window.onloadTurnstileCallback = async function () {
        turnstileInstance = turnstile;
    // se agrega dentro del elemento con id {$id_widgetCloud} el ifrome de turntile que valida
   await turnstileInstance.render(`#${id_widgetCloud}`, {
        sitekey: process.env.VUE_APP_SITE_SECRET_TURNSTILE,
        retry: "auto",
        'retry-interval': "9000",
        theme: 'light',
        action: id_widgetCloud,
        callback: function(token) { // funcion que obtiene la respuesta de turnstile
            tokenTurnstile = {'cf-turnstile-response': token};
        },
        'error-callback': ()=>  console.log("error turnstile")
        });
    };
}


//  funcion que hace una peticion al backend para que valide el token otorgado por turnstile
//  De ser true pasa al siguiente paso, de no ser asi se reinicia el turnstile
const serverSideValidation = (dataToken)=>{
    const url = process.env.VUE_APP_API_BASE_URL + '/api/verifyUser/turnstile';

    axios.post(url, dataToken).then(res=>{
        if(res.data.status == 1){
            finishResponse = true;
            // return finishResponse;
        }
        else{
            console.log("error del servidor", res);
            turnstileInstance.reset()
        }
    }).catch(error=>{
        console.log("error del servidor", error);
        turnstileInstance.reset()
    });

}

export default {
    
    // Agrega script de cloud en el head del html
    activeTurnstileCloud(id_widgetCloud){
        if(activeScript == false){
            // procede a crear un script html que llama a turnstile
            const scriptCloud = document.createElement('script');
            scriptCloud.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
            scriptCloud.async = true;
            scriptCloud.defer = true;
            scriptCloud.id = scriptID;
            scriptCloud.as="script"
            // Agrega el script en el head del html
            document.head.appendChild(scriptCloud);  
            // console.log(scriptCloud);
            activeScript = true;
            // llama funcion que renderiza el widget de turnstile
            verifyCloud(id_widgetCloud);
        }else{
            // console.log('activeScript ya creado: ', activeScript);
            verifyCloud(id_widgetCloud);
        }
      },
      removeTurnstileCloud(){
        if(activeScript == true){
            let script = document.getElementById(scriptID); 
            script.remove(script) // se elimina elemento html del head
            turnstileInstance.remove(); // se elimina instancia de turnstile
            activeScript = false;
            finishResponse = false; 
        }
      },
      getStateTurnstile(){
        // 
        return finishResponse;
      },
      // obtener el token que retorna cloudflare que se usa para validarlo en el backend   
      getToken(){
        return tokenTurnstile;
      },
      resetTurnstile(){
        if(turnstileInstance != null){
            turnstileInstance.reset();
        }
      }
}