<template>
       <section class="container" :class="{'container_widthFull': configSwiper.perView.slidesPerViewThree == 4}" :style="`padding:${styleSection.padding}`">
       
        <div class="titleBx_cupon" v-if="categoryWithCoupon">
          <star :stylesConfig="{size: '2em'}"/>
          <h3 class="titleBx_cupon_title">
             Aprovecha nuestros descuentos exclusivos para clientes nuevos por tiempo limitado
          </h3>
          <star :stylesConfig="{size: '2em'}"/>
        </div>
         <div class="card__container swiper" :class="{card__container_loading: loading }">
            <div class="card__content" :class="`card__content_${keySlide}`">
               <div  class="swiper-wrapper">
                  <article v-for="(item, index) in categorie" :key="item.id" class="card__article swiper-slide" :style="`width:${styleBoxCard.width} !important; max-width: ${styleBoxCard.width}`">
                    <Card :serviceProp="item" :index="index" :styleBoxCard="{width: styleBoxCard.width, min_width: styleBoxCard.min_width, max_width: styleBoxCard.max_width, viewMore: true  }" :infoCategory="infoCategory" :imageCard="imageCard" :promoGlobal="promoGlobal" :sslGlobal="sslGlobal" :addDCTO="addDCTO"></Card>
                  </article>   
                  <article v-if="cardContact" class="card__article swiper-slide">
                    <div class="cardContact" :class="{ active: true }">
                        <div class="cardContact_info">
                          <span class="cardContact_info_headerTitle">Necesitas un plan a tu medida?</span>
                          <span class="cardContact_info_subtitle"><em>Contáctate con nosotros para preparar una solución que se acomode a tus requerimientos.</em></span>
                          <h2 class="cardContact_info_title" style="margin: 45px 0px;"> ¡Contáctanos!</h2>
                          <p class="cardContact_info_subtitle"> Planes VPS a medida. </p>
                        </div>
                        <div class="cardContact_footer">
                          <a href="/nosotros#contacto" class="cardContact_footer_button" :class="{ 'pt-button-change-color': true}">Contacto</a>
                        </div>
                        <div class="pt-bg-effect polygon-new">
                          <img src="images/polygon/Polygon-Luminary.svg" alt="gym-image" />
                        </div>
                    </div>
                  </article>    
               </div>
            </div>

            <!-- Navigation buttons -->
            <div class="swiper-button-next" :class="`swiper-button-next_${keySlide}`">
               <i class="ri-arrow-right-s-line"></i>
            </div>
            
            <div class="swiper-button-prev" :class="`swiper-button-prev_${keySlide}`">
               <i class="ri-arrow-left-s-line"></i> 
            </div>

            <!-- Pagination -->
            <div class="swiper-pagination" :class="`swiper-pagination_${keySlide}`"></div>
         </div>
         <div class="container_loading" v-if="loading">
          <Loading/>
         </div>
      </section>
</template>

<script>
import {swiperCreate} from "@/helpers";
import Card from "@/components/web/cards/Card.vue";
import Loading from "@/components/web/Loading.vue";
import star from "../svg/star.vue";


export default{
  name: 'SliderCardPlans',
  components:{
    Card,
    Loading,
    star
  },
  props:{
    configSwiper: {
      default: ()=>({
        loop: false,
        autoplay: false,
        time: 700,
        perView: {'slidesPerViewTwo': 2, 'slidesPerViewThree': 3, viewDynamic: true}
      })
    },
    categorie:{
      default: ()=>{
       return []
      }
    },
    styleBoxCard:{
      default: ()=>({
                background: '#ffffff',
                width: '340px',
                min_width: '320px',
                max_width: '342px'
      }),
      required: false
    },
    styleSection:{
      default: ()=>({
        padding: '0px',
      })
    },
    index:{
      default: 0
    },
    infoCategory:{
          default: ()=>{
            return {
              category: '',
              subCategory: ''
            }
          }
    },
    imageCard:{
            type: Object,
            default: ()=> {
                return {
                    src: '',
                    state: false
                }; 
            }
    },
    keySlide:{
      type: String,
      default: ""
    },
    cardContact:{
      type: Boolean,
      default: false
    },
    promoGlobal: {
      type: Object,
      default: ()=>{
          return {
            status: false,
            text: ''
          }
            }
        },
    sslGlobal:{
      default: false,
      type: Boolean
    },
    addDCTO:{
      default: false
    }
    // loading:{
    //   type: Boolean,
    //   default: true
    // }
        

    },
    data: ()=>({
      loading: true
    }),
    watch:{
      categorie(value){
        if(value.length > 0 ) this.loading = false;
      }
    },
    computed:{
      styleBoxCardComputed(){
            return {
                'width': this.styleBoxCard.width,
                'max-width': this.styleBoxCard.max_width,
                'min-width':  this.styleBoxCard.min_width, 
                'background': this.styleBoxCard.background,

            }
        },

        categoryWithCoupon(){
          if(this.categorie){
            return this.categorie.some(element => element.cupon_service);
          }

          return false
        }
    },
    methods: {
        swiperCreate,
        
    },
    mounted(){
      this.swiperCreate(this.keySlide, this.configSwiper.loop, this.configSwiper.autoplay, this.configSwiper.time, this.configSwiper.perView);
      setTimeout(() => { if(this.categorie.length > 0) this.loading = false }, 900);
    },

}
</script>

<style scoped>
/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --second-color: hsl(195, 75%, 52%);
  --dark-color: hsl(212, 40%, 12%);
  --white-color: hsl(212, 4%, 95%);
  --body-color: hsl(212, 42%, 15%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Bai Jamjuree", sans-serif;
  --h2-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

section {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--white-color);
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

/*=============== CARD ===============*/
.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  /* min-height: 735px; */
  max-height: 905px;
  width: 100%;
  margin: 0 auto 10px;
  position: relative;
  z-index: 6;

  flex-direction: column;
  align-items: center;
}

.container_widthFull{
  /* min-width: 1580px; */
  min-width: 100%;

}

.titleBx_cupon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}


.titleBx_cupon_title{
  font-size: 25px;
  text-align: center;
  max-width: 95%;
  display: flex;
  align-items: center;
}
.section_paddingBottom{
    padding-bottom: 130px;
}
.container_loading{
  width: 100%;
  height: 100%;
  min-height: 725px;

  padding: 100PX 20PX;
  display: flex;
  justify-content: center;
}

.card__container {
  position: relative;
  padding-block: 1rem;
  /* background: gray; */
  max-width: 1580px;
  height: auto;
  /* min-height: 90%; */
  padding-bottom: 35px;
  z-index: 1;
  opacity: 1;
  transition: .3s;

}


.card__container_loading{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 0;
}
.card__content {
  margin-inline: 1.75rem;
  border-radius: 1.25rem;
  overflow: hidden;
}

.card__article {
  width: 300px; /* Remove after adding swiper js */
  border-radius: 1.25rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 15px 0px rgb(0 36 80 / 10%);


}
/* Swiper class */
.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
}

.swiper-button-prev,
.swiper-button-next {
  width: initial;
  height: initial;
  font-size: 1.4rem;
  color: var(--second-color);
  background: var(--primary-color);
  box-shadow:1px 1px 8px 0px rgb(4 70 151 / 88%);
  border-radius: 40px;
  height: 45px;
  width:45px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 275px !important;
  /* padding: 2px; */
  /* display: none; */
}

.swiper-button-prev {
  left: 2rem;
}

.swiper-button-next {
  right: 2rem;
}
.swiper-pagination{
  margin-top: 10px;
  transform: translateX(-50%) translateY(10px) !important;
  z-index: 10;
}

.swiper-pagination-bullet {
  background-color: hsl(212, 32%, 40%);
  opacity: 1;
  z-index: 10;
  /* background: red; */
}

.swiper-pagination-bullet-active {
  background-color: var(--second-color);
}



/* cardContact START*/

.cardContact{
  width: 380px;
  background: var(--primary-color);
  padding: 85px 30px 35px;
  /* width: 340px; */
  min-width: 320px;
  height: 567px;
  border-radius: 1.25rem;
  text-align: center;
  position: relative;
}

.cardContact_info{
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff7a;
}

.cardContact_info_headerTitle{
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 5px;
}

.cardContact_info_subtitle{
  font-size: 14px;
  margin-bottom: 10px;

}
.cardContact_info_title{
  color: var(--white-color);
}
/* cardContact END*/

.cardContact_footer{
  position: relative;
  padding: 35px 0px;
}
.cardContact_footer_button{
  position: relative;
    width: auto;
    background: var(--white-color)!important;
    color: var(--primary-color) !important;
    font-family: var(--title-fonts);
    font-size: 16px;
    padding: 5px 35px;
    line-height: 2;
    vertical-align: middle;
    border: none;
    display: inline-block;
    overflow: hidden;
    border-radius: 5px;
    z-index: 10;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
/* @media screen and (max-width: 320px) {
  .card__data {
    padding: 1rem;
  }
} */
@media(max-width: 1700px){

}
@media (max-width: 1300px){
  .card__container{
    max-width: 1140px;
  }
}

@media(max-width:1200px){
  .card__article {
    box-shadow: none;
  }
  .swiper-pagination{
  display: none;
}
}
@media (max-width: 1100px){
  .card__container{
    max-width: 90%;
  }
  .titleBx_cupon_title{
    width: 68%;
  }
}
@media(max-width:768px){
  .container {
    margin: 10px auto 10px;

  }
  .card__article {
   max-width: 100% !important;
  }



}
/* For medium devices */
@media screen and (min-width: 768px) {

  .card__content {
    margin-inline: 1.2rem;
  }
}

@media(max-width: 500px){
  .container{
    max-height: 180vh;
  }
  .card__content {
    margin-inline: 1.05rem;
  }
  .titleBx_cupon_title{
    font-size: 18px;
    text-align: center;
    width: 68%;
  }
}

/* For large devices */
/* @media screen and (min-width: 1120px) {
  /* .card__container {
    max-width: 1100px;
  } */  


  /* .swiper-button-prev {
    left: -1rem;
  }
  .swiper-button-next {
    right: -1rem;
  } */
/* }  */
</style>