<template>
  <div>
    <div class="col-md-12 px-0">
      <div v-if="!user" class="alert alert-light alert-payment-v" role="alert">
        - Para activar tus servicios, inicia sesión o regístrate con nosotros.
        <br/>
        {{ onlyHosting == 0 ? '- Para utilizar los días de prueba elegir pago por transferencia.' : '' }}
        <br/>
        {{ cupon_query && cupon_query.code ? '- Para aplicar cupón del servicio, inicia sesión.' : '' }}

      </div>
      <label class="mb-0" style="text-align: left; top: 7px"
        ><b>Método de pago</b>
        <i
          v-tooltip="'Seleccione método de pago'"
          class="fas fa-question-circle tooltip-icon-v"
          style="font-size: 16px !important"
        ></i
      ></label>
      <div class="row">
        <div class="col-md-6">
          <div class="row card-checkbox px-1" style="position: relative; top: -7px">
            <ul class="small-block-grid-3 pl-0" style="width: 100%; position: realtive; top: 3px">
              <li class="col-md-12 px-0">
                <label>
                  <input
                    @change="selectMP('webpay')"
                    type="radio"
                    v-validate="'required'"
                    data-vv-as="Método de pago"
                    name="payment"
                    id="payment_1"
                    key="1001"
                    class="card-input-element v2-element"
                    ref="radio_1"
                    checked
                  />

                  <div class="card-input">
                    <div class="card-input-body">
                      <div class="icon webpay"></div>
                      WebPay
                    </div>
                  </div>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <!-- METODO 2 -->
        <div class="col-md-6">
          <div class="row card-checkbox px-1" style="position: relative; top: -7px">
            <ul class="small-block-grid-3 pl-0" style="width: 100%; position: realtive; top: 3px">
              <li class="col-md-12 px-0">
                <label>
                  <input
                    @change="selectMP('transferencia')"
                    type="radio"
                    v-validate="'required'"
                    data-vv-as="Método de pago"
                    name="payment"
                    id="payment_1"
                    key="1001"
                    class="card-input-element v2-element"
                    ref="radio_1"
                  />

                  <div class="card-input">
                    <div class="card-input-body">
                      <div class="icon trans"></div>
                      Transferencia
                    </div>
                  </div>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="order-standard_cart" style="line-height: 1.4 !important">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <div class="view-cart-items-header">
                <div class="row" style="flex-flow: row nowrap">
                  <div class="col-lg-12 col-sm-12 col-xs-12">Resumen del Pedido</div>
                </div>
              </div>

              <div class="view-cart-items">
                <div class="item">
                  <div class="row">
                    <div class="col-md-12">
                      <span style="display: block">
                        <div style="font-size: 1.6em">Monto a pagar</div>
                      </span>

                      <span class="item-group">Método de pago: {{ metodo_pago }} </span>
                    </div>

                    <div v-if="cart.length > 0" class="col-md-12 price-total-table">
                      <h6 style="font-size: 16px; white-space: nowrap">
                        <b
                          >NETO: ${{
                            formatNumber(
                              Math.round(
                                setMasterTotalPriceService(cart) -
                                  (setMasterTotalPriceService(cart) / 100) * 19
                              )
                            )
                          }}</b
                        >
                      </h6>
                      <h6 style="font-size: 16px; white-space: nowrap">
                        <b
                          >IVA: ${{
                            formatNumber(Math.round((setMasterTotalPriceService(cart) / 100) * 19))
                          }}</b
                        >
                      </h6>
                    </div>
                    <div class="col-md-12 col-lg-12 px-0 pt-pricebox-info price-card-new price-cont pr-3">
                      <h6 class="price" style="text-align: right">
                        <span> ${{ formatNumber(setMasterTotalPriceService(cart)) }} </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 px-0">
        <button
          v-if="user && cart.length != 0"
          alt="activar servicios" aria-label="activar servicios"
          @click="finishOrder"
          class="btn btn-default-grad-purple-fill white-font btn-default-grad-purple-fill-black mt-3"
          style="width: 100%; height: 52px"
          :disabled="!user"
          :class="[verify ? 'verify-class' : 'normal-class']"
        >
          <span v-if="!verify" class="spinner-span" style="top: -3px; position: relative"> 
            <span>Activar <span v-if="cart.length == 1">Servicio</span> 
            <span v-else>Servicios</span>
          </span>
            <i class="fas fa-arrow-alt-circle-right spinner-i"></i
          ></span>
          <div v-else class="spinner-border text-info" style="max-height: 30px" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>

        <div v-if="user != null && metodo_pago == 'webpay'" class="col-md-12 px-0 container-sc-v">
          <a  @click="changeViewCupon()" class="seguir-comprando-btn mt-2 pt-2"> {{ !cupon.show ? 'Aplicar cupón' : 'No aplicar cupón'  }} </a>
        </div>

        <div v-if="cupon.show && metodo_pago == 'webpay'" class="inputCupon">
          <input
            v-model="cupon.input"
            v-validate="'required'"
            type="text"
            placeholder="Cupón..."
            class="input-white-custom mb-0 inputCupon_input"
            maxlength="60"
          />
          <button :disabled="cupon.loading" class="btn btn-default-purple-fill inputCupon_btn" @click="addCupon({code: cupon.input})"><span>Agregar</span></button>
        </div>

        <ul class="listCupon" v-if="cupon.show && apliedCupons.length > 0 || apliedCupons.length > 0 ">
          <b>Promoción</b>
          <li v-for=" (aplied, index) in apliedCupons" class="listCupon_item" :key="index">
            <div @click="deleteCupon(aplied)" class="listCupon_item_delete"><i class="fas fa-times"></i></div> 
            <span v-if="aplied" class="listCupon_item_cupon">
              Cupón <b>{{ cupon.codigo_cupon }}</b> aplicable para {{  aplied.name_service }} 
              <!-- si es  un cupon de periodicidades -->
              <div v-if="aplied.cupon_allData ">
                <b v-if="aplied.cupon_allData.periodicidad_state && aplied.cupon_allData.periodicidad_state == 1">
                - Descuento {{ aplied.cupon_allData.id_tipocupon == 2 ? `del ${aplied.cupon_allData.cupon_periodicidad_selected.valor}%` : `de $${aplied.cupon_allData.cupon_periodicidad_selected.valor}` }} - Pago {{ aplied.cupon_allData.cupon_periodicidad_selected.nombre  }}
                </b> 
                <b v-else>
                - Descuento {{ aplied.cupon_allData.id_tipocupon == 2 ? `del ${aplied.cupon_allData.valor}%` : `de $${aplied.cupon_allData.valor}` }} 
                </b> 
              </div>
          </span>
        </li>
        </ul>

        <div class="col-md-12 px-0 container-sc-v">
          <a @click="goToCategory" class="seguir-comprando-btn mt-2 pt-2">Seguir Comprando</a>
        </div>
      </div>
    </div>

    <div style="height: 0px; position: absolute">
      <form id="form_webpay" :action="action">
        <input type="hidden" name="token_ws" v-model="token" />
        <button class="disabled-button" id="submit_webpay" type="submit"></button>
      </form>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from 'axios'
import {
  formatNumber,
  setPriceService,
  setTotalPriceService,
  setMasterTotalPriceService,
} from "@/helpers.js";
export default {
  props: ["cart", "user"],
  data: () => ({
    action: null,
    token: null,
    metodo_pago: "webpay",
    verify: false,
    cupon: {
      show: false,
      input: '',
      codigo_cupon: null,
      loading: false,

    },
    apliedCupons: [],
    cupon_query: null,

  }),

  created() {
      this.apliedCupons = this.loadCuponsAplied();
      this.checkResponse();  
  },

  computed: {
    ...mapGetters(["getToken", "getCart", "getUser"]),
    
    onlyHosting(){
      let valid = 0;
      this.cart.forEach(e=>{
        e.category_.id != 17 ? valid = valid + 1 : 0;
      });
      return valid
    },
    // getCuponsAplied(){
     
    //   return this.loadCuponsAplied();
    // },
  },
  watch:{
    cart(before, after){
      // console.log("se agrega o se elimina un nuevo servicio");
      this.apliedCupons = this.loadCuponsAplied();

    },
    getUser(){
      // console.log("getUser watch ", this.getUser);
      if(this.getUser != null){
        this.checkResponse();
      }
    }
  },
  methods: {
    ...mapActions(["sendCartTo"]),
    ...mapMutations(["setResume", "clearCart", "setToken", "addCuponService", "deleteCuponService"]),

    formatNumber,
    setPriceService,
    setTotalPriceService,
    setMasterTotalPriceService,
    // funcion que toma los datos del pago desde la respuesta URL
    checkResponse() {
    // sin no es una transferencian
    if(this.$route.query.cupon){
       this.cupon_query = {code: this.$route.query.cupon} ;
      if(this.$route.query.cupon_periodicidad){
        this.cupon_query.id_periodicidad = this.$route.query.cupon_periodicidad;
      }
      // console.log("existe cupon: ", cupon_query , " this.getUser: ", this.getUser);
        if(this.getUser != null){
          // console.log("user se encuentra logueado se ejecuta addcupon: ", this.getUser);
          this.addCupon(this.cupon_query);   
          this.changeViewCupon();     
        }else{
          console.log("user NOO SE encuentra logueado ", this.getUser);

        }
      }
    },
    selectMP(mpago) {
      this.metodo_pago = mpago;
      if('transferencia') this.deleteCupon();
    },
    // validateCarrito(){
    //   this.getCart.forEach(service=>{
    //     if(service.periodicidad_state == 1){

    //     }
    //   });
    // },
    finishOrder() {
      this.$Progress.tempTransition({ speed: "2s", opacity: "2.6s", termination: 1300 });
      this.$Progress.start();
      this.verify = true;
      this.$emit("processCartStart");
      // this.validateCarrito();
      setTimeout(() => {
        this.sendCartTo(this.metodo_pago).then((res) => {
          // console.log(res);
          if (res.status == "success") {
            switch (this.metodo_pago) {
              case "webpay": // WEBPAY
                this.action = res.formAction;
                this.token = res.tokenWs;
                this.setToken(this.token);
                this.setResume(res.carrito);
                this.clearCart();

                setTimeout(() => {
                  this.$toast.success("Redireccionando...");
                  this.$Progress.finish();
                  var form = document.getElementById("form_webpay");
                  form.submit();
                }, 500);
                break;
              case "transferencia": // TRANSFERENCIA
                this.setResume(res.carrito);
                this.clearCart();
                window.location.replace(`/resumen_compra?resp=4&monto_total=${res.total_pagado.toString()}`);
                break;
            }
          } else {
            this.$toast.error("Error al procesar el pago");
            this.$Progress.fail();
            this.verify = false;
            this.$emit("processCartFinish");
          }
        });
      }, 0);
    },

    goToCategory() {
      this.$emit("goToCategory");
    },

    changeViewCupon(){
      this.cupon.show = !this.cupon.show
      if(!this.cupon.show) this.deleteCupon();
    },

    addCupon(cupon){
      this.cupon.codigo_cupon = cupon.code;
      if(cupon.id_periodicidad) this.cupon.id_periodicidad = cupon.id_periodicidad;
      this.validateCupon();

    },
    // delete and reset state cupon
    deleteCupon(cupon = null){
      this.cupon.codigo_cupon = null;
      this.cupon.input = '';
      if(this.cupon.id_periodicidad) this.cupon.id_periodicidad = null;

      this.deleteCuponService(cupon);
      this.apliedCupons = this.loadCuponsAplied();
      // console.log("deleteCupon se ejecuta");
      this.refresh()
    },

    refresh(){
      this.$emit("refreshAllView");
    },
    // valida si el cupon a agregar existe y si aplica para el servicio seleccionado para luego agregar el cupon al servicio
    validateCupon(){
      // console.log("se ejecuta validateCupon: this.getUser ",  this.getUser);
      if(this.getUser == null){
        // console.log("se ejecuta validateCupon, user no se encuentra logueado this.getUser ",  this.getUser);
        this.$toast.error("Cupones no disponible sin antes iniciar sesión.");
        return false;
      }
      // console.log("se ejecuta validateCupon,se debe de ejecutar si usa estaa logueado: ",  this.getUser);

      if(this.metodo_pago == 'webpay'){
        this.$Progress.start();
        this.cupon.loading = true;
        // array que contendrá los servicios del carrito del cliente con su categoria id para validarlo
        // console.log("validateCuponthis .getCart: ",  this.getCart);
        let servicesCategory = this.loadCuponsAplied();
        // console.log("validateCuponthis .servicesCategory: ",  servicesCategory);

        // console.log("getCart: ", this.getCart);
        // console.log("servicesCategory: ", servicesCategory);
        let url = process.env.VUE_APP_API_BASE_URL + '/api/coupons/validate';
        let paramsCupon = {
          'servicesCart':servicesCategory, 
        }
        // console.log("params: ", paramsCupon);
        axios.post(url, paramsCupon).then(res=>{
          // if(this.apliedCupons.lenght == 0){
          //   this.apliedCupons = res.data.cupones;
          // }
          this.$toast.success("Cupón valido.");
          // console.log("res: ", res);
          this.cupon.loading = false;
          this.$Progress.finish();
          this.$emit('ChangeReload', true);
          // agregar cupon al servicio a comprar
          if(res.data.cupones.length > 0) this.addCuponService(res.data.cupones);
          this.apliedCupons = this.loadCuponsAplied();

        }).catch(error=>{
          console.log("error: ", error);
          this.$emit("get_response_cupon_selected", error.response.data);

          this.$toast.error("El cupón ingresado no es aplicable.");
          this.$Progress.fail();
          this.cupon.loading = false;
        });
      }else{
        this.$toast.error("Cupones no disponible por transferencia.");
      }
    },

    loadCuponsAplied(){
      let apliedCupons = [];
      this.cart.forEach(service=>{
        if(this.cupon && this.cupon.codigo_cupon != null || service.cupon){
          let servicesCategory_value = {
            'id_category': service.categoria.id, 
            'id_service': service.id, 
            'name_service': service.nombre, 
            'id_cliente': this.user.id, 
            };
                      // si el servicio del carrito no tiene un cupon agregado se le agrega el cupon que se obtiene de la url para verificar si es valido para este service
          if(!service.cupon){
            servicesCategory_value.cupon = this.cupon.codigo_cupon, 
            servicesCategory_value.cupon_id_periodicidad =  this.cupon.id_periodicidad ? this.cupon.id_periodicidad :  null  
          }else{
            servicesCategory_value.cupon = service.cupon.codigo_cupon, 
            servicesCategory_value.cupon_id_periodicidad = service.cupon.periodicidad_state == 1 && service.cupon.cupon_periodicidad_selected.id_periodicidad ? service.cupon.cupon_periodicidad_selected.id_periodicidad :  null  
            servicesCategory_value.cupon_allData = service.cupon;
         
          }
            apliedCupons.push(servicesCategory_value);
        }
      });
      return apliedCupons;
    }
  },
};
</script>
<style scoped>


.inputCupon{
  padding: 10px 0px 10px 10px;
  display: flex;
  justify-content: center;

}

.inputCupon_input{
  width: 70% !important;
  border: 1px solid var(--primary-color) !important;
  border-right: none !important;
}
.inputCupon_input:focus{
  outline: none !important;
  /* border: 1px solid transparent !important; */
}
.inputCupon_btn{
  position: relative;
  left: -10px;
  cursor: pointer;
}
.inputCupon_btn span{
  color: white;
  font-weight: 400;
}


.listCupon_item{
  display: flex;
  width: auto;
  background: var(--light);
  padding: 10px 12px;
}

.listCupon_item_delete{
  margin: 0px 10px 0px 2px;
  cursor: pointer;
}

</style>