<template>
  <div id="ingenieria" style="overflow: hidden">
    <!-- START home 3 REVOLUTION SLIDER 6.3.2 -->
    <p class="rs-p-wp-fix"></p>
    <rs-module-wrap
      id="rev_slider_2_1_wrapper"
      data-alias="home-2-1"
      data-source="gallery"
      style="background: transparent; padding: 0; margin: 0px auto; margin-top: 0; margin-bottom: 0; min-height: 740px; padding: 5px 0px;"
    >
      <rs-module
        id="rev_slider_ingenieria"
        class=""
        data-version="6.3.2"
        style="overflow: visible !important"
      >
        <rs-slides style="overflow: visible !important">
          <rs-slide data-key="rs-2" data-title="Slide" data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;">
            <img
              src="rev-slider/assets/transparent.png"
              alt="Slide"
              data-bg="c:#f5f9ff;"
              data-parallax="off"
              class="rev-slidebg"
              data-no-retina
            />

            <rs-layer
              id="slider-2-slide-2-layer-4"
              data-type="image"
              data-rsp_ch="on"
              class="rs-pxl-2"
              data-xy="x:l,c,c,c;xo:-170px,1px,-1px,0;y:m,b,b,b;yo:-70px,-130px,-90px,-155px;"
              data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
              data-dim="w:986px,500px,411px,333px;h:1080px,548px,450px,355px;"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:1600;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 9"
              ><img
                src="rev-slider/assets/1213.png"
                width="986"
                height="1080"
                alt="slider-image-2"
                data-no-retina
              />
            </rs-layer>

            <rs-layer
              id="slider-1-slide-1-layer-1"
              data-type="text"
              data-color="#606c7a"
              data-rsp_ch="on"
              data-xy="x:r,c,c,c;xo:15px,0,0,0;y:m,t,t,t;yo:30px,145px,113px,69px;"
              data-text="w:normal;s:18,16,0,4;l:34,32,0,6;a:right,center,center,center;"
              data-vbility="t,f,f,f"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:900;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 9; font-family: Jost"
            >
              Un equipo de profesionales estará a tu disposición para ayudarte rápidamente<br />
              a diseñar, administrar y monitorear tu infraestructura tecnológica.
            </rs-layer>

            <rs-layer
              id="slider-1-slide-1-layer-2"
              class="banner-text"
              data-type="text"
              data-color="#002450"
              data-rsp_ch="on"
              data-xy="x:r,c,c,c;xo:15px,0,0,0;y:m,t,t,t;yo:-82px,40px,40px,30px;"
              data-text="w:normal;s:42,47,42,31;l:53,53,50,41;fw:600;a:right,center,center,center;"
              data-dim="w:633.406px,498px,609px,528px;"
              data-frame_0="x:50,39,29,17;"
              data-frame_1="st:900;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 10; font-family: Jost"
            >
              ¿Estás buscando un partner tecnológico para tu negocio?
            </rs-layer>

            <rs-layer
              id="slider-2-slide-2-layer-3"
              data-type="image"
              data-rsp_ch="on"
              data-xy="x:c;y:b;"
              data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
              data-dim="w:2200px,1732px,1315px,811px;h:1238px,975px,740px,456px;"
              data-frame_0="sX:0.9;sY:0.9;"
              data-frame_1="st:600;sp:1000;"
              data-frame_999="o:0;st:w;"
              style="z-index: 8"
              ><img src="rev-slider/assets/Polygon-Luminary-1.svg" alt="slider-image" data-no-retina />
            </rs-layer>

            <router-link class="menu-item" to="/nosotros#contacto">
              <a
                id="slider-1-slide-1-layer-7"
                class="rs-layer rev-btn"
                target="_self"
                rel="nofollow"
                data-type="button"
                data-rsp_ch="on"
                data-xy="x:r,c,c,c;xo:15px,-80px,-80px,-70px;y:m,t,m,m;yo:110px,225px,-90px,-55px;"
                data-text="w:normal;s:18,16,16,14;l:32,32,32,30;"
                data-dim="minh:0px,none,none,none;"
                data-padding="t:12,9,10,8;r:30,24,30,25;b:12,9,10,8;l:30,24,30,25;"
                data-border="bor:5px,5px,5px,5px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                data-vbility="t,f,f,f"
                data-frame_hover="c:#fff;bgc:var(--primary-color);bor:5px,5px,5px,5px;"
                style="z-index: 15; background-color: var(--dark-color); font-family: Jost"
                >Contáctanos
              </a>
            </router-link>
          </rs-slide>
        </rs-slides>
      </rs-module>
    </rs-module-wrap>

    <!-- END REVOLUTION SLIDER -->

    <!-- Service Section Start -->
    <section class="pt-grey-background margin-top-desktop pb-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-md-6 mt-4 mt-md-0" style="z-index: 12">
            <div class="pt-fancy-box pt-fancybox-1" style="text-align: center !important">
              <div class="pt-fancy-media" style="justify-content: center">
                <i class="cont-img-icon-tab" style="background: white; margin: auto auto 20px auto"
                  ><img
                    src="images/icons/cloud.svg"
                    class="img-icon-tab"
                    style="margin: auto; max-width: fit-content"
                /></i>
              </div>
              <h3 class="pt-fancy-box-title">Administración de<br />Infraestructura</h3>
              <div class="pt-fancybox-info">
                <p class="pt-fancybox-description text-left">
                  Administramos tu plataforma tecnológica on-premise o en la nube con los más altos estándares disponibles.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mt-4 mt-md-0" style="z-index: 12">
            <div class="pt-fancy-box pt-fancybox-1" style="text-align: center !important">
              <div class="pt-fancy-media" style="justify-content: center">
                <i class="cont-img-icon-tab" style="background: white; margin: auto auto 20px auto"
                  ><img
                    src="images/icons/dashboard.svg"
                    class="img-icon-tab"
                    style="margin: auto; max-width: fit-content"
                /></i>
              </div>
              <h3 class="pt-fancy-box-title">Monitoreo de<br />Plataformas</h3>
              <div class="pt-fancybox-info">
                <p class="pt-fancybox-description text-left">
                  Aseguramos la continuidad de tu servicio en la nube como on-premise las 24 horas del día.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mt-4 mt-md-0" style="z-index: 12">
            <div class="pt-fancy-box pt-fancybox-1" style="text-align: center !important">
              <div class="pt-fancy-media" style="justify-content: center">
                <i class="cont-img-icon-tab" style="background: white; margin: auto auto 20px auto"
                  ><img
                    src="images/icons/sketch.svg"
                    class="img-icon-tab"
                    style="margin: auto; max-width: fit-content"
                /></i>
              </div>
              <h3 class="pt-fancy-box-title">Diseño de<br />Arquitectura</h3>
              <div class="pt-fancybox-info">
                <p class="pt-fancybox-description text-left">
                  Diseñamos tu infraestructura nube u on-premise, siempre nos adaptamos a tus necesidades, requerimientos y presupuestos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Service Section End -->

    <!-- Website Hosting Start -->
    <section class="section_service">
      <div id="administracion-infraestructura" class="enclaje_section"></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title">
                Administración de <span class="imp-word">Infraestructura</span>
              </h2>
              <p class="pt-section-description">
                ¿Destinas tiempo importante para que tus servicios se mantengan operativos?, puedes dejar en
                manos de profesionales con experiencia la seguridad y administración de tus servidores o
                entorno nube con SLA definidos, olvidándote de complejas configuraciones, caídas inesperadas
                de servicio, actualizaciones, etc. para así enfocarte en tu negocio.
              </p>
            </div>
            <div class="pt-button-block mt-4">
              <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
            </div>
          </div>
          <div class="col-xl-6 col-md-12 mt-4 mt-xl-0">
            <div class="pt-img-main wow animate__animated animate__fadeInRight" data-wow-delay="1s">
              <img
                src="images/patterns/dbaashigh.svg"
                class="svg img-fluid pt-img-padding"
                alt="web-hosing-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Website Hosting End -->

    <!-- MONITOREO DE PLATAFORMAS -->
    <section class="pt-grey-background section_service">
      <div id="monitoreo-plataformas" class="enclaje_section"></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12 mb-4 mb-xl-0">
            <div class="pt-img-main wow animate__animated animate__fadeInLeft" data-wow-delay="1s">
              <img src="images/patterns/monitoring.svg" class="svg img-fluid pt-img-padding" alt="image31" />
            </div>
          </div>
          <div class="col-xl-6 col-md-12">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title">Monitoreo de <span class="imp-word">Plataformas</span></h2>
              <p class="pt-section-description">
                Nuestro equipo de profesionales se encarga de monitorear tu infraestructura, tales como redes
                de datos, storage, SAN, máquinas físicas, virtuales o entorno nube con alertas proactivas en
                todo momento. Estamos permanentemente monitoreando parámetros tales como, tiempos de
                respuesta, disponibilidad, latencia, entre otros.
              </p>
            </div>

            <div class="pt-button-block mt-4">
              <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Website Hosting End -->

    <!-- Website Hosting Start -->
    <section class="section_service" >
      <div id="diseño-arquitectura" class="enclaje_section"></div>

      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12">
            <div class="pt-section-title-1">
              <h2 class="pt-section-title">Diseño Arquitectura <span class="imp-word">TI</span></h2>
              <p class="pt-section-description">
                Diseñamos una arquitectura que permita resolver múltiples variables de disponibilidad, para
                asegurar la continuidad operacional de servicios, entornos críticos y negocio. Es desarrollado
                por consultores senior en plataformas. Su finalidad es conseguir el máximo beneficio en cuanto
                a costos de tecnología, rendimiento y seguridad.
              </p>
            </div>
            <div class="pt-button-block mt-4">
              <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
            </div>
          </div>
          <div class="col-xl-6 col-md-12 mt-4 mt-xl-0">
            <div class="pt-img-main wow animate__animated animate__fadeInRight" data-wow-delay="1s">
              <img
                src="images/patterns/ranking.svg"
                class="svg img-fluid pt-img-padding"
                alt="web-hosing-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Website Hosting End -->

    <!-- Offer Section Start -->
    <section class="pt-blue-background">
      <div
        class="pt-background-overley pt-opacity-1"
        style="background-image: url(images/polygon/Polygon-Luminary.svg)"
      ></div>
      <div class="container">
        <div class="row mx-0 my-0">
          <div class="col-xl-8">
            <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
              <h2 class="pt-section-title text-white">¿Estás en búsqueda de un partner de tecnología?</h2>
              <p class="pt-section-description text-white mb-0">
                Te invitamos a contactarnos, queremos apoyarte en el éxito de tu negocio.
              </p>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="pt-btn-container text-right pt-btn-position">
              <div class="pt-button-block">
                <a class="pt-button pt-button-change-color" href="/nosotros#contacto">
                  <span class="text">Contáctanos</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Offer Section end -->

    <!-- cloud Services Start -->
    <section class="boxshadowcustom section_service">
      <div id="test-stress" class="enclaje_section"></div>

      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">Test de <span class="imp-word">Stress</span></h2>
              <p class="pt-section-description">
                ¿Tú sitio web no funciona ante un gran número de visitas y necesitas determinar cuántas
                visitas simultáneas puede soportar tu sitio?. Es una prueba de carga en la cual simulamos un
                número determinado de visitas para saber el comportamiento del sitio donde te asesoraremos, a
                través de un análisis exhaustivo, entregando recomendaciones para que tu sitio funcione sin
                problemas.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12">
            <div class="pt-img-main wow animate__animated animate__fadeInLeft" data-wow-delay="1s">
              <img src="images/patterns/provisioning.svg" class="svg img-fluid" alt="email-service-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="pt-button-block mt-4" style="text-align: center">
        <a href="/nosotros#contacto" class="pt-button"><span class="text">Contáctanos</span></a>
      </div>
    </section>
    <!-- cloud Services End -->
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import {
  formatNumber,
  changeSeparator,
  swiper_component,
  swiper_component_fast,
  revinit_revslider21,
  changeColorSvg,
  resolucion,
  accordion,
} from "@/helpers";

export default {
  metaInfo: {
        title: "ihosting | Ingeniería",
        htmlAttrs: {
          lang: "es",
          amp: true,
        },
        meta: [
          {
            name: "description",
            content: "Un equipo de profesionales estará a tu disposición para ayudarte rápidamente a diseñar, administrar y monitorear tu infraestructura tecnológica."
          },
          {
            name: "keywords",
            content: "ihosting, hosting, vps, cloudvps, wordpress, ssl, hosting chile",
          },
        ]
  },
  data: () => ({
    infra_01: false,
    type: 1,
    form: {
      cantidad: 1,
    },
  }),

  computed: {
    ...mapGetters(["getServices_"]),
    resolucion,
  },
  created() {},

  mounted() {
    this.accordion();
    this.swiper_component_fast();
    this.changeColorSvg();
    this.revinit_revslider21("#rev_slider_ingenieria");
    this.getServices(1).then(() => {
      this.swiper_component();
    });
  },

  methods: {
    ...mapActions(["getServices"]),
    formatNumber,
    changeSeparator,
    swiper_component,
    revinit_revslider21,
    swiper_component_fast,
    changeColorSvg,
    accordion,
  },
};
</script>
