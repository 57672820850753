<template>
  <div class="cf_wrapper" >
    <div class="cf_wrapper_header">
        <titleBox :dataTitleBox="dataTitleBox" :configTitleBox="{'positionTitle': 'left'}"/>
        <div class="cf_wrapper_direction">
            <button class="buttonDirection" @click="changeStateManual(countFlexSelected - 1)">
                <span class="buttonDirection_content">
                    <svg class="arrow-icon buttonDirection_content_icon" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
                    </svg>
                </span>
            </button>
    
    
            <button class="buttonDirection" @click="changeStateManual(countFlexSelected + 1)">
                <span class="buttonDirection_content">
                    <svg class="arrow-icon buttonDirection_content_icon" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
                    </svg>
                </span>
            </button>
        </div>
    </div>
    <div class="cf_container">
      <div class="cf_container_card" v-for="(item, index) in dataInfoFormated" :key="index">
        <!-- <input type="radio" name="slide" :id="`c${index + 1}`" checked /> -->
        <div  class="cf_container_card_cardFlex" :class="{'cf_container_card_cardFlex_activated': item.state}" @click="changeStateManual(index)" >
            <div class="cf_container_card_cardFlex_row">
                <div class="cf_container_card_cardFlex_row_description" :class="{'cf_container_card_cardFlex_row_description_activated': item.state}">
                    <div class="cf_container_card_cardFlex_row_description_imageBx">
                        <i class="cf_container_card_cardFlex_row_description_imageBx_img" :class="item.icon" aria-hidden="true" style="font-size: 40px;"></i>
                        <!-- <img src="images/services/ventajas/Trafico_limitado.png"  alt="imagen de proceso Azure ihosting"> -->
                    </div>
                    <h4 class="cf_container_card_cardFlex_row_description_title" :class="{'cf_container_card_cardFlex_row_description_title_Activated': item.state}">{{ item.title }}</h4>
                    <p>{{ item.description }}</p>
                </div>
            </div>
            <div class="cf_container_card_cardFlex_row_icon">{{ index + 1 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from '@/components/web/titleBox.vue';

export default {
    components: {
    titleBox
  },
  props: {
    dataInfo: {
      default: {
        cards: [
          // {text: 'Reunión Inicial. '},
          // {text: 'Levantamiento de Necesidades. '},
          // {text: 'Desarrollo de Estructura'},
          // {text: 'Cálculo y Optimización de Costos. '},
          // {text: 'Cotización Optimizada.'},
          // {text: 'Presentación de Solución.'},
          // {text: 'Onboarding y Configuración de Propuesta.'},
          // {text: 'Administración de la Nube. '},
        ],
      },
    },
    dataTitleBox: {
        default: ()=>({})
    }

  },
  data: ()=>({
    dataInfoFormated: [],
    countFlexSelected: 1,
    stopSlider: false,
    tryStart: 0,
    
  }),
  watch:{
    stopSlider(){
      setTimeout(() => {
        if(!this.stopSlider && this.tryStart < 1){
          // console.log("se ejecuta autoSeisdfjaslkfdjlskjf");
          // this.autoSwitch()
          this.tryStart = 1;
        }
        // else{
        //   console.log("se ejecuta watch, no se ejecuta autiswitch");
        // }
      }, 15000);
    }
  },
  mounted() {
    if(this.dataInfo.cards){
        this.dataInfoFormated = [...this.dataInfo.cards];
        // this.autoSwitch();
    }
  },
  methods: {
    changeState(indexSelected){
        if(indexSelected >= 0 && indexSelected < this.dataInfoFormated.length){
            this.countFlexSelected = indexSelected;
            this.dataInfoFormated.forEach((element, index) => {
                index != indexSelected ? element.state = false : element.state = true;
            });
        }
    },
    changeStateManual(param){
      this.stopAutoSwitch(true);
      this.changeState(param);
    },

    stopAutoSwitch(value){
      this.stopSlider = value;
      this.$forceUpdate();
    },
    autoSwitch(){
      if(!this.stopSlider){
        setTimeout(() => {
            if(this.countFlexSelected > this.dataInfoFormated.length){
              this.countFlexSelected = 0;
            }
            // console.log("autoSwitch -- countFlexSelected: ", this.countFlexSelected);
    
            this.changeState(this.countFlexSelected);
            this.countFlexSelected = this.countFlexSelected + 1;
  
            this.autoSwitch();
            this.tryStart = 0;
          
        }, 8000);

    }
    }  
  },
};
</script>

<style scoped>
/* steep */
.cf_wrapper {
  width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    padding: 0 80px;
}
.cf_wrapper_header{
  display: flex;
    width: 40%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}
.cf_wrapper_direction{
  display: flex;
    justify-content: space-between;
    gap: 5px;
    min-width: 105px;
    margin-bottom: 30px;
}

.cf_container {
  height: 400px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.cf_container_card_cardFlex {
    position: relative;
    width: 60px;
    min-height: 350px;
    border-radius: 0.75rem;
    background-size: cover;
    /* background-image: url('/negotiation.jpg'); */
    cursor: pointer;
    overflow: hidden;
    border-radius: 2rem;
    margin: 0 10px;
    display: flex;
    padding: 40px 20px 20px 40px;
    /* align-items: flex-end; */
    transition: 0.6s cubic-bezier(0.28, -0.03, 0, 0.99);
    /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8); */
    box-shadow: 0px 6px 30px -6px rgb(0 0 0 / 13%);
}

.cf_container_card_cardFlex::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.034);
    filter: blur(50px);
}

.cf_container_card_cardFlex > .cf_container_card_cardFlex_row {
  color: white;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.cf_container_card_cardFlex > .cf_container_card_cardFlex_row_icon {
  background: white ;
  color: rgb(36, 36, 36);
  font-weight: 500;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.cf_container_card_cardFlex > .cf_container_card_cardFlex_row > .cf_container_card_cardFlex_row_description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 300px;
  width: 300px;
  min-width: 290px;
  max-width: 350px;

  opacity: 0;
  transform: translateY(30px);
  transition-delay: 0.3s;
  transition: all 0.3s ease;
  /* background: black; */
}

.cf_container_card_cardFlex_row_description_imageBx{
    width: 50px;
    height: 50px;
    margin: 10px 0px 20px;
}
.cf_container_card_cardFlex_row_description_imageBx_img{
    width: 100%;
    height: 100%;
}

.cf_container_card_cardFlex_row_description p {
  color: var(--white);
  padding-top: 5px;
  font-weight: 500;
  font-size: 14px;
  width: 85%;
}

.cf_container_card_cardFlex_row_description_title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  opacity: 0;
  transition-delay: .09s;
  transition: .5s;
  color: white;
}



/* input {
  display: none;
} */

/* input:checked + label {
  width: 600px;
} */


/* ---------------- Card Activated */

.cf_container_card_cardFlex_activated {
  width: 350px;
}

.cf_container_card_cardFlex_row_description_activated {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.cf_container_card_cardFlex_row_description_title_Activated {
    opacity: 1;
}



.cf_container_card:nth-child(1)  .cf_container_card_cardFlex{ 
  background-color: var(--primary-color);
}
.cf_container_card:nth-child(2)  .cf_container_card_cardFlex {
  background-color: var(--blue-color);
}
.cf_container_card:nth-child(3)  .cf_container_card_cardFlex {

  background-color: var(--dark-color);
}
.cf_container_card:nth-child(4)  .cf_container_card_cardFlex {

  background-color: var(--primary-color);
}
.cf_container_card:nth-child(5)  .cf_container_card_cardFlex {

  background-color: var(--blue-color);
}





/* button direction */
.buttonDirection {
  width: 45px;
  height: 45px;
  position: relative;
  border: none;
  background-color: white;
  color: #212121;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  gap: 10px;
  border-radius: 10px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  box-shadow: 0px 6px 30px -6px rgb(0 0 0 / 13%);
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.buttonDirection .buttonDirection_content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.buttonDirection::before {
  content: "";
  position: absolute;
  background-color: var(--blue-color);
  width: 100%;
  height: 100%;
  left: 0%;
  bottom: 0%;
  transform: translate(-100%, 100%);
  border-radius: inherit;
}

.buttonDirection .buttonDirection_content_icon {
  fill: var(--blue-color);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.buttonDirection:hover::before {
  animation: shakeBack 0.6s forwards;
}

.buttonDirection:hover .buttonDirection_content_icon {
  fill: white;
  scale: 1.3;
}

.buttonDirection:active {
  box-shadow: none;
}

@keyframes shakeBack {
  0% {
    transform: translate(-100%, 100%);
  }

  50% {
    transform: translate(20%, -20%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}
@media (max-width: 1555px){
  .cf_wrapper_header{
    align-items: center;
    justify-content: center;
    width: 60%;
  }
}
@media (max-width: 1270px){

  .cf_wrapper {
    flex-direction: column;
    padding: 80px 20px 80px;
    height: auto;

  }

}

/* media query */
@media (max-width: 1105px){
    .cf_wrapper {
    padding: 20px 20px 0px;
    height: auto;

    }
    .cf_container{
        /* flex-wrap: wrap; */
        flex-direction: column;
        height: auto;
    }
    .cf_wrapper_direction{
        display: none;
    }

    .cf_container_card{
        margin-bottom: 30px;
    }
    .cf_container_card_cardFlex{
        display: flex;
        justify-content: center;
        padding: 30px 0px;
        width: 95% !important;

    }
    .cf_container_card_cardFlex_activated{
        width: 100%;
        /* flex: span 1; */
    }

    .cf_container_card_cardFlex > .cf_container_card_cardFlex_row > .cf_container_card_cardFlex_row_description {
    opacity: 1 !important;
    transform: translateY(0) !important;
    }

    .cf_container_card_cardFlex_row_description_title {
        opacity: 1;
    }
    .cf_container_card_cardFlex_row{
        width: 82%;
    }
    
    .cf_container_card_cardFlex > .cf_container_card_cardFlex_row > .cf_container_card_cardFlex_row_description {
        width: auto;

    }
    .cf_container_card_cardFlex_row_description_title {
      font-size: 18px;
    }
}

@media (max-width:600px){
  .cf_wrapper_header{
    width: 90%;
  }
}

/* media query */
/* @media (max-width: 600px){

} */
</style>


