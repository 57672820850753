<template>
  <div id="home">
    <!-- Revolution Slider  -->
    <div class="pt-bottom-padding pt-0 pb-xl-0">
      <p class="rs-p-wp-fix"></p>
      <rs-module-wrap
        v-if="getData"
        id="rev_slider_11_1_wrapper"
        data-source="gallery"
        style="background: transparent; padding: 0; margin: 0px auto; margin-top: 0; margin-bottom: 0"
      >
        <rs-module id="rev_slider_11_1" style="" data-version="6.3.2">
          <rs-slides>
            <rs-slide data-key="rs-11" data-title="Slide" data-anim="ei:d;eo:d;t:fade;sl:7;">
              <img
                src="rev-slider/assets/transparent.png"
                alt="Slide"
                title="home-10"
                data-bg="c:linear-gradient(rgba(0,198,255,1) 0%,  var(--dark-color) 100%);"
                data-parallax="off"
                class="rev-slidebg"
                data-no-retina
              />

              <rs-layer
                id="slider-11-slide-11-layer-0"
                class="rs-pxl-2"
                data-type="image"
                data-rsp_ch="on"
                data-xy="x:r,c,c,c;xo:-190px,1px,1px,0;y:m,b,b,b;yo:-20px,0,0,0;"
                data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
                data-dim="w:825px,576px,487px,393px;h:631px,408px,303px,244px;"
                data-frame_0="sX:0.9;sY:0.9;"
                data-frame_1="st:1600;sp:1000;"
                data-frame_999="o:0;st:w;"
                style="z-index: 13"
                ><img src="images/about-us/Group-184.svg" alt="ihosting_home" data-no-retina />
              </rs-layer>

              <!-- <rs-layer
                id="slider-11-slide-11-layer-1"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:l,c,c,c;xo:16px,0,0,0;y:m,t,t,t;yo:20px,150px,113px,69px;"
                data-text="w:normal;s:18,16,0,4;l:34,32,0,6;a:left,center,center,center;"
                data-vbility="t,f,f,f"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                style="z-index: 9; font-family: Jost"
                v-html="titles.subtitulo2"
              >
              </rs-layer> -->

              <rs-layer
                id="slider-11-slide-11-layer-2"
                class="banner-text"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:l,c,c,c;xo:-10px,0,0,0;y:m,t,t,t;yo:-70px,40px,40px,30px;"
                data-text="w:normal;s:55,47,42,34;l:55,53,50,49;fw:600;a:left,center,center,center;"
                data-dim="w:634px,690px,589px,440px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:500;"
                data-frame_999="o:0;st:w;"
                style="z-index: 14; font-family: Jost; padding: 20px; "
              >

              ¡Conoce los Canales de Soporte que tenemos disponibles para ti! 

              <button class="btnShodow btnShodow_principal" style="margin: 30px 0px; ">
              <a class="btnShodow_link" href="/nosotros#contacto">
               Contáctanos
              </a>
            </button>
                <!-- <div class="animatype sub-title-home pt-0 pt-xl-2">
                  {{ titles.subtitulo }} &nbsp;<span id="typed2"></span>
                </div> -->
                <!-- <p style="font-size: 18px; margin: 40px 2px 10px;">Conoce todos nuestros canales de Soporte</p> -->
               <!-- <div class="wrapper">
                  <div class="icon facebook">
                      <div class="tooltip">
                        Facebook
                      </div>
                      <span><i class="fab fa-facebook-f"></i></span>
                  </div>
                  <div class="icon twitter">
                      <div class="tooltip">
                        Twitter
                      </div>
                      <span><i class="fab fa-twitter"></i></span>
                  </div>
                  <div class="icon instagram">
                      <div class="tooltip">
                        Instagram
                      </div>
                      <span><i class="fab fa-instagram"></i></span>
                  </div>
                  <div class="icon github">
                      <div class="tooltip">
                        Github
                      </div>
                      <span><i class="fab fa-github"></i></span>
                  </div>
                  <div class="icon youtube">
                      <div class="tooltip">
                        YouTube
                      </div>
                      <span><i class="fab fa-youtube"></i></span>
                  </div>
               </div> -->
              </rs-layer>

              <rs-layer
                id="slider-11-slide-11-layer-3"
                class="pt-hs-img"
                data-type="image"
                data-rsp_ch="on"
                data-xy="xo:50px,39px,29px,17px;yo:50px,39px,29px,17px;"
                data-text="w:normal;s:20,15,11,6;l:0,19,14,8;"
                data-dim="w:1920px,1512px,1148px,708px;h:1080px,850px,645px,397px;"
                data-frame_999="o:0;st:w;"
                style="z-index: 7"
                ><img src="images/polygon/Polygon-Luminary-2.svg" alt="ihosting_background" data-no-retina />
              </rs-layer>

              <!-- <a
                id="slider-11-slide-11-layer-7"
                class="rs-layer rev-btn btn_home_section"
                :href="btn[0].url"
                data-type="button"
                data-color="var(--primary-color)"
                data-rsp_ch="on"
                data-xy="x:l,c,c,c;xo:16px,0px,0px,0px;y:m,t,m,m;yo:110px,180px,-90px,-60px;"
                data-text="w:normal;s:18,16,16,14;l:32,32,32,30;"
                data-dim="minh:0px,none,none,none;"
                data-padding="t:12,9,10,8;r:30,24,30,25;b:12,9,10,8;l:30,24,30,25;"
                data-border="bor:5px,5px,5px,5px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                data-frame_hover="c:#fff;bgc: var(--primary-color) ;bor:5px,5px,5px,5px;"
                style="z-index: 13; background-color: #ffffff; font-family: Jost;  "
                >{{ btn[0].titulo }}</a
              >            -->
            </rs-slide>
          </rs-slides>
        </rs-module>
      </rs-module-wrap>
    </div>
    <!-- Revolution Slider -->

    <!-- boxes -->
    <section class="boxFloating">
      <div class="boxFloating_container" >
        <div class="boxFloating_container_box">
          <div class="boxFloating_container_box_header">
            <img data-v-1f8323f4="" src="images/icons/login.svg" alt="soporte" class="img-icon-tab" style="margin: auto; max-width: fit-content !important;">
            <h4>Portal Cliente</h4>
          </div>        
          <div class="boxFloating_container_box_footer">
            <p>Todo lo que necesitas, en un solo lugar.</p>
            <button class="btnShodow" >
              <a class="btnShodow_link" :href="enlace">
                Login
              </a>
            </button>
          </div>  
        </div>
        <div class="boxFloating_container_box">
          <div class="boxFloating_container_box_header">
            <img data-v-1f8323f4="" src="images/icons/pay.svg" alt="soporte" class="img-icon-tab" style="margin: auto; max-width: fit-content !important;">
            <h4>Pago rápido</h4>
          </div>        
          <div class="boxFloating_container_box_footer">
            <p>Paga tu servicio, en un click.</p>
            <button class="btnShodow" >
              <a class="btnShodow_link" href="/pago-rapido">
                Pagar
              </a>
            </button>
          </div>  
        </div>
        <div class="boxFloating_container_box">
          <div class="boxFloating_container_box_header">
            <img data-v-1f8323f4="" src="images/icons/support.svg" alt="soporte" class="img-icon-tab" style="margin: auto; max-width: fit-content !important;">
            <h4>Ticket Soporte</h4>
          </div>        
          <div class="boxFloating_container_box_footer">
            <p>¿Necesitas ayuda?, ¡estamos para ti!</p>
            <button class="btnShodow" >
              <a class="btnShodow_link" href="/nosotros#contacto">
                Abrir ticket
              </a>
            </button>
          </div>  
        </div>
      </div>
    </section>



    <!-- Pricing Table  start -->
    <div v-if="getData" class="container" style="margin-top: -130px; z-index: 10; position: relative">
      <section class="section-cards-home">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-md-6 px-0 px-lg-3 cardPlan_body">
              <div class="pt-pricebox-1 pc-fbox-home text-center mt-0 shadow-card">
                <div class="contbadage">
                    <div class="badge plans bg-blue">Prueba 5 días gratis</div>
                  </div>
                <div class="pt-pricebox-info">
                  <span class="pt-price-title">{{ services[0].nombre }}</span
                  ><br />
                  <span class="pt-price-duration fromer"
                    ><em>{{ services[0].categoria }}</em></span
                  >
                  <h2 class="price">{{ services[0].precio }}</h2>
                  <p class="pt-price-duration">{{ services[0].moneda }} / {{ services[0].ciclo }}</p>
                </div>
                <ul class="pt-list-info pt-list-info-padding">
                  <li v-for="(atribute, idx) in services[0].caracteristicas" :key="idx" class="active">
                    <div style="flex-flow: row nowrap;">
                      <span style="margin-right: 25px">
                        <div style="display: flex; flex-flow: row nowrap"></div>
                        <span>{{ atribute.nombre.toUpperCase() }} </span>
                      </span>

                      <span style="float: right"
                        ><span v-if="atribute.valor == '∞'" class="span-infinito-cart">∞</span>
                        <span v-else>{{ atribute.valor.toUpperCase() }}</span>
                      </span>
                    </div>
                  </li>
                </ul>
                <div class="pt-btn-container">
                  <div class="pt-button-block">
                    <a class="pt-button" :href="services[0].btn.url">
                      <span class="text">{{ services[0].btn.titulo }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 px-0 px-lg-3 cardPlan_body">
              <div
                class="pt-pricebox-1 pc-fbox-home text-center active mt-md-0 shadow-card only-d"
                style="z-index: 9"
              >
                <div class="pt-pricebox-info">
                  <div class="contbadage">
                    <div class="badge plans bg-white">Prueba 5 días gratis</div>
                  </div>
                  <span class="pt-price-title">{{ services[1].nombre }}</span
                  ><br />
                  <span class="pt-price-duration fromer"
                    ><em>{{ services[1].categoria }}</em></span
                  >
                  <h2 class="price">{{ services[1].precio }}</h2>
                  <p class="pt-price-duration">{{ services[1].moneda }} / {{ services[1].ciclo }}</p>
                </div>
                <ul class="pt-list-info pt-list-info-padding">
                  <li v-for="(atribute, idx) in services[1].caracteristicas" :key="idx" class="active">
                    <div style="flex-flow: row nowrap">
                      <span style="margin-right: 25px">
                        <div style="display: flex; flex-flow: row nowrap"></div>
                        <span>{{ atribute.nombre.toUpperCase() }} </span>
                      </span>

                      <span style="float: right"
                        ><span v-if="atribute.valor == '∞'" class="span-infinito-cart">∞</span>
                        <span v-else>{{ atribute.valor.toUpperCase() }}</span>
                      </span>
                    </div>
                  </li>
                </ul>
                <div class="pt-btn-container">
                  <div class="pt-button-block">
                    <a class="pt-button pt-button-change-color" :href="services[1].btn.url">
                      <span class="text">{{ services[1].btn.titulo }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-12 px-0 px-lg-3 cardPlan_body">
              <div class="pt-pricebox-1 pc-fbox-home text-center shadow-card">
                <div class="pt-pricebox-info">
                  <div class="contbadage">
                    <div style="height: 23.25px"></div>
                    <!-- <div class="badge plans bg-blue">Prueba 5 días gratis</div> -->
                  </div>

                  <span class="pt-price-title">{{ services[2].nombre }}</span
                  ><br />
                  <span class="pt-price-duration fromer"
                    ><em>{{ services[2].categoria }}</em></span
                  >

                  <h2 class="price">{{ services[2].precio_clp }}</h2>
                  <p class="pt-price-duration">
                    <span>{{ services[2].precio }}</span>
                    {{ services[2].moneda }} / {{ services[2].ciclo }}
                  </p>
                </div>

                <ul class="pt-list-info pt-list-info-padding">
                  <li v-for="(atribute, idx) in services[2].caracteristicas" :key="idx" class="active">
                    <div style="flex-flow: row nowrap">
                      <span style="margin-right: 25px">
                        <div style="display: flex; flex-flow: row nowrap"></div>
                        <span>{{ atribute.nombre.toUpperCase() }} </span>
                      </span>

                      <span style="float: right"
                        ><span v-if="atribute.valor == '∞'" class="span-infinito-cart">∞</span>
                        <span v-else>{{ atribute.valor.toUpperCase() }}</span>
                      </span>
                    </div>
                  </li>
                </ul>

                <div class="pt-btn-container">
                  <div class="pt-button-block">
                    <a class="pt-button" :href="services[2].btn.url">
                      <span class="text">{{ services[2].btn.titulo }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Pricing Table end -->

    <!-- best-features start -->
    <section style="padding: 100px 0px">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">¿Por Qué <span class="imp-word">Elegir</span> ihosting?</h2>
              <p>
                Somos una empresa que cuenta con más de 21 años en el mercado enfocada en prestar soluciones
                de infraestructura e ingeniería TI robustas, brindando seguridad, una experiencia de soporte
                personalizada y disponible para atender a sus requerimientos en el menor tiempo posible.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div
              class="wow animate__animated animate__fadeIn pt-icon-box mt-0 shadow-card"
              style="min-height: 332px"
            >
              <div class="pt-icon-box-media">
                <i class="cont-img-icon-tab"
                  ><img
                    src="images/icons/support.svg"
                    class="img-icon-tab"
                    alt="soporte"
                    style="margin: auto; max-width: fit-content !important"
                /></i>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-heading">Soporte</h4>
                <p class="pt-icon-box-description">
                  En cuanto lo requieras atenderemos rápidamente tus inquietudes.
                </p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6">
            <div
              class="wow animate__animated animate__fadeIn pt-icon-box shadow-card"
              style="min-height: 332px"
            >
              <!-- priority -->
              <div class="pt-icon-box-media">
                <i class="cont-img-icon-tab"
                  ><img
                    src="images/icons/prioritize.svg"
                    class="img-icon-tab"
                    style="margin: auto; max-width: fit-content !important"
                /></i>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-heading">Prioridad</h4>
                <p class="pt-icon-box-description">
                  La solución a los problemas de nuestros clientes son nuestra máxima prioridad.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div
              class="wow animate__animated animate__fadeIn pt-icon-box shadow-card"
              style="min-height: 332px"
            >
              <div class="pt-icon-box-media">
                <i class="cont-img-icon-tab"
                  ><img
                    src="images/icons/book.svg"
                    class="img-icon-tab"
                    style="margin: auto; max-width: fit-content !important"
                /></i>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-heading">Conocimiento</h4>
                <p class="pt-icon-box-description">
                  Un equipo altamente calificado y dispuesto a resolver sus dudas y requerimientos.
                </p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6">
            <div
              class="wow animate__animated animate__fadeIn pt-icon-box mt-md-0 shadow-card"
              style="min-height: 332px"
            >
              <div class="pt-icon-box-media">
                <i class="cont-img-icon-tab"
                  ><img
                    src="images/icons/experience.svg"
                    class="img-icon-tab"
                    style="margin: auto; max-width: fit-content !important"
                /></i>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-heading">Experiencia</h4>
                <p class="pt-icon-box-description">
                  Llevamos más de 21 años en el mercado y más de 4.000 clientes confían en nosotros.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- best-features end -->

      <!-- Offer Section Start -->
    <!-- <section v-if="getData && resolucion2()" class="pt-blue-background">
      <div
        class="pt-background-overley pt-opacity-1"
        style="background-image: url(images/polygon/Polygon-Luminary.svg)"
      ></div>
      <div class="container row no-gutters" style="margin: auto">
        <div class="col-xl-3 col-md-6 mt-0">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[0].icono">+</span></span>
              <span
                class="pt-counter-number"
                data-duration="2000"
                :data-to-value="counters[0].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[0].valor }}</span
              >
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[0].titulo }}</div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mt-0">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[1].icono">+</span></span>
              <span
                class="pt-counter-number"
                data-duration="2000"
                :data-to-value="counters[1].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[1].valor }}</span
              ><span>K</span>
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[1].titulo }}</div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[2].icono">+</span></span>
              <span
                class="pt-counter-number"
                data-duration="2000"
                :data-to-value="counters[2].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[2].valor }}</span
              >
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[2].titulo }}</div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[3].icono">+</span></span>
              <span
                class="pt-counter-number"
                data-duration="2000"
                :data-to-value="counters[3].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[3].valor }}</span
              ><span class="ml-2"> Gbps</span>
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[3].titulo }}</div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="sliderImages_section" >
      <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center" style="margin-bottom: 10px !important;">
              <h2 class="pt-section-title">Somos <span class="imp-word">partners</span></h2>
            </div>
          </div>
        </div>
      <SliderImages/>
    </section>
    <!-- Offer Section end -->

    <!-- Offer Section Start -->
    <!-- <section v-if="getData && !resolucion2()" class="pt-blue-background">
      <div
        class="pt-background-overley pt-opacity-1"
        style="background-image: url(images/polygon/Polygon-Luminary.svg)"
      ></div>
      <div class="container row no-gutters" style="margin: auto">
        <div class="col-xl-3 col-md-6 mt-0">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[0].icono">+</span></span>
              <span
                class="pt-counter-number1"
                data-duration="2000"
                :data-to-value="counters[0].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[0].valor }}</span
              >
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[0].titulo }}</div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mt-0">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[1].icono">+</span></span>
              <span
                class="pt-counter-number1"
                data-duration="2000"
                :data-to-value="counters[1].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[1].valor }}</span
              ><span>K</span>
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[1].titulo }}</div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[2].icono">+</span></span>
              <span
                class="pt-counter-number1"
                data-duration="2000"
                :data-to-value="counters[2].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[2].valor }}</span
              >
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[2].titulo }}</div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="pt-counter">
            <div class="pt-counter-number-wrapper">
              <span class="pt-counter-number-prefix"><span v-if="counters[3].icono">+</span></span>
              <span
                class="pt-counter-number1"
                data-duration="2000"
                :data-to-value="counters[3].valor"
                data-from-value="0"
                data-delimiter=","
                >{{ counters[3].valor }}</span
              ><span class="ml-2"> Gbps</span>
              <span class="pt-counter-number-suffix"></span>
            </div>
            <div class="pt-counter-title">{{ counters[3].titulo }}</div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- Offer Section end -->

    <!-- Benefits Section start -->
    <section style="padding-bottom: 60px; padding-top: 70px" class="section-center-text">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-6">
            <div
              class="pt-icon-box-style6 mt-4 shadow-card"
              style=" min-height: 352px; padding: 40px 30px; 10px 30px"
            >
              <div class="pt-icon-box-media">
                <span class="pt-icon-box-animation-">
                  <i aria-hidden="true" class="flaticon-002-cloud-computing"></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-title">
                  <span>Hosting</span>
                </h4>
                <p class="pt-icon-box-description">
                  ¿Tu sitio web o correos no funcionan adecuadamente y nadie te ayuda?. Siempre podrás
                  resolver tus dudas contando con la confianza de que siempre existirá alguien para orientarte
                  e ir creciendo conforme a tus necesidades de negocio.
                </p>
                <div class="pt-btn-container mt-3">
                  <div class="pt-button-block">
                    <a class="pt-button" href="/hosting#hosting">
                      <span class="text">Conoce más</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-6">
            <div
              class="pt-icon-box-style6 mt-4 shadow-card"
              style=" min-height: 352px;   padding: 40px 30px; 10px 30px"
            >
              <div class="pt-icon-box-media">
                <span class="pt-icon-box-animation-">
                  <i class="cont-img-icon-tab"
                    ><img
                      src="images/icons/infrastructure.svg"
                      class="img-icon-tab"
                      alt="infraestructura"
                      style="margin: auto; max-width: inherit !important" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-title">
                  <span>Azure</span>
                </h4>
                <p class="pt-icon-box-description">
                  ¿Estás pensando en migrar un proyecto en la nube y te preocupan los costos?, ¿Tienes un
                  evento en línea con miles de auditores?. Te ayudaremos en todo el proceso evaluando la mejor
                  alternativa y costos para tu infraestructura en la nube.
                </p>
                <div class="pt-btn-container mt-3">
                  <div class="pt-button-block">
                    <a class="pt-button" href="/servicios#azure">
                      <span class="text">Conoce más</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-6">
            <div
              class="pt-icon-box-style6 mt-4 shadow-card"
              style="  min-height: 352px;  padding: 40px 30px; 10px 30px"
            >
              <div class="pt-icon-box-media">
                <span class="pt-icon-box-animation-">
                  <i class="cont-img-icon-tab"
                    ><img
                      src="images/icons/cloud.svg"
                      alt="administración"
                      class="img-icon-tab"
                      style="margin: auto; max-width: inherit !important" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-title">
                  <span>Administración de Infraestructura</span>
                </h4>
                <p class="pt-icon-box-description">
                  ¿Destinas tiempo importante para que tus servicios se mantengan operativos?, puedes dejar en
                  manos de profesionales con experiencia la seguridad y administración de tus servidores o
                  entorno nube olvidándote de complejas configuraciones, enfocandote en tu negocio.
                </p>
                <div class="pt-btn-container mt-3">
                  <div class="pt-button-block">
                    <a class="pt-button" href="/ingenieria#administracion-infraestructura">
                      <span class="text">Conoce más</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-6">
            <div
              class="pt-icon-box-style6 mt-4 shadow-card"
              style="  min-height: 352px;  padding: 40px 30px; 10px 30px"
            >
              <div class="pt-icon-box-media">
                <span class="pt-icon-box-animation-">
                  <i class="cont-img-icon-tab"
                    ><img
                      src="images/icons/vpn.svg"
                      class="img-icon-tab"
                      alt="vpn"
                      style="margin: auto; max-width: fit-content !important" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <h4 class="pt-icon-box-title">
                  <span>VPN</span>
                </h4>
                <p class="pt-icon-box-description">
                  ¿Sabes si tus usuarios se están conectando de manera remota de forma segura a sus sistemas o
                  aplicaciones de trabajo?. Podemos orientarte y buscar la mejor solución para que tus
                  colaboradores trabajen en un entorno seguro a través de un túnel privado virtual hacia sus
                  servidores o aplicativos de negocio.
                </p>
                <div class="pt-btn-container mt-3">
                  <div class="pt-button-block">
                    <a class="pt-button" href="/conectividad#vpn">
                      <span class="text">Conoce más</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Benefits Section End -->

    <!-- Award Slider start -->
    <section style="padding: 40px 0px 80px 0px" class="boxshadowcustom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">Han <span class="imp-word">confiado </span>en nosotros</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="pt-award">
              <div
                class="swiper-container s1 swiper-container-initialized swiper-container-horizontal"
                data-nav="false"
                data-pagination_type="bullets"
                data-desk_num="5"
                data-lap_num="3"
                data-tab_num="2"
                data-mob_num="1"
                data-mob_sm="1"
                data-autoplay="true"
                data-loop="true"
                data-margin="60"
              >
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp1.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp3.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp5.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp6.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp7.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp16.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp12.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp14.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div>
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/nosotros/emp15.png"
                          alt="pt-award-img"
                          style="height: 180px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="swiper-pagination swiper-pagination-dynamic"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Award Slider end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { revinit_revslider11, swiper_component, resolucion2, resolucion } from "@/helpers";
import SliderImages from "@/components/web/sliders/SliderImages.vue";

export default {
  components: {
    SliderImages
  },
  metaInfo: {
    title: "ihosting Chile: Hosting, Wordpress y VPS para Apoyar tu Empresa",
    htmlAttrs: {
      lang: "es",
      amp: true,
    },
    meta: [
      {
        name: "title",
        vmid: "title",
        content:
          "ihosting® es una empresa ubicada en la ciudad de Viña del Mar, Chile. Cuenta con más de 15 años de experiencia en la industria.",
      },
      {
        name: "description",
        vmid: "description",
        content: "21 años enfocados en el éxito de tu negocio brindando soporte excepcional en Chile, soluciones de hosting WordPress y VPS, comprometidos con la calidad del servicio. ¡Contáctanos y encontraremos tu plan ideal!",
      },
      { name: "keywords", content: "ihosting, hosting, vps, cloudvps, wordpress, ssl, hosting chile" },
    ],
  },
  data: () => ({
    enlace: process.env.VUE_APP_PORTAL_URL,

  }),
  computed: {
    ...mapGetters(["getData"]),
    services() {
      return this.getData?.web?.home;
    },
    titles() {
      return this.getData?.web?.slider;
    },
    btn() {
      return this.getData?.web?.slider.btn;
    },
    counters() {
      return this.getData?.web?.contadores;
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    this.initSlider();

    setTimeout(() => {
      this.swiper_component();
      var typed3 = new Typed("#typed2", {
        strings: this.titles.adicionales,
        typeSpeed: 50,
        backSpeed: 20,
        smartBackspace: true,
        loop: true,
      });
    }, 1200);
  },
  methods: {
    revinit_revslider11,
    swiper_component,
    resolucion2,
    resolucion,
    initSlider() {
      setTimeout(() => {
        if (this.getData) {
          this.revinit_revslider11();
        } else {
          this.initSlider();
        }
      }, 100);
    },
  },
};
</script>

<style scoped>
tspan {
  white-space: pre;
}
h4{
  font-size: 26px !important;
}
.shp0 {
  fill: #f5f9ff;
}

.shp1 {
  fill: var(--primary-color);
}

.shp2 {
  fill: var(--dark-color);
}

.pt-list-info div{
  display: flex;
    justify-content: space-between;
    width: 100%;
}

/* card price */
.pt-pricebox-1 {
  min-height: 780px;
}

/* boton price */
.pt-pricebox-1 .pt-btn-container {
    margin: 0 auto;
    position: absolute;
    bottom: 35px;
    right: 10px;
    left: 10px;
}

.sliderImages_section{
  padding: 50px 0px 0px;
}

.wrapper{
  display: inline-flex;
}
.wrapper .icon{
  margin: 0 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon span{
  display: block;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon span i{
  line-height: 60px;
  font-size: 25px;
}
.wrapper .icon .tooltip{
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  color: #fff;
  padding: 10px 18px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 25px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover .tooltip{
  top: -70px;
  opacity: 1;
  pointer-events: auto;
}
.icon .tooltip:before{
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover span{
  color: #fff;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip{
  text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
}
.wrapper .facebook:hover span,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip:before{
  background: #3B5999;
}
.wrapper .twitter:hover span,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip:before{
  background: #46C1F6;
}
.wrapper .instagram:hover span,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip:before{
  background: #e1306c;
}
.wrapper .github:hover span,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip:before{
  background: #333;
}
.wrapper .youtube:hover span,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip:before{
  background: #DE463B;
}

/* boxFloating */
.boxFloating{
    position: relative;
    top: -150px;
    padding: 30px 20px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    
}
.boxFloating_container{
  margin: 0 auto;
    position: relative;
    max-width: 1400px;
    height: 240px;
    display: flex;
    justify-content: space-around;
    box-shadow: 1px 5px 13px 5px rgba(0, 0, 0, 0.04);
    z-index: 10;
    border-radius: 10px;
    backdrop-filter: saturate(180%) blur(20px);
    background: rgb(251 251 253 / 96%);
}

@media (max-width: 900px){
  .boxFloating_container{
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
  .boxFloating_container_box{
    margin: 20px 0px;
  }
  .boxFloating{
    margin-bottom: 0px;
  }
}
.boxFloating_container_box{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.boxFloating_container_box_header img{
  margin-bottom: 12px;
}
.boxFloating_container_box_footer{
  display: flex;
    flex-direction: column;
    align-items: center;

}

.boxFloating_container_box_footer p{
  margin-bottom: 10px
}
/* button */
.btnShodow{
    width: 140px;
    display: flex;
    align-items: center;
    color: var(--white-color);
    font-family: var(--title-fonts);
    background: var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    line-height: 2;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
    transform: scale(1);
    padding: 8px 10px !important;
    text-align: center;
    justify-content: center;
    margin: 0;
}
.btnShodow:hover{
    transform: scale(1.05) !important;
    color: var(--white-color) !important;

}



.btnShodow_link{
  color:  var(--white-color);

}
/* text atributos price */
.pt-pricebox-1 .pt-list-info li {
  font-size: 13px;
}
@media(max-width: 1350px){
  .banner-text{
    margin: 10px 30px;
  }
}
@media(max-width: 1024px){
  .sub-title-home{
    text-align: center !important;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    margin-top: 20px;
  }
  .sub-title-home span{
    margin-left: 8px !important;
  }
  .btn_home_section{
    margin-top: 30px !important;
  }

  
  .btnShodow_principal{
    margin: 15px auto !important;
}

}
@media(max-width: 1200px){
  .cardPlan_body{
    max-width: 420px !important;
    height: 790px !important;
    margin: 10px auto;

  }
  .cardPlan_body .shadow-card{
    transform: scale(1) !important;
    margin-top: 0px !important;
  }
}
@media(max-width:700px){
  .sub-title-home{
    font-size: 20px !important;
    font-weight: 600;
  }
}
@media(max-width: 600px){


  .cardPlan_body{
    max-width: 318px !important;
  }
  }

  a{
    text-shadow:0.3px 0.3px #0000003b;
    font-weight: 600;
  }

</style>
