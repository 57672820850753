<template>
  <div id="nosotros" class="overflow-custom-v">
    <!-- Domain-Form Start -->
    <section
      class="pt-domain-form-section pt-blue-background"
      style="background-color: rgba(0, 0, 0, 0.7); padding: 72px 0px 86px"
    >
      <div
        class="pt-background-overley pt-opacity-1"
        style="
          background-image: url(images/polygon/Polygon-Luminary.svg);
          background-color: rgba(0, 0, 0, 0.7);
        "
      ></div>
      <div v-if="!resolucion2()" class="covervid-wrapper" style="overflow: hidden">
        <video class="covervid-video" autoplay loop muted playsinline>
          <source src="videos/about.mp4" type="video/mp4" />
        </video>
      </div>
      <!-- <div class="container"> -->
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="row">
            <div class="col-12">
              <div class="title-nosotros-w" style="">
                <h2
                  class="pt-section-title"
                  style="color: white; font-size: 54px; margin-bottom: 0px; padding: 20px; font-size: 59px"
                >
                  Nosotros
                </h2>
                <p class="pt-section-description" style="color: white; padding: 20px; font-size: 18px">
                  Somos una empresa que cuenta con más de 21 años en el mercado enfocada en prestar soluciones
                  de infraestructura e ingeniería TI robustas, brindando seguridad, una experiencia de soporte
                  personalizada y siempre disponible para atender a sus requerimientos en el menor tiempo
                  posible.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="pt-breadcrumb-img text-right">
            <img src="images/patterns/domains.svg" class="svg" alt="seo-image" />
          </div>
        </div>
      </div>
    </section>
    <!-- Domain-Form End -->

    <div style="background-color: white; background: var(--grey-color)">
      <div
        class="diagonal-banner2"
        style="position: relative; background-color: white; top: -30px; background: var(--grey-color)"
      ></div>
    </div>

    <!-- Benefits Start -->
    <section class="pt-grey-background" style="margin-top: -30px; padding: 0px 0px 130px 0px">
      <div style="position: absolute; bottom: 30px; overflow: hidden; right: 20px">
        <img src="images/isotipo.png" style="opacity: 0.1" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">¿Por qué <span class="imp-word">elegirnos</span>?</h2>
              <p class="pt-section-description">
                Para que obtengas los mejores resultados con un equipo humano con años de experiencia en el
                mercado y que estará dispuesto a ayudar cuando lo requieras.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-md-6">
            <div class="pt-icon-box-style7 mt-md-0" style="min-height: 185px">
              <div class="pt-icon-box-icon icon-resp-n">
                <span class="pt-icon-animation">
                  <i class="cont-img-icon-tab"
                    ><img
                      width="60px" height="60px"
                      src="images/icons/experience.svg"
                      class="img-icon-tab"
                      style="margin: auto; max-width: fit-content" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <p class="pt-icon-box-description">
                  21 años de experiencia entregando tranquilidad, confianza y seguridad con miles de clientes
                  satisfechos
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mt-4 mt-md-0">
            <div class="pt-icon-box-style7 mt-md-0" style="min-height: 185px">
              <div class="pt-icon-box-icon icon-resp-n">
                <span class="pt-icon-animation">
                  <i class="cont-img-icon-tab"
                    ><img
                      width="60px" height="60px"
                      src="images/icons/snap.svg"
                      class="img-icon-tab"
                      style="margin: auto; max-width: fit-content" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <p class="pt-icon-box-description">Queremos hacer más simple la vida de nuestros clientes</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-12 mt-4 mt-xl-0">
            <div class="pt-icon-box-style7 mt-md-0" style="min-height: 185px">
              <div class="pt-icon-box-icon icon-resp-n">
                <span class="pt-icon-animation">
                  <i class="cont-img-icon-tab"
                    ><img
                      width="60px" height="60px"
                      src="images/icons/availability.svg"
                      class="img-icon-tab"
                      style="margin: auto; max-width: fit-content" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <p class="pt-icon-box-description">
                  Trabajamos contra el reloj para que tus servicios estén permanentemente disponibles
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-xl-4 col-md-6">
            <div class="pt-icon-box-style7 mt-md-0" style="min-height: 185px">
              <div class="pt-icon-box-icon icon-resp-n">
                <span class="pt-icon-animation">
                  <i class="cont-img-icon-tab"
                    ><img
                      width="60px" height="60px"
                      src="images/icons/professional.svg"
                      class="img-icon-tab"
                      style="margin: auto; max-width: fit-content" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <p class="pt-icon-box-description">
                  Un equipo de profesionales con amplia experiencia en soluciones nube, web y TI estarán
                  contigo para que logres tus objetivos
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mt-4 mt-md-0">
            <div class="pt-icon-box-style7 mt-md-0" style="min-height: 185px">
              <div class="pt-icon-box-icon icon-resp-n">
                <span class="pt-icon-animation">
                  <i class="cont-img-icon-tab"
                    ><img
                      width="60px" height="60px"
                      src="images/icons/team-support.svg"
                      class="img-icon-tab"
                      style="margin: auto; max-width: fit-content" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <p class="pt-icon-box-description">
                  Nos encantan los desafíos y trabajamos junto a nuestros clientes para que logren sus
                  objetivos
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-12 mt-4 mt-xl-0">
            <div class="pt-icon-box-style7 mt-md-0" style="min-height: 185px">
              <div class="pt-icon-box-icon icon-resp-n">
                <span class="pt-icon-animation">
                  <i class="cont-img-icon-tab"
                    ><img
                      width="60px" height="60px"
                      src="images/icons/network.svg"
                      class="img-icon-tab"
                      style="margin: auto; max-width: fit-content" /></i
                ></span>
              </div>
              <div class="pt-icon-box-content">
                <p class="pt-icon-box-description">
                  Queremos que la tecnología esté al alcance de todos y entregar lo mejor de la computación en
                  la nube
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Benefits End -->

    <!-- Service Details start (HOSTING BÁSICO) -->
    <section id="hosting-basico pb-0" style="padding-bottom: 40px">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-md-12">
            <div class="row">
              <div class="col-12">
                <div class="pt-section-title-1 text-center p-0">
                  <h2 class="pt-section-title"><span class="imp-word">Infraestructura</span></h2>
                  <p class="pt-section-description">
                    Nuestros datacenters se encuentran ubicados en Viña del Mar, Santiago de Chile, Miami (US)
                    sumado a la red global de +60 datacenters de microsoft Azure.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-md-12 mt-3 mt-xl-0">
            <div class="pt-img-main wow animate__animated animate__fadeInRight">
              <img src="images/patterns/efficiency.svg" class="svg img-fluid" alt="image1" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="pt-fancybox-box-info" style="text-align: center">
              <div class="pt-fancy-media"><i class="flaticon-032-home"></i></div>
              <h4 class="pt-fancy-title">Local</h4>

              <p class="pt-fancybox-description">
                Nuestra infraestructura se encuentra geo-redundada en alta disponibilidad a través de puntos
                de presencia ubicados en Viña del Mar, Santiago de Chile y Miami (US) permitiendo contar
                diferentes puntos de presencia y entregar solución a los proyectos más exigentes.
              </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="pt-fancybox-box-info" style="text-align: center">
              <div class="pt-fancy-media">
                <i class="cont-img-icon-tab"
                  ><img
                    width="60px" height="60px"
                    src="images/icons/global-network.svg"
                    class="img-icon-tab"
                    style="margin: auto; max-width: fit-content; height: 71px !important"
                /></i>
              </div>
              <h4 class="pt-fancy-title">Global</h4>

              <p class="pt-fancybox-description">
                La infraestructura de microsoft Azure posee más de 60 locaciones alrededor del mundo. Cuenta
                con más de 90 certificaciones de seguridad, privacidad y regulaciones en áreas claves como la
                salud, gobierno, finanzas, educación, etc. pudiendo abarcar todo tipo de soluciones en la
                nube.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Service Details end -->

    <!-- Service Slider Start -->

    <section class="pt-grey-background">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-fancy-box-slider pt-fancy-box-slider-1 slide-jq fadein">
              <div class="row">
                <div class="col-md-4">
                  <div class="pt-fancybox-box-info" style="text-align: center; min-height: 336px">
                    <h4 class="pt-fancy-title">Soporte</h4>
                    <div class="pt-fancy-media">
                      <i class="cont-img-icon-tab"
                        ><img
                          src="images/icons/support.svg"
                          class="img-icon-tab"
                          style="margin: auto; max-width: fit-content"
                      /></i>
                    </div>
                    <p class="pt-fancybox-description">
                      En cuanto lo requieras atenderemos rápidamente tus inquietudes.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="pt-fancybox-box-info" style="text-align: center; min-height: 336px">
                    <h4 class="pt-fancy-title">Prioridad</h4>
                    <div class="pt-fancy-media">
                      <i class="cont-img-icon-tab"
                        ><img
                          src="images/icons/prioritize.svg"
                          class="img-icon-tab"
                          style="margin: auto; max-width: fit-content !important"
                      /></i>
                    </div>
                    <p class="pt-fancybox-description">
                      La solución a los problemas de nuestros clientes son nuestra máxima prioridad.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="pt-fancybox-box-info" style="text-align: center; min-height: 336px">
                    <h4 class="pt-fancy-title">Conocimiento</h4>
                    <div class="pt-fancy-media">
                      <i class="cont-img-icon-tab"
                        ><img
                          src="images/icons/book.svg"
                          class="img-icon-tab"
                          style="margin: auto; max-width: fit-content !important"
                      /></i>
                    </div>
                    <p class="pt-fancybox-description">
                      Un equipo altamente calificado y dispuesto a resolver sus dudas y requerimientos.
                    </p>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Service Slider End -->

    <!-- MAPA -->
    <section class="services maping sec-normal">
      <div class="container">
        <div class="service-wrap">
          <div class="row">
            <div class="col-sm-12 text-left">
              <div class="pt-section-title-1 text-center">
                <h2 class="pt-section-title">Alcance Local y <span class="imp-word">Global</span></h2>
                <span class="section-subheading"
                  ><span class="golink"
                    >Múltiples regiones que superan las que ofrece cualquier otro proveedor de
                    infraestructura.</span
                  ></span
                >
              </div>
            </div>
            <div class="col-md-12 pt-5">
              <img width="1270px" height="770.1px" src="images/map.svg" alt="Load Balancing" style="width:100%; height:100%" />

              <span style="position: relative; top: 100px" id="pointer_map01"></span>
              <span
                v-tooltip="contentTooltip('Datacenter en Viña del mar')"
                class="datacenters vinadelmar"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure West US 1')"
                class="datacenters azure2"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Japan East')"
                class="datacenters azure3"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Japan West')"
                class="datacenters azure4"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure korea South')"
                class="datacenters azure5"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure China North 1')"
                class="datacenters azure6"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure China North 2')"
                class="datacenters azure7"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure China North 3')"
                class="datacenters azure8"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure China South 1')"
                class="datacenters azure9"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure China South 2')"
                class="datacenters azure10"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure West India')"
                class="datacenters azure11"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Central India')"
                class="datacenters azure12"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure South India')"
                class="datacenters azure13"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure West India')"
                class="datacenters azure14"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Malaysia West')"
                class="datacenters azure15"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure South Asia')"
                class="datacenters azure16"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Indonesia Central')"
                class="datacenters azure17"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Australia East')"
                class="datacenters azure18"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Azure West US 2')"
                class="datacenters azure19"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Australia South')"
                class="datacenters azure20"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure South Africa')"
                class="datacenters azure21"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Norway East')"
                class="datacenters azure22"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Sweden Central')"
                class="datacenters azure23"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure UK South')"
                class="datacenters azure24"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure North Europe')"
                class="datacenters azure25"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Spain Central')"
                class="datacenters azure26"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure itali North')"
                class="datacenters azure27"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Poland Central')"
                class="datacenters azure28"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Canada East')"
                class="datacenters azure29"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Canada Central')"
                class="datacenters azure30"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure East US 1')"
                class="datacenters azure31"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure East US 2')"
                class="datacenters azure32"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure East US Central')"
                class="datacenters azure33"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure East US 3')"
                class="datacenters azure34"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure South Central US')"
                class="datacenters azure35"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure West US 1')"
                class="datacenters azure36"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure West US 3')"
                class="datacenters azure37"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Mexíco Central')"
                class="datacenters azure38"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Chile Central')"
                class="datacenters azure39"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Brazil South')"
                class="datacenters azure40"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure New Zeland North')"
                class="datacenters azure41"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Australia Central')"
                class="datacenters azure42"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Qatar Central')"
                class="datacenters azure43"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure UAE North')"
                class="datacenters azure44"
              ></span>

              <span
                v-tooltip="contentTooltip('Datacenter Azure Grece Central')"
                class="datacenters azure45"
              ></span>

              <span v-tooltip="contentTooltip('Datacenter en Santiago')" class="datacenters santiago"></span>

              <span v-tooltip="contentTooltip('Datacenter en Miami')" class="datacenters miami"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Mapa end -->

    <!-- Standard Start -->
    <section style="min-height: 1000px" class="pt-grey-background">
      <div class="container">
        <div class="">
          <div class="pt-section-title-1 text-center">
            <h2 class="pt-section-title">Partners & <span class="imp-word">Clientes</span></h2>
            <p class="pt-section-description" id="escoge_tu_plan">
              Creemos en nuestros partners como en la colaboración mutua para lograr grandes objetivos y
              entregar soluciones a miles de clientes
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="pt-filters">
              <div class="filters pt-filter-button-group">
                <ul>
                  <li class="pt-filter-btn active" data-filter="*">Todo</li>
                  <li class="pt-filter-btn" data-filter=".8">Agencias</li>
                  <li class="pt-filter-btn" data-filter=".10">Empresas</li>
                  <!-- <li class="pt-filter-btn" data-filter=".7">Development / Media</li>
                  <li class="pt-filter-btn" data-filter=".11">Optimization</li> -->
                </ul>
              </div>
            </div>
            <div class="pt-grid" data-next_items="4" data-initial_items="8">
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 8">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp8.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 8">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp9.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 8">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp10.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>

              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 8">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp13.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp1.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <!-- <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img src="images/nosotros/emp2.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div> -->
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp3.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <!-- <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img src="images/nosotros/emp4.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div> -->
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp5.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp6.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp7.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp12.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp14.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp15.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img width="278px" height="278px" src="images/nosotros/emp16.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div>
              <!-- <div class="pt-grid-item pt-filter-items pt-col-3 pt-crop-img 10">
                <div class="pt-portfoliobox-2">
                  <div class="pt-portfolio-img">
                    <a>
                      <img src="images/nosotros/emp11.png" class="img-fluid" alt="portfolio-image" />
                    </a>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Standard End -->

    <!-- Offer start -->
    <section class="pt-blue-background">
      <div
        class="pt-background-overley pt-opacity-1"
        style="background-image: url(images/polygon/Polygon-Luminary.svg)"
      ></div>
      <div class="container">
        <div class="row mx-0 my-0">
          <div class="col-xl-8">
            <div class="pt-section-title-1 pt-section-title-1-color-white mb-0">
              <h2 class="pt-section-title text-white">La transformación digital hoy es una realidad.</h2>
              <p class="pt-section-description text-white mb-0">
                Contáctanos y generemos sinergias para crear experiencias digitales que te entreguen confianza
                y asombrarán a tus clientes.
              </p>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="pt-btn-container text-right pt-btn-position">
              <div class="pt-button-block">
                <a href="/nosotros#contacto" class="pt-button pt-button-change-color" style="cursor: pointer">
                  <span class="text">Contáctanos</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- offer end -->

    <!-- Get-In Touch Start class="pt-blue-background" -->
    <section class="pt-grey-background section_service">
      <div id="contacto" class="enclaje_section"></div>

      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-12">
            <div class="sticky-img">
              <img width="620px" height="423px" src="images/ilustraciones/006.png" alt="t-img" class="img-fluid" />
            </div>
          </div>
          <div class="col-xl-6 col-md-12 mt-3 mt-xl-0">
            <div class="pt-contact-form">
              <div class="row">
                <div class="col-12 p-0">
                  <div class="pt-section-title-1">
                    <h2 class="pt-section-title">Contáctanos</h2>
                    <p class="pt-section-description">
                      Para mas información, envianos un mensaje y te responderemos a la brevedad
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <form @submit.prevent="submit" class="quote-from">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group" style="margin-bottom: 4rem">
                        <input
                          v-model="form.nombre"
                          v-validate="'required'"
                          type="text"
                          name="nombre"
                          data-vv-as="nombre"
                          placeholder="Nombre"
                          class="form-control input-white-custom mb-0"
                          :class="{ 'vue-error': errors.first('nombre') }"
                          key="1_"
                          maxlength="30"
                        />
                        <FormError :error="errors.first('nombre')"> </FormError>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group" style="margin-bottom: 4rem">
                        <input
                          v-model="form.email"
                          v-validate="'required|email'"
                          type="text"
                          name="email"
                          data-vv-as="email"
                          placeholder="Email"
                          class="form-control input-white-custom mb-0"
                          :class="{ 'vue-error': errors.first('email') }"
                          key="2_"
                          maxlength="30"
                        />
                        <FormError :error="errors.first('email')"> </FormError>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group" style="margin-bottom: 4rem">
                        <input
                          v-model="form.telefono"
                          @keypress="onlyNumber"
                          type="text"
                          name="telefono"
                          data-vv-as="telefono"
                          placeholder="Teléfono"
                          class="form-control input-white-custom mb-0"
                          :class="{ 'vue-error': errors.first('telefono') }"
                          key="3_"
                          maxlength="13"
                        />
                        <FormError :error="errors.first('telefono')"> </FormError>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group" style="margin-bottom: 4rem">
                        <input
                          v-model="form.asunto"
                          v-validate="'required'"
                          type="text"
                          name="asunto"
                          data-vv-as="asunto"
                          placeholder="Asunto"
                          class="form-control input-white-custom mb-0"
                          :class="{ 'vue-error': errors.first('asunto') }"
                          key="4_"
                          maxlength="30"
                        />
                        <FormError :error="errors.first('asunto')"> </FormError>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <!-- SELECT SUBCATEGORÍAS  -->
                      <div class="form-group">
                        <select
                          v-model="form.motivo"
                          style="border: none; margin-bottom: 30px; background-color: white"
                          class="aparence-none-v"
                        >
                          <option
                            v-for="(item, idx) in motivos"
                            :key="idx"
                            :value="item.motivo"
                            :selected="idx == '0'"
                          >
                            {{ item.motivo }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          v-model="form.mensaje"
                          rows="17"
                          class="input-white-custom"
                          placeholder="Mensaje"
                          style="line-height: initial"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12 mb-4">
                      <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="token"> </vue-recaptcha>
                    </div>

                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <div class="pt-button-block">
                              <button
                                type="submit"
                                alt="enviar cuestion a ihosting" aria-label="enviar cuestion a ihosting"
                                class="btn btn-default-grad-purple-fill btn-resp-v mb-1 mt-3"
                                style=" min-width: 180px; padding: 5px 20px !important"
                                :class="[verify ? 'verify-class' : 'normal-class']"
                              >
                                <span v-if="!verify" class="spinner-span"
                                  >Enviar
                                  <i
                                    class="fas fa-arrow-alt-circle-right spinner-i"
                                    style="top: 9px"
                                  ></i
                                ></span>
                                <div
                                  v-else
                                  class="spinner-border text-secondary"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div v-if="send" class="alert alert-light alert-payment-v" role="alert">
                            Gracias por comunicarte con nosotros, nos contactaremos a la brevedad.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Get-In Touch End -->

    <!-- Award Slider start -->
    <section style="padding: 40px 0px 80px 0px" class="boxshadowcustom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-section-title-1 text-center">
              <h2 class="pt-section-title">Nuestros <span class="imp-word">Proveedores</span></h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="pt-award">
              <!-- Slider main container -->
              <div
                class="swiper-container s-component1 swiper-container-initialized swiper-container-horizontal"
                data-nav="false"
                data-pagination_type="bullets"
                data-desk_num="5"
                data-lap_num="3"
                data-tab_num="2"
                data-mob_num="1"
                data-mob_sm="1"
                data-autoplay="true"
                data-loop="true"
                data-margin="60"
              >
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                  <!-- Slides -->
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.parallels.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner1.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Odin</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.hpe.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner2.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Hewlett Packard</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="#">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner3.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Cisco</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://partnernoc.cpanel.net/res/7151/ihostingcl.htm">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner4.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">cPanel</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.fortinet.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner5.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Fortinet</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="#">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner6.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Cloudlinux</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.imunify360.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner7.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Imunify360</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.softacolous.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner8.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Softaculous</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.fujitsu.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner9.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Fujitsu</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="#">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner10.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">PIT Chile</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.cloudflare.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner11.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Cloudflare</span> -->
                      </div>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://azure.microsoft.com">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner13.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">Microsoft Azure</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="pt-awardbox-1">
                      <div href="http://www.lacnic.net">
                        <img
                          width="180px"
                          height="180px"
                          class="pt-award-img"
                          src="images/partners/partner14.png"
                          alt="pt-award-img"
                        />
                        <!-- <span class="pt-award-title">lacnic</span> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- If we need pagination -->
                <div class="swiper-pagination swiper-pagination-dynamic"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Award Slider end -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  swiper_component,
  changeColorSvg,
  masonry,
  contentTooltip,
  resolucion2,
  onlyNumber,
} from "@/helpers";
import FormError from "@/components/cart/FormError";

import VueRecaptcha from "vue-recaptcha";

export default {
  metaInfo: {
        title: "ihosting | Nosotros",
        htmlAttrs: {
          lang: "es",
          amp: true,
        },
        meta: [
          {
            name: "description",
            content: "Somos una empresa que cuenta con más de 21 años en el mercado enfocada en prestar soluciones de infraestructura e ingeniería TI robustas, brindando seguridad, una experiencia de soporte personalizada y siempre disponible para atender a sus requerimientos en el menor tiempo posible."
          },
          {
            name: "keywords",
            content: "ihosting, hosting, vps, cloudvps, wordpress, ssl, hosting chile",
          },
        ]
  },
  components: {
    FormError,
    VueRecaptcha,
  },
  data: () => ({
    verify: false,
    send: false,
     form: {
      nombre: "",
      email: "",
      telefono: "",
      asunto: "",
      motivo: "",
      mensaje: "",
      robot: false,
    },
    token: process.env.VUE_APP_API_RECAPTCHA_TOKEN,
  }),
  computed: {
    ...mapGetters(["getData"]),
    motivos() {
      return this.getData?.web.motivos;
    },
  },
  watch: {
    "form.motivo"(val) {
      let index = this.motivos.findIndex((item) => item.motivo == val);
      this.form.mensaje = this.motivos[index].plantilla;
    },
  },
  mounted() {
    this.changeColorSvg();

    setTimeout(() => {
      this.masonry();
      this.swiper_component();
    }, 350);

    setTimeout(() => {
      this.form.motivo = this.getData?.web.motivos[0].motivo;
    }, 200);
  },
  methods: {
    ...mapActions(["sendMessage"]),
    swiper_component,
    changeColorSvg,
    masonry,
    contentTooltip,
    resolucion2,
    onlyNumber,
    submit() {

      this.$Progress.start();

      this.$validator.validate().then((esValido) => {
        if (esValido) {
          if (this.form.robot) {
            this.verify = true;
            this.sendMessage(this.form).then((res) => {
              if (res.data?.status == "success") {
                  this.$toast.success("Mensaje enviado con exito! te contactaremos a la brevedad.");
                  this.$Progress.finish();
                  this.send = true;
                  this.clearForm();
                  this.resetRecaptcha();
              }else if(res.data?.status == "error"){
                this.$toast.error(res.data.message);
                this.$Progress.fail();
                this.resetRecaptcha();
              }else {
                for (const [index, value] of Object.entries(res)) {
                  this.$validator.errors.add({
                    field: index,
                    msg: value[0],
                  });
                }

                this.$toast.error("Error en los campos ingresados");
                this.$Progress.fail();
              }

              this.verify = false;
            });
          } else {
            this.$toast.error("Error de reCaptcha.");
            this.$Progress.fail();
          }
        } else {
          this.$toast.error("Error al intentar enviar el mensaje.");
          this.$Progress.fail();
        }
      });
    },
    clearForm: function(){
      this.form.nombre = "";
      this.form.email = "";
      this.form.telefono = "";
      this.form.asunto = "";
      this.form.robot = false;

      let index = this.motivos.findIndex((item) => item.motivo == this.form.motivo);
      this.form.mensaje = this.motivos[index].plantilla;

      this.$nextTick(() => {
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      });
    },
    onVerify: function (response) {
      if (response){
        this.form.robot = true;
        this.form['g-recaptcha-response'] = response;
      } 
    },
    resetRecaptcha() {
      this.verify = false;
      this.robot = false;
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  },
};
</script>
