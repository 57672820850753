import Vue from 'vue'
import VueRouter from 'vue-router'
// WEB
import Home from '../views/web/Home.vue'

import Hosting from '../views/web/Hosting.vue'
import Ingenieria from '../views/web/Ingenieria.vue'
import Conectividad from '../views/web/Conectividad.vue'
import Nosotros from '../views/web/Nosotros.vue'
import PagoRapido from '../views/web/PagoRapido.vue'
import Registro from '../views/web/Cuenta.vue'

// SERVICIOS 
// import Servicios from '../views/web/Servicios.vue'
import VpsSSD from '../views/web/Servicios/vpsSSD.vue';
import AzureIhosting from '../views/web/Servicios/AzureIhosting.vue';
import Hostings from '../views/web/Servicios/Hostings.vue'
import cLocation from '../views/web/Servicios/cLocation.vue'
import microsoft from '../views/web/Servicios/microsoft.vue'
import vpsSSDCloud from '../views/web/Servicios/cloudVps.vue'
import MicrosoftDefender from '../views/web/Servicios/MicrosoftDefender.vue'



// CART
import Cart from '../views/cart/Cart.vue'
import Resumen from '../views/cart/Resumen/Resumen.vue'

Vue.use(VueRouter)
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true
})

const routes = [

  // WEB
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/hostings',
  //   name: 'Hostings',
  //   component: Hosting
  //   // component: () => import(/* webpackChunkName: "hosting" */ '../views/web/Hosting.vue')
  // },
  {
    path: '/Hostings',
    name: 'Hostings',
    component: Hostings
    // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  },
  // {
  //   path: '/servicios',
  //   name: 'Servicios',
  //   component: Servicios
  //   // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  // },
  // servicios:
  {
    path: '/vpsSSD',
    name: 'vpsSSD',
    component: VpsSSD
    // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  },
  {
    path: '/vpsSSDCloud',
    name: 'vpsSSDCloud',
    component: vpsSSDCloud
    // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  },
  {
    path: '/AzureIhosting',
    name: 'AzureIhosting',
    component: AzureIhosting
    // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  },
  {
    path: '/cLocation',
    name: 'cLocation',
    component: cLocation
    // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  },

  {
    path: '/microsoft',
    name: 'microsoft',
    component: microsoft
    // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  },
  {
    path: '/microsoftDefender',
    name: 'microsoftDefender',
    component: MicrosoftDefender
    // component: () => import(/* webpackChunkName: "servicios" */ '../views/web/Servicios.vue')
  },

  {
    path: '/ingenieria',
    name: 'Ingenieria',
    component: Ingenieria
    // component: () => import(/* webpackChunkName: "ingenieria" */ '../views/web/Ingenieria.vue')
  },
  {
    path: '/conectividad',
    name: 'Conectividad',
    component: Conectividad
    // component: () => import(/* webpackChunkName: "conectividad" */ '../views/web/Conectividad.vue')
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: Nosotros
    // component: () => import(/* webpackChunkName: "nosotros" */ '../views/web/Nosotros.vue')
  },
  {
    path: '/pago-rapido/:id?',
    name: 'PagoRapido',
    component: PagoRapido
    // component: () => import(/* webpackChunkName: "nosotros" */ '../views/web/Nosotros.vue')
  },
  {
    path: '/registro',
    name: 'Registro',
    component: Registro
    // component: () => import(/* webpackChunkName: "nosotros" */ '../views/web/Nosotros.vue')
  },

  // CARRITO
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    props: true
  },
  {
    path: '/resumen_compra/:params?',
    name: 'Resumen',
    component: Resumen,
    props: true
  },


  //REDIRECT
  {
    path: '/manuales/:file',
    name: "manuales"
  },
  {
    path: '/downloads/:file',
    name: "downloads"
  },
  {
    path: '/imagenfirma/:file',
    name: "imagenfirma"
  },
  { path: "/sitemap.xml", name: 'sitemap' },
  { path: "*", name: 'redirect' }
]
const headers = ["#home", "#hostings", "#servicios", "#ingenieria", "#conectividad", "#nosotros"]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      if (headers.includes(to.hash)) {
        return { x: 0, y: 0 };
      } else {
        setTimeout(() => {
          selector: $('html,body').stop().animate({ scrollTop: $(to.hash).offset().top - 64 }, 0)
        }, 740);
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  
  if(to.name == 'redirect'){
    window.location.replace("/");
  }

  if(to.params.file && (to.name == 'manuales' || to.name == 'downloads' || to.nmae == 'imagenfirma')){
    window.location.replace("https://files.ihosting.cl" + to.path);
  }
   
    $("#pt-loading").show();
    next()
  
  
})
router.afterEach(() => {

    $("#pt-loading").delay(950).fadeOut("slow");

})

export default router
