<template>
    <section class="contactSimply">
    <!-- <div class="ssdMain_services_backgroundOverlay" style="background-image: url(images/backgrounds/backgroundDecoratino.jpg)"></div> -->

        <div class="contactSimply_container" :class="{'contactSimply_container_reverse': reverse }">
            <div v-if="dataText != null" class="contactSimply_container_text" :class="{'contactSimply_container_text_reverse': reverse }">
                <h2 class="contactSimply_container_text_title"> {{ dataText.title }} </h2>
                <p>
                    {{ dataText.subtitle }}
                </p>
            </div>


            <div class="contactSimply_container_formBx" :class="{'contactSimply_container_formBx_fullWidth': dataText == null, 'contactSimply_container_formBx_withBlur': ViewDataForm.withBLur}">
                <h3 class="contactSimply_container_formBx_title">{{ titleForm }}</h3>
                <form @submit.prevent="submit" class="quote-from contactSimply_container_formBx_form">
                    <div v-if="ViewDataForm.stateInputs.nombre" class="col-md-4 contactSimply_container_formBx_form_bx">
                      <div class="form-group contactSimply_container_formBx_form_bx_group" style="margin-bottom: 4rem">
                        <input
                          v-model="message.nombre"
                          v-validate="'required'"
                          type="text"
                          name="nombre"
                          data-vv-as="nombre"
                          placeholder="Nombre"
                          class="form-control input-white-custom mb-0 contactSimply_container_formBx_form_bx_group_input"
                          :class="{ 'vue-error': errors.first('nombre'), 'contactSimply_container_formBx_form_bx_group_input_withBlur': ViewDataForm.withBLur  }"
                          key="1_"
                          maxlength="30"
                        />
                        <FormError :error="errors.first('nombre')"> </FormError>
                      </div>
                    </div>
                    <div v-if="ViewDataForm.stateInputs.email" class="col-md-4 contactSimply_container_formBx_form_bx">
                      <div class="form-group contactSimply_container_formBx_form_bx_group" style="margin-bottom: 4rem">
                        <input
                          v-model="message.email"
                          v-validate="'required|email'"
                          type="text"
                          name="email"
                          data-vv-as="email"
                          placeholder="Email"
                          class="form-control input-white-custom mb-0 contactSimply_container_formBx_form_bx_group_input"
                          :class="{ 'vue-error': errors.first('email') }"
                          key="2_"
                          maxlength="30"
                        />
                        <FormError :error="errors.first('email')"> </FormError>
                      </div>
                    </div>
                    <div v-if="ViewDataForm.stateInputs.telefono" class="col-md-4 contactSimply_container_formBx_form_bx">
                      <div class="form-group contactSimply_container_formBx_form_bx_group" style="margin-bottom: 4rem">
                        <input
                          v-model="message.telefono"
                          @keypress="onlyNumber"
                          type="text"
                          name="telefono"
                          data-vv-as="telefono"
                          placeholder="Teléfono"
                          class="form-control input-white-custom mb-0 contactSimply_container_formBx_form_bx_group_input"
                          :class="{ 'vue-error': errors.first('telefono') }"
                          key="3_"
                          maxlength="13"
                        />
                        <FormError :error="errors.first('telefono')"> </FormError>
                      </div>
                    </div>

  
                    <div v-if="ViewDataForm.stateInputs.mensaje" class="col-md-12 contactSimply_container_formBx_form_bx">
                      <div class="form-group contactSimply_container_formBx_form_bx_group">
                        <textarea
                          v-model="message.mensaje"
                          rows="4"
                          class="input-white-custom contactSimply_container_formBx_form_bx_group_textarea"
                          placeholder="Mensaje"
                          style="line-height: initial"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12 mb-4">
                      <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="token"> </vue-recaptcha>
                    </div>

                    <div class="col-md-12">

                        <button type="submit" alt="enviar cuestion a ihosting" aria-label="enviar cuestion a ihosting" class="btnShodow" :class="[verify ? 'verify-class' : 'normal-class']" >
                                <span v-if="!verify" class="btnShodow_link">
                                    Enviar
                                  <i class="fas fa-arrow-alt-circle-right"></i>
                                </span>

                                <span v-else class="btnShodow_link">Loading...</span>
                        </button>
                        <div class="col-md-8">
                          <div v-if="send" class="alert alert-light alert-payment-v" role="alert">
                            Gracias por comunicarte con nosotros, nos contactaremos a la brevedad.
                          </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
          
</template>

<script>
import FormError from "@/components/cart/FormError";
import VueRecaptcha from "vue-recaptcha";

import { mapActions } from "vuex";
import { onlyNumber} from "@/helpers";
export default{
    components: {
        FormError,
        VueRecaptcha
    },
    props:{
        reverse: {
            default: false
        },
        dataText:{
            default: null
        },
        ViewDataForm:{
            default: ()=>({
            stateInputs:{
                    nombre: true,
                    email: true,
                    telefono: true,
                    asunto: false,
                    motivo: false,
                    mensaje: true,
                },
                withBLur: true,
                asunto: 'Consulta iHosting',
                motivo: 'Consulta comercial'
        })    
        },
        titleForm: {
            default: 'Contacto',
        }
    },
    data: ()=>({
        loading: false,
        message: {
            nombre: '',
            email: '',
            telefono: '',
            asunto: '',
            motivo: '',
            mensaje: '',
            robot: false,

        },
        loading: false,
        verify: false,
        send: false,
        token: process.env.VUE_APP_API_RECAPTCHA_TOKEN,


    }),
    created() {
        this.message.asunto = this.ViewDataForm.asunto;
        this.message.motivo = this.ViewDataForm.motivo;
    },
    methods: {
        ...mapActions(["sendMessage"]),
        onlyNumber,

        validateInput(expresion, input, campo){
            if (!expresion.test(input)) return false;
            return true;
        },
        async submit() {
            this.$Progress.start();

            this.loading = true;
            
            try {
                // if( !this.validateInput(this.expresiones.name, this.message.name) || !this.validateInput(this.expresiones.email, this.message.email)  ) throw new Error("Campos invalidos");
                this.$validator.validate().then((esValido) => {
                    if (esValido) {
                        this.sendMessage(this.message).then((res) => {
                        // const response =  useContactUs.SendConsult(message).then(res=>{
                            if (res.data?.status == "success") {
                            this.$toast.success("Mensaje enviado con exito! te contactaremos a la brevedad.");
                            this.$Progress.finish();
                            //   this.send = true;
                            this.clearForm();
                            //   this.resetRecaptcha();
                            }else if(res.data?.status == "error"){
                                this.$toast.error(res.data.message);
                                this.$Progress.fail();
                                this.resetRecaptcha();
                            }else {
                                for (const [index, value] of Object.entries(res)) {
                                this.$validator.errors.add({
                                    field: index,
                                    msg: value[0],
                                });
                                }

                                this.$toast.error("Error en los campos ingresados");
                                this.$Progress.fail();
                            }

                            this.verify = false;

                        }).catch(error=>{
                            console.log("sendMsg: response: ", response);

                        });
                        
                    }
                })
            } catch (error) {
                console.log("sendMsg error: ", error);

            }
            this.loading = false;
            this.$Progress.finish();

   },
   onVerify: function (response) {
      if (response){
        this.message.robot = true;
        this.message['g-recaptcha-response'] = response;
      } 
    },
    
   clearForm(){
    this.message.nombre = '';
    this.message.email = ''
    this.message.mensaje = '';
    this.message.telefono = '';
    this.message.robot = false;


    this.$nextTick(() => {
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      });
   },
   resetRecaptcha() {
      this.verify = false;
      this.message.robot = false;
      this.$refs.recaptcha.reset() // Direct call reset method
    },
    },
}



</script>

<style  scoped>
.contactSimply{
    width: 100%;
    padding: 0px;
    position: relative;
    overflow: hidden;
}

.contactSimply_container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 40px;
    max-width: 1400px;
    margin: 0 auto;

}

.contactSimply_container_reverse{
    flex-direction: row-reverse;
}

.ssdMain_services_backgroundOverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 650px;

  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.2;
  z-index: 0;
}

.contactSimply_container_text{
    width: 60%;
}

.contactSimply_container_text_reverse{
    padding: 20px 20px 20px 110px;
}

.contactSimply_container_formBx_fullWidth{
    width: 90% !important;
    max-width: 650px;
}
.contactSimply_container_formBx{
    width: 40%;
    padding: 20px 30px 35px;
    box-shadow: 0px 3px 9px 3px #cbcbcb36;
    border-radius: 8px;
    background: var(--grey-color);

}
.contactSimply_container_formBx_withBlur{
    background-color: rgba(251,251,253,0.8);
    backdrop-filter: saturate(180%) blur(20px);
}
.contactSimply_container_formBx_title{
    text-align: center;
    margin: 20px 0px 30px;
    font-size: 25px;
    line-height: normal;
    border-bottom: 1px solid #3b3b3b1a;
    padding-bottom: 10px;
}
.contactSimply_container_formBx_form{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.contactSimply_container_formBx_form_bx_group_input, .contactSimply_container_formBx_form_bx_group_textarea{
    border-radius: 8px !important;
    background: var(--white-color) !important;
    font-size: 14px;
}
.contactSimply_container_formBx_form_bx_group_input_withBlur{
    background: var(--grey-color) !important;

}

/* button */
.btnShodow{
    width: 140px;
    display: flex;
    align-items: center;
    color: var(--white-color);
    font-family: var(--title-fonts);
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    line-height: 2;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
    transform: scale(1);
    padding: 5px 10px !important;
    text-align: center;
    justify-content: center;

}
.btnShodow:hover{
    transform: scale(1.05) !important;
    color: var(--white-color) !important;

}

.btnShodow_link{
  color:  var(--white-color);

}
.errorShowStyle{
    display: none;
}
@media(max-width:1300px){
.contactSimply_container_formBx_title{
    text-align: center;
    margin: 5px 0px 15px;
    font-size: 22px;
    line-height: normal;
    border-bottom: 1px solid #3b3b3b1a;
    padding-bottom: 8px;
}
.contactSimply_container_formBx_form_bx{
    height: 65px;
    padding: 0px 5px;
}

.contactSimply_container_formBx_form_bx_group_input, .contactSimply_container_formBx_form_bx_group_textarea{
    height: 58px;
    margin-bottom: 10px;
}

}

@media (max-width: 925px){
    .contactSimply_container{
        flex-direction: column;
    }
    .contactSimply_container_text{
        width: 100%;
        text-align: center;
    }
    .contactSimply_container_text_reverse{
        padding: 20px;
    }
    .contactSimply_container_formBx{
        width: 100%;
        max-width: 450px;
    }

}

@media (max-width: 555px){

    .contactSimply_container{
        padding: 40px 2px;
    }
    .contactSimply_container_text_title{
        font-size: 25px;
        line-height: 45px;
    }
    .titleBox_description{
        font-size: 16px;
    }
}
</style>


