<template>
  <main id="AzureIhosting" class="azureMain" style="min-height: 100vh;" >
    <SliderTextImages :dataSlider="dataSliderTextImages">
      <div class="azureMain_portada_content">
              <ContactSimply :ViewDataForm="{
                    stateInputs:{
                    nombre: true,
                    email: true,
                    telefono: true,
                    asunto: false,
                    motivo: false,
                    mensaje: true,
                  },
                  withBLur: true,
                asunto: 'Consulta iHosting Microsoft Azure: solicito la llamada de un Ejecutivo Comercial',
                motivo: 'Consulta comercial Microsoft Azure'
              }"
              titleForm="Solicita la llamada de un Ejecutivo Especializado"
              />
              
      </div>
    </SliderTextImages>
    <!-- <section class="azureMain_portada">
        <BannerSlider :dataBannerSlider="dataBannerSlider" >
            <div class="azureMain_portada_content">
              <ContactSimply :ViewDataForm="{
                    stateInputs:{
                    nombre: true,
                    email: true,
                    telefono: true,
                    asunto: false,
                    motivo: false,
                    mensaje: true
                },
                asunto: 'Consulta iHosting Microsoft Azure: solicito la llamada de un Ejecutivo Comercial',
                motivo: 'Consulta comercial Microsoft Azure'
              }"
              titleForm="Solicita la llamada de un Ejecutivo Especializado"
              />
              
            </div>
        </BannerSlider>
    </section> -->

    <!-- services info -->
    <section class="sectionServices">
      <div class="sectionServices_container">
        <div class="sectionServices_container_text">
          <titleBox :dataTitleBox="dataCardsScale.dataTitle" :configTitleBox="{'positionTitle':'center' }"/>
        </div>
        <div class="sectionServices_container_cards">
          <cardWaveInside v-for="(item,index) in dataCardsScale.listText" :dataInfo="item" :key="index" class="wow animate__animated  animate__fadeInUp" />
        </div>
      </div>
    </section>


        <!-- banner promo wave animated -->
    <section class="azure_bannerAnimated">
      <bannerAnimated :dataBannerAnimated="dataBannerAnimated_demofree"/>
    </section>

    <!-- <div class="sectionServices_container">

      <section class="sectionServices_container wow animate__animated  animate__fadeInUp">

        <div class="cardWaves">
        </div>
      </section>
    
    </div> -->
    <!-- Solucion -->
    <div class="azureMain_beneficios">
      <div class="azureMain_beneficios_content ">
                    <div class="azureMain_beneficios_content_text ">
                        <div class="azureMain_beneficios_content_text_sectionTitle wow animate__animated  animate__fadeInUp ">
                            <titleBox :dataTitleBox="dataSolution" :configTitleBox="{'positionTitle':dataSolution.positionTitle, 'style': 'width: 100%'}"/>
                        </div>
                        <!-- <p class="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit clita duo justo magna dolore erat amet</p> -->
                        <div class="azureMain_beneficios_content_text_row wow animate__animated  animate__fadeInUp">
                            <div class=" wow azureMain_beneficios_content_text_row_bx" >
                                <div class="bg-light rounded text-center p-4">
                                    <i class="fa fa-line-chart fa-2x text-primary mb-2"></i>
                                    <p class="mb-0">Objetivos del Cliente</p>
                                </div>
                            </div>
                            <div class=" wow azureMain_beneficios_content_text_row_bx" >
                                <div class="bg-light rounded text-center p-4">
                                    <i class="fa fa-shield fa-2x text-primary mb-2"></i>
                                    <p class="mb-0">Disponibilidad y seguridad</p>
                                </div>
                            </div>
                            <div class=" wow azureMain_beneficios_content_text_row_bx">
                                <div class="bg-light rounded text-center p-4">
                                    <i class="fa fa-refresh fa-2x text-primary mb-2"></i>
                                    <p class="mb-0">Dimensión de Carga</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 azureMain_beneficios_content_image">
                        <img class="img-fluid wow zoomIn azureMain_beneficios_content_image_img" data-wow-delay="0.5s" src="/images/services/azure/images/ARQUITECTURA-DE-SOLUCION.webp">
                    </div>
           
      </div>
    </div>
    <!-- About End -->


    <!-- proceso Start-->
    <section class="azureMain_steep">
      <div class="nubesComponent_top">
          <nubes/>
      </div>
      <div class="azureMain_steep_content wow animate__animated  animate__fadeInUp">
        <div class="azureMain_steep_content_steep">
          <CardsFlex :dataInfo="dataCardsSteep" :dataTitleBox="dataTitleSteep"/>
        </div>
      </div>

      <div class="nubesComponent">
        <nubes/>
      </div>
    </section>

    <!-- beneficios  ihosting-->
    <section class="azureMain_imageList spad">
      <div class="azureMain_imageList_content">
              <ContactSimply :ViewDataForm="{
                    stateInputs:{
                    nombre: true,
                    email: true,
                    telefono: true,
                    asunto: false,
                    motivo: false,
                    mensaje: true,
                    withBLur: true
                },
                asunto: 'Consulta iHosting Microsoft Azure',
                motivo: 'Consulta comercial Microsoft Azure'
              }"
              titleForm="Solicita asistencia comercial en Microsoft Azure"
              />
              
      </div>
      <div class="azureMain_imageList_list">
        <listWithImage :dataListBeneficios="dataListBeneficios" :dataTitleBox="dataTitleBeneficios"/>
      </div>
    </section>


        <!-- banner promo wave animated -->
    <section class="azure_bannerAnimated">
      <bannerAnimated :dataBannerAnimated="dataBannerAnimated"/>
    </section>


    <!--Beneficios Azure -->
    <section class="azureMain_list">
      <listDynamic :dataTitleBox="dataTitleList" :dataList="dataList" class="wow animate__animated  animate__fadeInUp"/>
    </section>

    <section class="azure_about">
      <aboutService :dataSection="dataTitleMicrosoftAzure" />
    </section>
    <!-- servicios adicionales -->
    <section class="cardsHoverAnimated   ">
      <!-- <div class="nubesComponent_top"><nubes/></div> -->
      <cardHoverAnimated :dataCards="dataAdditional" :dataTitleBox="dataTitleAdditional"/>
      <!-- <div class="nubesComponent"><nubes/></div> -->
    </section>
  </main>
</template>

<script>
import nubes from "../../../components/web/nubes.vue";
// import cardTransform from "../../../components/web/cards/cardTransform.vue";
// import BannerSlider from  "@/components/web/sliders/BannerSlider.vue";  
import listDynamic from "@/components/web/listDynamic.vue";
import bannerAnimated from "@/components/web/bannerAnimated.vue";
import titleBox from '@/components/web/titleBox.vue';
import CardsFlex from "../../../components/web/cards/CardsFlex.vue";
import aboutService from "../../../components/web/aboutService.vue";
import listWithImage from "../../../components/web/lists/listWithImage.vue";
import ContactSimply from "../../../components/web/forms/contactSimply.vue";
import cardWaveInside from "@/components/web/cards/cardWaveInside.vue";
import SliderTextImages from "@/components/web/sliders/SliderTextImages.vue";
import cardHoverAnimated from "@/components/web/cards/cardHoverAnimated.vue";

export default {
  components: {
    // SliderCardPlans,
    // BannerSlider,
    // cardTransform,
    CardsFlex,
    nubes,
    titleBox,
    listDynamic,
    bannerAnimated,
    aboutService,
    listWithImage,
    ContactSimply,
    cardWaveInside,
    SliderTextImages,
    cardHoverAnimated
  },
  data: ()=>({

    // data para la seccion de inicio, el banner slider ---
    dataBannerSlider:{
    title: 'Microsoft Azure',
    titleBold: '\nTu proyecto en la nube',
    // subtitle: ' Solicita la llamada de un Ejecutivo Comercial y en las próximas 48hrs uno de nuestros Ejecutivos se contactará contigo.',
    directionElement: false,

    },

    dataSliderTextImages:{
      withBackgrounds: false,
      withArrows: false,
      withThumbnail: false,
      withBackgroundsFixed: true,
      withPointsAnimation: true,
      services: [
        {
          "title": "Trabaja con la Plataforma de Computación en la Nube de Microsoft ",
          "description": "Acceda a más de 200 funciones clave en la gestión de seguridad, cumplimiento y gobernabilidad con una migración a la nube basada en las mejores prácticas de la industria.",
          "mainImage": "/QUINTA%20COLONIAL%20CCS/_MG_0164-2_fb9orh.jpg",
          "header_title" : "Microsoft Azure",
          "header_img": "/images/partners/partner13_2.png",
        },
        {
          "title": "Somos Partners Certificados de Microsoft Azure",
          "description": "Reduce el costo total de propiedad (TCO) y los costos de licenciamiento con una ventaja doble sobre otros proveedores de servicios en la nube en términos de precio y rendimiento.",
          "mainImage": "/ACOGEDORA%20VILLA%20EN%20TUCACAS/_MG_0674-HDR_qprgcs.jpg",
          "header_title" : "Microsoft Azure",
          "header_img": "/images/partners/partner13_2.png",

        },
      ]
    },

        // formularios data;
    dataTextForm1:{
      title: '¡Solicita ayuda de un especialista!',
      subtitle: ' Deja tus datos y nuestro equipo técnico VPS se comunicará contigo'
    },


    // solucion
    dataSolution: {
      title: 'Arquitectura de Solución',
      description: '¡Somos Partner Oficial de Microsoft Azure! Con iHosting pagas lo mismo y recibes un servicio integral y en tu idioma. Agenda tu consultoría en Microsoft Azure con un especialista de iHosting 100% Gratis.',
      color: 'var(--dark-color)',
      positionTitle: 'left'
    }, 

    // data cards scale


    dataCardsScale:{
      dataTitle:{
        title: 'Servicios',
        description: 'Si te encuentras en transición hacia la transformación digital o si necesitas recibir una gran cantidad de visitas en un corto periodo de tiempo, la infraestructura de Microsoft Azure te ayuda a crear de manera rápida, escalable y segura.',
        color: 'var(--dark-color)',
        positionTitle: 'left'
      },
        listText: [
            {title: 'Asesoría', text: 'Desarrollo de estructuras.', img: 'images/services/azure/ASESORIA.webp'},
            {title: 'Migración', text: 'Llevamos tu plataforma a la nube sin perjudicar la productividad de tu negocio.', img:'images/services/azure/MIGRACION.webp'},
            {title: 'Administración', text: 'Desplegamos tu plataforma y servicios en Microsoft Azure.', img:'images/services/azure/IMPLEMENTATION.webp'},
            {title: 'Soporte ', text: 'Servicio de soporte directo y exclusivo.', img:'images/services/azure/SOPORTE.webp'},

        ]
    },
    
    // banner animated ---
    dataBannerAnimated_demofree:{
      title:  '¡Solicita tu Demo Gratis!',
      paragraph: 'Nuestro equipo de profesionales, realizará un POC y un entorno para que lo pruebes gratis.'
    },
    
    // data section steep to steep 
    dataTitleSteep: {
      title: 'Proceso',
      description: '¡La flexibilidad es parte del crecimiento! Queremos acompañarte en cada paso, es por eso que contamos con plataformas flexibles y planes personalizados.',
      color: 'var(--dark-color)',
      positionTitle: 'left'
    },
    dataCardsSteep:{
      cards: [
        {title: 'Reunión Inicial', description: 'Reunión inicial para el levantamiento de necesidades.', state: true, icon: 'fa fa-calendar-plus-o'},
        {title: 'Desarrollo de Estructura', description: 'Desarrollo de estructura. Hacer cálculos y poder optimizar costos.', state: false, icon: 'fa fa-cubes'},
        {title: 'Cotización Optimizada', description: 'Cotización Optimizada y presentación de Solución.', state: false, icon: 'fa fa-balance-scale'},
        {title: 'Onboarding', description: 'Onboarding y Configuración de Propuesta.', state: false, icon: 'fa fa-cogs'},
        {title: 'Administración de la Nube ', description: "Administración de la Nube.", state: false, icon: 'fa fa-cloud'},
      ]
    },

    // data section beneficios --- 
    dataTitleBeneficios: {
      title: 'Más que una suscripción, administramos tu Nube de manera Integral',
      // description: 'Donde tendras asesoría y soporte técnico en tú idioma especializado en resolver tus inquietudes.',
      color: 'var(--dark-color)',
      positionTitle: 'left'
    },
    dataListBeneficios:{
        listText: [
            { text: 'Migraciones rápidas y eficientes.'},
            { text: 'Conectividad / Conectamos tu plataforma con la nube y extendemos tu red hacia ella. '},
            { text: 'Más de 20 años de Experiencia en el mundo TI.'},
            { text: 'Microsoft Azure en tu Idioma.'},
            { text: 'Paga en pesos chilenos'},
            { text: 'Facturación Local.'},
            { text: 'Control de Costos.'},
            { text: 'Ahorra hasta 60% contratando Soporte con nosotros.'},
            { text: 'Soporte técnico 24x7x365 con bajos costos.'},
            { text: 'Damos acceso directo al Soporte Premier de Microsoft.'},
            { text: 'Partner Oficial de Microsoft Azure. '},
        ],
    },

      // data section beneficios Azure
    dataTitleList: {
      title: 'Beneficios de Microsoft Azure',
      description: 'Estos beneficios hacen que Microsoft Azure sea una opción atractiva para empresas que buscan flexibilidad, eficiencia y reducción de costos en sus operaciones de TI.',
      color: 'var(--dark-color)',
    }, 
    dataList:{
      dataListServiceInfo:[
        {title: 'Escalabilidad sin inversión', 'description': 'Escalabilidad sin inversión en hardware: Permite actualizar y escalar tus servidores según las necesidades sin la necesidad de realizar grandes inversiones en hardware.', icon: 'fa fa-check'},
        {title: 'Modelo de pago por uso ', description: 'Modelo de pago por uso: Alquilar solo los servicios necesarios proporciona un modelo de pago por uso, lo que significa que solo pagas por los recursos que consumes, optimizando costos.',  icon: 'fa fa-check'},
        {title: 'Respaldo y seguridad de la información', description: 'Respaldo y seguridad de la información: Ofrece soluciones robustas de respaldo y seguridad para garantizar la integridad y confidencialidad de tus datos, protegiéndolos contra pérdidas y amenazas.',  icon: 'fa fa-check'},
        {title: 'Acceso remoto global ', description: 'Acceso remoto global: Permite acceder a la información desde cualquier lugar del mundo, facilitando la colaboración y el trabajo remoto de manera eficiente.',  icon: 'fa fa-check'},
        {title: 'Ahorro en gastos operativos ', description: 'Ahorro en gastos operativos: Contribuye a la reducción de gastos operativos al minimizar los costos asociados con mantenimiento, energía y climatización de infraestructuras locales.',  icon: 'fa fa-check'},
        {title: 'Ancho de banda público de entrada ilimitado ', description: 'Eliminación de altas inversiones en hardware: Al disminuir la necesidad de adquirir hardware costoso, se eliminan las altas inversiones iniciales, permitiendo un uso más eficiente de los recursos financieros.',  icon: 'fa fa-check'},
        // {title: 'Ancho de banda de red privada ilimitado ',  icon: 'fa fa-bolt'},
        // {title: 'Virtualización con Vmware calidad empresarial  ', 'description': 'Virtualización con Vmware calidad empresarial con visualización de alto rendimiento.',  icon: 'fa fa-bolt'},
      ],
      expande: true
    },

        // banner animated ---
    dataBannerAnimated:{
      title: 'Implementación Microsoft Azure de primer nivel',
      paragraph: 'Acceda a más de 200 funciones clave en la gestión de seguridad, cumplimiento y gobernabilidad con una migración a la nube basada en las mejores prácticas de la industria. Eficiencia presupuestaria y menor inversión.'
    },

      // data section steep to steep 
      dataTitleMicrosoftAzure: {
        titleBox:{
          title: 'Simplificamos tu gestión de datos',
          description: 'Microsoft Azure es un servicio de cómputo en la nube diseñado para simplificar la construcción, prueba, despliegue y gestión de aplicaciones y servicios a través de sus centros de datos globales.',
          color: 'var(--dark-color)',
          positionTitle: 'left'
        },

        data:{
          title: 'Objetivo y beneficios',
          subtitle: 'El objetivo principal de Microsoft Azure es ofrecer a las empresas un ahorro significativo en la adquisición de hardware y servicios, mediante la posibilidad de arrendar solo la infraestructura necesaria. ',
          subtitle_decoration: 'Siendo algunos de los beneficios principales:',
          list: [
            ' Escalabilidad sin inversión en hardware: Permite actualizar y escalar tus servidores según las necesidades sin la necesidad de realizar grandes inversiones en hardware.',
            ' Modelo de pago por uso: Alquilar solo los servicios necesarios proporciona un modelo de pago por uso, lo que significa que solo pagas por los recursos que consumes, optimizando costos.'
          ],
          img: '/images/services/azure/images/OBJETIVOS-Y-BENEFICIOS.webp'
        }

    },

        // data para la seccion servicios adicionales ---
        dataTitleAdditional: {
            title: 'Servicios Relacionados',
            description: 'Servicios relacionados que también te pueden interesar.',
            color: 'var(--dark-color)',
    },
    dataAdditional:[
        {title: 'Microsoft Defender. ', icon: 'ri-arrow-right-s-line', img: '/images/services/azure/services_additionals/MICROSOFT_DEFENDER.png', url: '/microsoftDefender#'},
        {title: 'VPS SSD.  ', icon: 'ri-arrow-right-s-line', img: '/images/services/azure/services_additionals/VPSSSD.png ', url: '/vpsSSD'},
        {title: 'Cloud VPS.', icon: 'ri-arrow-right-s-line', img: '/images/services/azure/services_additionals/CLOUDVPS.png', url: '/vpsSSDCloud'},
        {title: 'Microsoft 365. ',icon: 'ri-arrow-right-s-line', img: '/images/services/azure/services_additionals/MICROSOFT_365.png', url: '/microsoft'},


    ],
    
  }),

}
</script>


<style scoped>

i{
  color: var(--primary-color) !important;
}
.azureMain_portada{
  padding: 0px 0px 0px;
}

.azureMain_portada_content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    max-width: 800px;
    margin: 0 auto;
}




/* services */

.sectionServices{
  padding: 40px 0 30px;
}
.sectionServices_container{
  display: flex;
    padding: 20px 100px;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.sectionServices_container_text{
    max-width: 800px;
    display: flex;
    justify-content: center;
}

.sectionServices_container_cards{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:  0 auto;
    width: auto;
}


/* beneficios */
.azureMain_beneficios{
  min-height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.azureMain_beneficios_content{
  width: 100%;
  max-width: 1300px;
  padding: 0px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.azureMain_beneficios_content_text{
  width: 58%;
  max-width: 59%;
  padding: 20px;
}
.azureMain_beneficios_content_text_sectionTitle{
  margin-bottom: 30px;
}

.azureMain_beneficios_content_text_row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.azureMain_beneficios_content_text_row_bx{
  /* margin-bottom: 1.5rem; */
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  height: 152px;
  width: 219px;
  max-width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey-color);
  margin: 0 0.5rem 1.5rem;
}

.azureMain_beneficios_content_image{
  width: 40%;
  width: 40%;
  display: flex;
  justify-content: center;
}

.azureMain_beneficios_content_image_img{
  max-width: 420px;
  height: 375px;
}

.azureMain_steep{
  background: var(--grey-color);
  position: relative;
  overflow: hidden;
  padding: 80px 0px;
}

.nubesComponent{
  position: absolute;
  left: 0;
  bottom: -50px;
}

.nubesComponent_top{
  position: absolute;
    top: 65px;
    left: 0;
    z-index: 5;

}

/* button */


.btnShodow{
    background: var(--primary-color);
    color:  var(--white-color);
    font-family: var(--title-fonts);
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    padding: 5px 35px;
    line-height: 2;
    border: none;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 81, 250, 0.3);
    transform: scale(1);

}
.btnShodow:hover{
    transform: scale(1.05) !important;
    color: var(--white-color) !important;

}

.btnShodow_link{
  color:  var(--white-color);

}


/*---------------------
  Choose Plan
-----------------------*/

.azureMain_imageList{
  width: 90%;
  margin: 0 auto;
  max-width: 1300px;
  padding: 70PX 0PX;
  display: flex;
  justify-content: space-between;
}


/* .azureMain_imageList_content{
  width: 50%;
} */

.azureMain_imageList_list{
  width: 50%;
}
/* azure list beneficios */
.azureMain_list{
  padding: 100px 0 10px;
  min-height: 65vh !important;
}
.azureMain_list, .azure_bannerAnimated{
  padding: 40px 0px;
}


.azure_about{
  padding: 40px 0 0px;
}

.cardsHoverAnimated{
  padding: 70px 0px 80px;
  background: var(--grey-color);

}


@media(max-width:1580px){
  .sectionServices_container{
    justify-content: space-between;
    padding: 20px;
  }
}

@media(max-width:1100px){
  .sectionServices_container{
    flex-direction: column;
    align-items: center;
  }
  .sectionServices_container_cards{
    justify-content: center;
  }
    /* beneficios ---- */
    .azureMain_imageList_list{
      width: 100%;
    }
    .azureMain_imageList{
    width: 95%;
    flex-direction: column-reverse;

  }
}

@media (max-width:900px){

  .azureMain_portada{
    padding: 0px 0px 0px;
  }
  /* Solucion ---- */
  .azureMain_beneficios_content_text{
    width: 100%;
    max-width: 95%;
  }
  .azureMain_beneficios_content_image{
    width: 100%;
    max-width: 95%;
  }

  /* beneficios ---- */
  .azureMain_imageList{
    width: 100%;
    display: flex;

  }
  .azureMain_imageList_content{
    flex-direction: column;
    align-items: center;
  }
  .azureMain_imageList_content_imgBx{
    width: 90%;

  }
  .azureMain_imageList_content_imgBx_img{
  width: 90%;
  }
  .azureMain_imageList_content_infoBx{
    width: 95%;
  }
  .azureMain_imageList_content_infoBx_info{
    padding-left: 10px;
  }

  .azureMain_imageList_content_infoBx_info_title {
    font-size: 21px;
  }
  .azureMain_imageList_content_infoBx_info ul li {
    font-size: 13px;
  }
}

@media (max-width: 600px){
  .azureMain_imageList{
    padding: 40px 10px;
  }
  .azureMain_beneficios_content, .azureMain_beneficios_content_text_row{
    justify-content: center;
  }
  
  .azureMain_beneficios_content_image{
    display: none;
  }

  .azureMain_beneficios_content_text_row_bx{
    width: 90%;
  max-width: 90%;

  
  }
}


</style>